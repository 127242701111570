import {StyleSheet} from 'react-native';
import { Colors } from '../../../styles';

export const styles = StyleSheet.create({
  badgeView: {
    borderRadius: 4
  },  
  successBadgeView: {
    backgroundColor: Colors.Custom.Success50,
  },
  primaryBadgeView: {
    backgroundColor: Colors.Custom.Primary50,
  },
  warningBadgeView: {
    backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
  },
  secondaryBadgeView: {
    backgroundColor: Colors.FoldPixel.GRAY50,
  },
  primaryBadgeText: {
    color: Colors.FoldPixel.PRIMARY300,
    fontWeight: '400',
    fontFamily: 'Inter'
  },
  warningBadgeText: {
    color: Colors.FoldPixel.STATUS_IN_PROGRESS,
    fontWeight: '400',
    fontFamily: 'Inter'
  },
  successBadgeText: {
    color: Colors.Custom.SuccessColor,
    fontWeight: '400',
    fontFamily: 'Inter'
  },
  secondaryBadgeText: {
    color: Colors.FoldPixel.GRAY300,
    fontWeight: '400',
    fontFamily: 'Inter'
  }
});
