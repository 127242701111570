import {FilterCodeListType} from '../components/common/FilterView/interfaces';

import {Colors} from '../styles';
import {IUserType} from '../utils/interfaces';
import {FormComponents} from '../components/PublicPages/PublicForm/PublicFormHelper';
import {isWeb} from '../utils/platformCheckUtils';
import {IContactSource} from './interface';
import { CONVERSATION_ACTION_CODES } from './ActionConst';

export const MOBILE_TAB_BAR_HEIGHT = 86;

export const BLOOD_PRESSURE_LOINC = '85354-9';
export const HEIGHT_LOINC = '8302-2';
export const SYSTOLIC_BLOOD_PRESSURE_LOINC = '8480-6';
export const DIASTOLIC_BLOOD_PRESSURE_LOINC = '8462-4';

export const RIGHT_SIDE_CONTAINER_CODE = {
  CONTACT_LIST_VIEW: 'CONTACT_LIST_VIEW',
  CONTACT_EDIT_VIEW: 'CONTACT_EDIT_VIEW',
  CONTACT_DELETE_VIEW: 'CONTACT_DELETE_VIEW',
  CONTACT_OMNI_VIEW: 'CONTACT_OMNI_VIEW',
  CONTACT_IMPORT_VIEW: 'CONTACT_IMPORT_VIEW',
  CONTACT_TAG_VIEW: 'CONTACT_TAG_VIEW',
  CONTACT_ADD_RELATION_VIEW: 'CONTACT_ADD_RELATION_VIEW',
  CONTACT_CONVERT_LEAD: 'CONTACT_CONVERT_LEAD',
  CONTACT_CONVERT_CUSTOMER: 'CONTACT_CONVERT_CUSTOMER',
  CONTACT_ADD_NOTE: 'CONTACT_ADD_NOTE',
  CONTACT_MAIL_POPUP: 'CONTACT_MAIL_POPUP',
  CONTACT_SMS_DRAWER: 'CONTACT_SMS_DRAWER',
  APPOINTMENT_SCHEDULE_POPUP: 'APPOINTMENT_SCHEDULE_POPUP',
  APPOINTMENT_DETAIL_POPUP: 'APPOINTMENT_DETAIL_POPUP',
  CONTACT_OPEN_IN_EHR: 'CONTACT_OPEN_IN_EHR',
  CREATE_MEETING_VIEW: 'CREATE_MEETING_VIEW',
  CONTACT_RESET_PASSWORD: 'CONTACT_RESET_PASSWORD',
  CREATE_CHANNEL_VIEW: 'CREATE_CHANNEL_VIEW',
  ADDED_OR_UPDATED_CONTACT: 'ADDED_OR_UPDATED_CONTACT',
  EMAIL_SENT_EVENT: 'EMAIL_SENT_EVENT',
  CONTACT_SEND_FORM: 'CONTACT_SEND_FORM',
  PRINT_CLINICAL_PROFILE: 'PRINT_CLINICAL_PROFILE',
  CREATE_TASK: 'CREATE_TASK',
  AUTOMATION_VIEW: 'AUTOMATION_VIEW',
  CONTACT_DEACTIVATE_VIEW: 'CONTACT_DEACTIVATE_VIEW',
  SEND_SMS_DRAWER: 'SEND_SMS_DRAWER',
  CONTACT_DETAILS_VIEW: 'CONTACT_DETAILS_VIEW',
  SMS_INBOX_VIEW: 'SMS_INBOX_VIEW',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  ADD_FAMILY_MEMBERS: 'ADD_FAMILY_MEMBERS',
  MEMBER_LIST_VIEW: 'MEMBER_LIST_VIEW',
  CHAT_WITH_PATIENT: 'CHAT_WITH_PATIENT',
  CONTACT_CHAT_DRAWER: 'CONTACT_CHAT_DRAWER',
  CALL_WITH_PATIENT_DRAWER: 'CALL_WITH_PATIENT_DRAWER',
  SHOW_CONVERSATION_DRAWER: 'SHOW_CONVERSATION_DRAWER',
  SHOW_SEND_PROVIDER_REQUEST_FORM_DRAWER: 'SHOW_SEND_PROVIDER_REQUEST_FORM_DRAWER',
  SHOW_CARE_PROGRAM_CONSENT_AND_ENROLL_DRAWER: 'SHOW_CARE_PROGRAM_CONSENT_AND_ENROLL_DRAWER',
  ECM_OUTREACH_VIEW: 'ECM_OUTREACH_VIEW',
  SHOW_CARE_PROGRAM_CONSENT: 'SHOW_CARE_PROGRAM_CONSENT',
  SHOW_CARE_PROGRAM_ASSESSMENT: 'SHOW_CARE_PROGRAM_ASSESSMENT',
  ACTIONABLE_TASK_VIEW: 'ACTIONABLE_TASK_VIEW',
  ACTIONABLE_ALERT_VIEW: 'ACTIONABLE_ALERT_VIEW',
  ASSIGN_CARE_MANAGER_VIEW: 'ASSIGN_CARE_MANAGER_VIEW',
  CARE_PLAN_GOALS_VIEW: 'CARE_PLAN_GOALS_VIEW',
};
export const TAXONOMIES_CONTAINER_CODE = {
  ADD: 'ADD',
  RESET: 'RESET',
  DETAILVIEW: 'DETAILVIEW',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  ALL: 'ALL',
};

export const PAMI_CODE_LIST = [
  'PROBLEM_ACTIVE',
  'PROBLEM_HISTORIC',
  'ALLERGY',
  'MEDICATION',
  'IMMUNIZATION',
];

export const CLINICAL_CONST = {
  PROBLEM_ACTIVE: 'PROBLEM_ACTIVE',
  PROBLEM_HISTORIC: 'PROBLEM_HISTORIC',
  ALLERGY: 'ALLERGY',
  MEDICATION: 'MEDICATION',
  IMMUNIZATION: 'IMMUNIZATION',
  HISTORICAL_LAB: 'HISTORICAL_LAB',
  VISIT_HISTORY: 'VISIT_HISTORY',
};

export const PATIENT_DEMOGRAPHIC_CONST = {
  PERSONAL_DETAILS: 'PERSONAL_DETAILS',
  CONTACT_DETAILS: 'CONTACT_DETAILS',
  EMERGENCY_CONTACT: 'EMERGENCY_CONTACT',
  CONSENT_DETAILS: 'CONSENT_DETAILS',
  PAYMENT_DETAILS: 'PAYMENT_DETAILS',
};

export const IMMUNIZATION_STATUS = {
  COMPLETED: 'COMPLETED',
  MISSED: 'MISSED',
};

export const VALIDATOR_CODES = {
  MIN_LENGTH: 'MIN_LENGTH',
  MAX_LENGTH: 'MAX_LENGTH',
  REG_EX: 'REG_EX',
};

export const FILTER_COMPONENT_CODE: {
  [index in FilterCodeListType]: FilterCodeListType;
} = {
  BRAND: 'BRAND',
  INTEGRATION: 'INTEGRATION',
  STATUS: 'STATUS',
  FORM: 'FORM',
  TAG: 'TAG',
  DEAL: 'DEAL',
  TICKET: 'TICKET',
  TASK: 'TASK',
  KIND: 'KIND',
  INBOX: 'INBOX',
  TEAM: 'TEAM',
  TAGDATA: 'TAGDATA',
  RELATIVECONTACT: 'RELATIVECONTACT',
  TRACKDATA: 'TRACKDATA',
  RELATEDCONVERSATION: 'RELATEDCONVERSATION',
  CONTACTDETAILS: 'CONTACTDETAILS',
  NOTES: 'NOTES',
  DATE: 'DATE',
  COMPLETED: 'COMPLETED',
  RELATIONWITHCONTACTS: 'RELATIONWITHCONTACTS',
  SUBMITTED_FORMS: 'SUBMITTED_FORMS',
  FUTURE_APPOINTMENT: 'FUTURE_APPOINTMENT',
  SUBSCRIPTION_LIST: 'SUBSCRIPTION_LIST',
  PATIENT_DOCUMENTS: 'PATIENT_DOCUMENTS',
  EMPLOYER_GROUP: 'EMPLOYER_GROUP',
  CARE_TEAM: 'CARE_TEAM',
  GROUP_LIST: 'GROUP_LIST',
  STICKY_NOTES: 'STICKY_NOTES',
};

export const UNKNOWN_VISITOR = 'Unknown';
export const INVALID_DATE = 'Invalid date';

export const DISPLAY_DATE_FORMAT = 'MM-DD-YYYY';
export const DISPLAY_SLASH_DATE_FORMAT = 'MM/DD/YYYY';
export const ORDER_SUMMARY_DATE_FROMATED = 'llll';
export const PATIENT_QUERRY_DATE_FROMATED = 'YYYY-MM-DD';
export const MONTH_YEAR = 'MMM YY';

export const PERSON_TYPES = {
  LEAD: 'LEAD',
  CUSTOMER: 'CUSTOMER',
  EMPLOYEE: 'EMPLOYEE',
  VISITOR: 'VISITOR',
  TEAM_MEMBER: 'TEAM_MEMBER',
  SUPPORTING_MEMBER: 'SUPPORTING_MEMBER',
  OTHER: 'OTHER',
  TPA_CARE_ADVOCATE: 'TPA_CARE_ADVOCATE'
};

export const PERSON_TYPES_TEXT = {
  PROSPECT: 'Prospect',
  PATIENT: 'Patient',
};

export const ONLINE_STATUS_COLORS: {[index: string]: string} = {
  ONLINE: Colors.Custom.SuccessColor,
  OFFLINE: Colors.Custom.OfflineColor,
};
export const WORKFLOW_TAGS = {
  TRIGGER: 'TRIGGER',
  WORKFLOW: 'WORKFLOW',
  GOALS: 'GOALS',
  REVIEW: 'REVIEW',
};

export const MODULE_PAGINATION_COUNT = {
  CONVERSATION_PAGE_SIZE: isWeb() ? 10 : 8,
  MESSAGES_PAGE_SIZE: 30,
  PRODUCTS_LIST_PAGE_SIZE: 25,
  CALL_LOG_SIZE: 10,
  CRM_ACTIVITY_PAGE_SIZE: 50,
  MENTION_MESSAGES_PAGE_SIZE: 25,
  MEMBERS_GROUP_TABLE_PAGE_SIZE: 12,
  CARE_TIMELINE: 10,
  PATIENT_QUICK_VIEW_CONVERSATION_PAGE_SIZE: 30
};

export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const DATE_FORMATS = {
  DEFAULT_DISPLAY_DATE_FORMAT: 'YYYY/MM/DD',
  CONVERSATION_DATE_FILTER: 'YYYY-MM-DD',
  CONVERSATION_DATE_PICKER: 'MM-DD-YYYY',
  SLOT_DATE_FORMAT: 'DD MMM, YYYY',
  CALENDAR_WIDGET_DATE_FORMAT: 'ddd • DD MMM',
  DISPLAY_TIME_FORMAT: 'hh:mm a',
  DISPLAY_TIME_FORMAT_WITH_TIMEZONE: 'hh:mm a Z',
  DISPLAY_DATE_FORMAT: 'YYYY-MM-DD',
  EMAIL_DISPLAY_DATE_FORMAT: 'DD-MMM-YYYY',
  DISPLAY_DATE_FORMAT_DMY: 'DD-MM-YYYY',
  DISPLAY_BIRTH_DATE_FORMAT: 'MM-DD-YYYY',
  DISPLAY_DATE_MONTH_FORMAT: 'D/M',
  API_TIME_FORMAT: 'HH:mm:ss',
  MESSAGE_DATE_FORMAT: 'MM-DD-YYYY, hh:mm A',
  SAME_YEAR_MESSAGE_DATE_FORMAT: 'MM-DD hh:mm A',
  FORM_DATE_PICKER_FORMAT: 'YYYY-MM-dd',
  API_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  UTC_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ssZ',
  NEXT_PREV_MESSAGES_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
  AVAILABILITY_DATE_FORMAT: 'YYYY-MM-DD',
  APPOINTMENT_CONFIRM_DATE_FORMAT: 'hh:mm a dddd, Do MMM, YYYY',
  MESSAGE_DATE_FORMAT_WITHOUT_TIME: 'MM-DD-YYYY',
  CALL_LOG_DATE_FORMAT: 'DD MMMM YYYY',
  MESSAGE_SECTION_HEADER_FORMAT_WITHOUT_TIME: 'MM-DD-YYYY',
  STICKY_NOTE_DATE: 'MMM DD, YYYY • hh:mm A ',
  DIAGNOSTIC_REPORT_DATE_FORMAT: 'MM/DD/YYYY hh:mm a',
  MM_DD_YY_HH_MM_A_DATE_TIME_FORMAT: 'MM/DD/YYYY hh:mm A',
  REPORT_DATE_FORMAT: 'MM/DD/YYYY hh:mm A',
  CHAT_TIME_AGO_MMDD: 'MM-DD',
  NOTE_TIMELINE_MONTH_VIEW_FORMAT: 'MMMM YYYY',
  NOTE_TIMELINE_DAY_VIEW_FORMAT: 'MMM DD',
  CALENDAR_LIB_FORMAT: 'MM-DD-YYYY',
  SIGNED_NOTE_DATE: 'MMM DD, YYYY • hh:mm A ',
  FORM_DEFAULT_DATE_FORMAT: 'MM/DD/YYYY',
  TIME_SELECT_FORMAT: 'hh:mm A',
  CALL_LOG_DETAILS_DATE: 'MMM DD YYYY, hh:mm A ',
  SUB_TASK_VIEW_FORMAT: 'DD MMM',
  HOLIDAY_DATE_FORMAT: 'DD-MM-YYYY hh:mm A',
  CRM_ACTIVITY_DATE_FORMAT: 'MM/DD/YYYY • hh:mm A',
  DOCUMENT_DATE_FORMAT: 'DD MMM YYYY hh:mm A',
  MONTH_DATE_YEAR: 'MMM DD, YYYY',
  CARE_JOURNEY_DASHBOARD_DATE_FORMAT: 'yyyy-MM-DDTHH:mm:ss',
  TASK_LOG_DATE_FORMAT: 'MM/DD/YYYY, HH:mm',
  TASK_DUE_DATE_DISPLAY_FORMAT: 'MM/DD/YYYY',
  ANALYTICS_DATE_PICKER: 'yyyy-MMM-DD',
  GMT_DATE_FORMAT: 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
  SIGNED_NOTE_DATE_FOR_AUDIT: 'MMM DD, YYYY hh:mm A ',
  BILLABLE_DATE_OF_SERVICE: 'MM/DD/YYYY',
  CARE_DASHBOARD_DATE_FORMAT: 'MM/DD/YYYY HH:mm A',
  PATIENT_PROFILE_COMMUNICATION_TAB_DATE_FORMAT: 'MMM yyyy',
  BILLING_ACTIVITY_DATE: 'MM/DD/YYYY, hh:mm A',
  HOUR_MIN_AM_PM_FORMAT: 'h:mm a',
  HOUR_AM_PM_FORMAT:'h a',
  DMY_DATE_FORMAT: 'DD MMM YYYY',
  SHORT_DAY_MONTH_FORMAT: 'D MMM',
  DAY_NAME_WITH_MONTH_AND_YEAR: 'MMM DD, YYYY',
};

export const CALENDAR_WIDGET_DATE_FORMATS = {
  DAY_VIEW_TITLE_FORMAT: 'ddd MMM DD',
  MONTH_VIEW_TITLE_FORMAT: 'MMM YYYY',
  FULL_MONTH: 'MMMM',
  SHORT_MONTH: 'MMM',
  DAY_FORMAT: 'DD',
};

export const SENDER_TYPE = {
  CONTACT: 'Contact',
  USER: 'User',
};

export const MESSAGE_POSITION = {
  RIGHT: 'right',
  LEFT: 'left',
};

export const API_TOAST_MSG = {
  SUCCESS: 'Contacts Import Successfully. Take Few Minutes To Show',
  ERROR: 'Contacts Import Failed!',
};

export const CONTACT_RELATION_VIEW = {
  CONTACT_LIST_VIEW: 'CONTACT_LIST_VIEW',
  ADD_RELATION: 'ADD_RELATION',
  ADD_RELATION_CONTACT: 'ADD_RELATION_CONTACT',
  ADD_UPDATE_CONTACT: 'ADD_UPDATE_CONTACT',
  ADD_UPDATE_TAG: 'ADD_UPDATE_TAG',
};
export const CONTACT_NOTES_VIEW = {
  NOTES_LIST_VIEW: 'NOTES_LIST_VIEW',
  NOTE_READ_ONLY_VIEW: 'NOTE_READ_ONLY_VIEW',
  ADD_NOTE: 'ADD_NOTE',
};

export const LAST_SEEN_UPDATE_TIMEOUT = 1200;

export const CUSTOMER_PANEL_ACTIONS: any = [
  {
    code: 'EDIT',
    value: 'Edit',
  },
  {
    code: 'MERGE',
    value: 'Merge',
  },
  {
    code: 'DELETE',
    value: 'Delete',
  },
  // {
  //   code: 'Change_State',
  //   value: 'Change State'
  // }
];

export const STATUS_ACTION_CONV = [
  {
    code: 'PENDING',
    value: 'Mark as pending',
    status: 2,
  },
];

export const CONVERSATION_ACTION_ROLES = ['ADMIN', 'SUPER_ADMIN'];
export const ATTACHMENT_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  FILE: 'file',
  IMAGE_SLASH:'image/*'
};

export const CONTENT_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  PDF: 'pdf',
};

export const BASE64_EXTENSIONS = {
  PDF: 'data:application/pdf;base64,',
  JPEG: 'data:image/jpeg;base64,',
  JPG: 'data:image/jpg;base64,',
  PNG: 'data:image/png;base64,'
}

export const CONVERSATION_STATUS_TYPES = {
  OPEN: 'OPEN',
  CLOSED: 'RESOLVED',
  PENDING: 'PENDING',
};

export const CONVERSATION_STATUS_ACTION: any = {
  [CONVERSATION_STATUS_TYPES.OPEN]: [
    {
      code: 'CLOSE',
      value: 'Resolve chat',
      status: 1,
    },
    // {
    //   code: 'PENDING',
    //   value: 'Mark as pending',
    //   status: 2,
    // },
    // {
    //   code: 'MERGE',
    //   value: 'Merge',
    // },
    // {
    //   code: 'DELETE',
    //   value: 'Delete',
    // },
  ],
  [CONVERSATION_STATUS_TYPES.PENDING]: [
    {
      code: 'OPEN',
      value: 'Open Chat',
      status: 0,
    },
    // {
    //   code: 'EDIT',
    //   value: 'Edit',
    // },
    // {
    //   code: 'MERGE',
    //   value: 'Merge',
    // },
    // {
    //   code: 'DELETE',
    //   value: 'Delete',
    // },
  ],
  [CONVERSATION_STATUS_TYPES.CLOSED]: [
    {
      code: 'REOPEN',
      value: 'Reopen',
      status: 0,
    },
    // {
    //   code: 'PENDING',
    //   value: 'Mark as pending',
    //   status: 2,
    // },
    // {
    //   code: 'EDIT',
    //   value: 'Edit',
    // },
    // {
    //   code: 'MERGE',
    //   value: 'Merge',
    // },
    // {
    //   code: 'DELETE',
    //   value: 'Delete',
    // },
  ],
};

export const EMAIL_TAG_VIEWS = {
  EMAIL_LIST: 'EMAIL_LIST',
};

export const BUTTON_TYPE = {
  PRIMARY: 'PRIMARY',
  PRIMARY_RIGHT_FLAT: 'PRIMARY_RIGHT_FLAT',
  PRIMARY_LEFT_FLAT: 'PRIMARY_LEFT_FLAT',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  NO_BORDER: "NO_BORDER",
  HOVERABLE_BUTTON: "HOVERABLE_BUTTON"
};

export const RECENT_ITEM_TYPES = {
  PATIENTS: 'Patients',
};

// Start Temp user cred will remove in coming sprint

export const commerceUserData = {
  grant_type: 'password',
  username: 'spree@example.com',
  password: 'spree123',
  scope: 'admin',
};

// End Temp user cred will remove in coming sprint

export const JOURNEYS_TABLE_VIEW = {
  ALL_JOURNEYS: 'ALL_JOURNEYS',
  PATIENT_JOURNEYS: 'PATIENT_JOURNEYS',
};

export const KANBANVIEWTYPE = {
  CARE_DASHBOARD: 'CARE_DASHBOARD',
  PATIENT_KANBAN: 'PATIENT_KANBAN',
};
export const FILE_TYPE = {
  PDF: 'PDF',
  VIDEO: 'VIDEO',
};

export const FIRST_MILE_TASKS =
  '[{"endDateTime":"2021-12-27T00:00:00.021498+00:00","id":"1","startDateTime":"2021-12-27T16:00:00.021498+00:00","title":"Completed Task","description":"All your completed tasks will show up here","isEscalated":false,"patientId":null,"status":{"id":"1","value":"Completed","code":"completed"},"taskType":{"code":"dummy","id":"1","value":"Care Program"},"patientEducations":[]},{"endDateTime":"2021-12-27T00:00:00.021498+00:00","id":"2","startDateTime":"2021-12-27T16:00:00.021498+00:00","title":"Pending Task","description":"All your pending tasks will show up here","isEscalated":false,"patientId":null,"status":{"id":"2","value":"","code":""},"taskType":{"code":"dummy","id":"2","value":"Care Program"},"patientEducations":[]}]';

export const GROUP_TYPES = {
  PRIVATE: 'PRIVATE',
  PATIENT_BROADCAST: 'PATIENT_BROADCAST',
  INTERNAL: 'INTERNAL',
  USER_BROADCAST: 'USER_BROADCAST',
};

export const GROUP_MEMBER_TYPE: {[index in IUserType]: IUserType} = {
  USER: 'USER',
  CONTACT: 'CONTACT',
  PATIENT: 'PATIENT',
  CUSTOMER: 'CUSTOMER'
};

export const PARENT_CODE = {
  PATIENT_INFO_LIST_VIEW: 'PATIENT_INFO_LIST_VIEW',
  MESSAGING_DETAIL_PANEL: 'MESSAGING_DETAIL_PANEL',
  SIDE_MENU_PANEL: 'SIDE_MENU_PANEL',
  TEAM_MANAGEMENT_PANEL: 'TEAM_MANAGEMENT_PANEL',
  INTEGRATION_LANDING: 'INTEGRATION_LANDING',
  MESSAGEBOX: 'MESSAGEBOX',
  ATTACHMENT_BOX: 'ATTACHMENT_BOX',
  CONVERSATION_QUICK_ACTION: 'CONVERSATION_QUICK_ACTION',
  SMS_CONVERSATION_DRAWER: 'SMS_CONVERSATION_DRAWER',
  CREATE_SMS_DRAWER: 'CREATE_SMS_DRAWER',
  MESSAGING_WINDOW_FOOTER: 'MESSAGING_WINDOW_FOOTER'
};

export const PARENT_CODE_CLASS_NAME: {[index: string]: string} = {
  PATIENT_INFO_LIST_VIEW: 'patient-info-list-view',
  MESSAGING_DETAIL_PANEL: 'messaging-detail-paneL',
  SIDE_MENU_PANEL: 'side-menu-panel',
  TEAM_MANAGEMENT_PANEL: 'team-management-panel',
  INTEGRATION_LANDING: 'integration-landing',
  MESSAGEBOX: 'messagebox',
  ATTACHMENT_BOX: 'attachment-box',
  CONVERSATION_QUICK_ACTION: 'conversation-quick-action',
  SMS_CONVERSATION_DRAWER: 'sms-conversation-drawer',
  CREATE_SMS_DRAWER: 'create-sms-drawer',
  MESSAGING_WINDOW_FOOTER: 'messaging-window-footer'
};

export const FHIR_RESOURCE = {
  CONDITION: 'Condition',
};
export const ACCESS_PROVISION_VIEW = {
  IVR_VIEW: true,
};

export const PHONE_NUMBER_MASK = `(###) ###-####`;

export const ENCRYPT_KEY = 'ewfWE@#%$rfdsefgdsfa';

// Encrypt

export const OPTION_SELECTION_TYPE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

export const OPTION_TYPE = {
  USER: 'USER',
  RELATION: 'RELATION',
  PATIENT: 'PATIENT',
  TAGS: 'TAGS',
  ZIPCODE: 'ZIPCODE',
  GENDER: 'GENDER',
  BLOOD_GROUP: 'BLOOD_GROUP',
  CONTACT_TYPE: 'CONTACT_TYPE',
};

export const CONTACT_SOURCE_OF_CREATION = 'FOLD_ADMIN_APP_WEB';

export const CONTACT_SOURCE_DISPLAY_STR: {[index in IContactSource]: string} = {
  APPOINTMENT_WIDGET: 'Appointment widget',
  BULK_IMPORT: 'BULK Import',
  CALL: 'Call',
  EMAIL: 'EMAIL',
  FOLD_ADMIN_APP_MOBILE: 'Admin App(Mobile)',
  FOLD_ADMIN_APP_WEB: 'Admin App(Web)',
  FORM: 'Form',
  SMS: 'SMS',
  UNKNOWN: 'Unknown',
  WEB_WIDGET: 'Web Widget',
  MEMBERSHIP_PURCHASE_REQUEST: 'Membership purchase request',
};
export const WINDOW_EVENT_CODES = {
  FORM_SUBMIT: 'FORM_SUBMIT',
  ORDER_UPDATES: 'ORDER_UPDATES',
  PATIENT_UPDATES: 'PATIENT_UPDATES',
};

export const SELECT_OPTION_DATA_TYPE = {
  SUPPORT_MEMBER: 'supportingMember',
  TAGS: 'tags',
  ZIPCODE: 'zipcode',
  GENDER: 'GENDER',
  BLOOD_GROUP: 'BLOOD_GROUP',
  CONTACT_TYPE: 'CONTACT_TYPE',
};

export const MEMBER_STATUS = [
  {value: 'ALL', label: 'All', code: 'ALL', isSelected: false, isDefault: true},
  {value: 'INACTIVE', label: 'Inactive', code: 'INACTIVE', isSelected: false},
  {value: 'ACTIVE', label: 'Active', code: 'ACTIVE', isSelected: false},
];

export const LOGGED_IN_STATUS = [
  {value: 'ALL', label: 'All', code: 'ALL', isSelected: false, isDefault: true},
  {value: 'NOT_LOGGED_IN', label: 'Not Logged In', code: 'NOT_LOGGED_IN', isSelected: false},
  {value: 'LOGGED_IN', label: 'Logged In', code: 'LOGGED_IN', isSelected: false},
]

export const EMPLOYEE_TYPE_STATUS = [
  {value: 'Employee', label: 'Employee', code: 'employee', isSelected: false},
  {value: 'Child', label: 'Child', code: 'child', isSelected: false},
  {value: 'Spouse', label: 'Spouse', code: 'spouse', isSelected: false},
];

export const RESPONSE_VIEW_CODE = {
  ADD_RESPONSE: 'ADD_RESPONSE',
  EDIT_RESPONSE: 'EDIT_RESPONSE',
  DELETE_RESPONSE: 'DELETE_RESPONSE',
};

export const RESPONSE_CATEGORY_CODE = {
  CHAT: 'CHAT',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  ALL: 'ALL',
  NOTES: 'NOTES',
};

export const IS_DEACTIVATE_CONTACT_ENABLED = true;
export const IS_MINICONTACT_VIEW_ON_LEADS = true;

export const MEMBERSHIP_PRICE_CODE = {
  ONE_TIME_REGISTRATION_FEE: 'ONE_TIME_REGISTRATION_FEE',
  MONTHLY_CHARGES: 'MONTHLY_CHARGES',
  QUARTERLY_CHARGES: 'QUARTERLY_CHARGES',
  ANNUALLY_CHARGES: 'ANNUALLY_CHARGES',
};

export const PRODUCT_SERVICES_FLOW_TYPE_CODE = {
  PRODUCTS: 'products',
  SERVICES: 'services',
  JOURNEYS: 'journeys',
  MEMBERSHIP: 'membership',
  PACKAGES: 'packages',
};
export const MEMBERSHIP_INTERVAL_CODE = {
  ONE_TIME: 'one_time',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
  DAILY: 'daily',
};
export const STRIPE_INTERVAL_CODE = {
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
  YEAR: 'year',
  MONTH: 'month',
  DAILY: 'daily',
  DAY: 'day',
  ONE_TIME: 'one_time',
};

export const BILLING_PLAN_TYPES = {
  CHAT: 'Chat',
  SMS: 'SMS',
  VIDEO: 'Video',
  VOICE: 'Voice',
  ACTIVE_LIVES: 'Active Lives',
  EMAIL: 'Email',
  EMPLOYEE: 'Employee',
  EMPLOYER: 'Employer',
  CAMPAIGN: 'Campaign',
  PROSPECTS: 'Prospects',
};

export const IPAD_WIDTH = 1024;
export const SMALL_WINDOW = 1620;
export const SMALL_WINDOW_1700 = 1700;
export const SMALL_WINDOW_1400 = 1400;
export const SMALL_WINDOW_1500 = 1500;
export const SMALL_SCREEN = 720;
export const EXTRA_SMALL_SCREEN = 550;
export const SMALL_MOBILE_SCREEN = 386;

export const MESSAGING_WINDOW_HEADER_HEIGHT =  68;

export const BANNER_SCREEN_OFFSET_WIDTH = {
  BANNER_SCREEN_OFFSET_300: 300,
  BANNER_SCREEN_OFFSET_250: 250,
}

export const CLOUD_TELEPHONY_CODE = {
  OUTGOING: 'OUTGOING',
  INCOMING: 'INCOMING',
  MISSED_CALL: 'MISSED_CALL'
};
export const IPAD_MINI_WIDTH = 768;

export const MEMBERSHIP_STATUS = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  PENDING: 'PENDING',
  SUCCEEDED: 'succeeded'
};

export const SUBSCRIPTION_API_STATUS = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  PENDING: 'incomplete',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
};
export const SUBSCRIPTION_SORT_ORDER = [
  SUBSCRIPTION_API_STATUS.ACTIVE,
  SUBSCRIPTION_API_STATUS.CANCELED,
  SUBSCRIPTION_API_STATUS.INCOMPLETE,
  SUBSCRIPTION_API_STATUS.INCOMPLETE_EXPIRED,
  SUBSCRIPTION_API_STATUS.UNPAID,
  SUBSCRIPTION_API_STATUS.PAST_DUE,
  SUBSCRIPTION_API_STATUS.TRIALING,
];
export const PREFERRED_CONTACT_CODE = {
  PRIMARY_EMAIL: 'PRIMARY_EMAIL',
  PRIMARY_PHONE: 'PRIMARY_PHONE',
  SECONDARY_EMAIL: 'SECONDARY_EMAIL',
  SECONDARY_PHONE: 'SECONDARY_PHONE',
};
export const canShowSubscriptionInfo = true;

export const CARE_JOURNEY_TABLE_SORTS = {
  BY_NAME: 'name',
  BY_PRICE: 'price',
  BY_UPDATED_AT: 'updated_at',
  BY_TITLE: 'title'
};
export const CARE_JOURNEY_TABLE_SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
  DESC_NULL_LAST: 'desc_nulls_last'
};
export const SORT_TYPE = {
  ASC: 'ascend',
  DESC: 'descend',
};

export const CONSENT_ERROR =
  'Please give consent to send a communication via email, SMS, voice or any other electronic media';
export const CONSENT_ERROR_FOR_SIDE_CAR =
  'Please log in to the Fold Portal to provide consent so that the patient can receive communications via email, SMS, voice, or other electronic media.';
export const PATIENT_CONSENT_ERROR =
  'to send a communication via email, SMS, voice or any other electronic media';
export const PREFERRED_CHANNEL_CODE = {
  VOICE: 'VOICE',
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};
export const PATIENT_NUMBER_CHANGED_ERROR =
  `You cannot reply to this thread because patient's phone number has changed. To continue texting, start a new conversation with the updated number.`;
export const getInActiveContactError = (contactData: any) => {
  const contactType =
    contactData?.contactType?.contactType?.code == PERSON_TYPES.CUSTOMER ||
      contactData?.contactType?.code == PERSON_TYPES.CUSTOMER
      ? 'Patient'
      : 'Prospect';
  return ` The ${contactType} is not active, please mark as active to take any actions. `;
};
export const TCPA_INFO = `The TCPA prohibits any telephone solicitation (including text messages) anytime before 8 am and after 9 pm in the recipient's time zone (known as "quiet hours"), but certain states have more restrictive rules. To be safe, you should only send texts during normal hours (9 am to 8 pm in the recipient's time zone).`;
export const COLLABORATORS_INFO = `Internal team users who can assess the incoming SMS on above from number, and also able to send an outbound message through same number.`;

export const AGE_ATTRIBUTES = {
  BETWEEN_AGE: 'BTW',
  GREATER_THEN: 'GT',
  LESS_THEN: 'LT',
};
export const DEFAULT_COMMUNICATION_GROUP_NAME = 'Default Communication Group';

export const RULE_GROUP_FACE_CONST = {
  AGE: 'age ',
};

export const setSmsInboxErr =
  'Please set up the SMS inbox to mark the number as a default SMS number';
export const releaseNumberErr =
  'The number is already marked as default for calling/SMS, please set another number as default to continue to release the number.';
export const releaseNumberErrForDefaultLocation =
  'The number is marked as default for calling/SMS for the location, please remove number as default for location to continue to release the number.';
export const releaseNumberErrForDefaultAccountLocation =
  'The number is marked as default for calling/SMS for the location and account.';
export const removedDefault = 'Number removed as default successfully';
export const setAsDefault = 'Number set as default successfully';
export const notAllowed = 'Only business owner allowed to make these changes';
export const apiError = 'Something went wrong';
export const categoryAlreadyExistMessage = 'category name already exist. Please enter another category name.'
export const documentCaptionError = 'Please add caption to document'
export const documentCaptionDuplicateError = 'Cannot add this document, since document with the same name exists in this Journey'

export const setDefaultMessageForLocation = 'Number set as default for location successfully'
export const removeDefaultMessageForLocation = 'Number removed as default for location successfully'
export const MESSAGE_WINDOW_TABS = {
  CHAT: 'Chat',
  FILES: 'Files',
};

export const MESSAGE_TAB_OPTION = {
  CHAT: 'Chat'
}

export const PAGINATION_DIRECTION = {
  PREV: 'PREV',
  NEXT: 'NEXT',
};

export const ANALYTICS_RESOURCE = {
  EMPLOYEE_COUNT: 'EMPLOYEE_COUNT',
  CLINICAL_INTERVENTION_MESSAGE_NOTES: 'CLINICAL_INTERVENTION_MESSAGE_NOTES',
  EMPLOYEE_AGE_GROUP: 'EMPLOYEE_AGE_GROUP',
  EMPLOYEE_GENDER_GROUP: 'EMPLOYEE_GENDER_GROUP',
  CRM_ACTIVITY: 'CRM_ACTIVITY',
  CHAT_MESSAGING: 'CHAT_MESSAGING',
  VIDEO_CALLING: 'VIDEO_CALLING',
  VOICE_CALLING: 'VOICE_CALLING',
  ERX_SAVINGS: 'ERX_SAVINGS',
  EMPLOYEE_LOGGED_IN: 'EMPLOYEE_LOGGED_IN',
  AUTOMATION_TIME_SAVED: 'AUTOMATION_TIME_SAVED',
  LAB_SAVINGS: 'LAB_SAVINGS',
  IMAGING_SAVINGS: 'IMAGING_SAVINGS',
  VISITS_SAVINGS: 'VISITS_SAVINGS',
  CLAIMS_AVOIDED: 'CLAIMS_AVOIDED',
  WEIGHT_REDUCED: 'WEIGHT_REDUCED',
  AVERAGE_BMI_REDUCED: 'AVERAGE_BMI_REDUCED',
  CHRONIC_REVERSAL: 'CHRONIC_REVERSAL',
  SMOKING_DISCONTINUED: 'SMOKING_DISCONTINUED',
  WORK_COMPS_AVOIDED: 'WORK_COMPS_AVOIDED',
  ER_ADMISSIONS_AVOIDED: 'ER_ADMISSIONS_AVOIDED',
  SYMPTOMS_REPORTED: 'SYMPTOMS_REPORTED',
  APPOINTMENT_BOOKED: 'APPOINTMENT_BOOKED',
  APPOINTMENT_SELF_SCHEDULED: 'APPOINTMENT_SELF_SCHEDULED',
  MEDICATION_ADHERENCE: 'MEDICATION_ADHERENCE',
  CARE_JOURNEY_ADHERENCE: 'CARE_JOURNEY_ADHERENCE',
  NO_SHOWS: 'NO_SHOWS',
  TOP_DIAGNOSIS: 'TOP_DIAGNOSIS',
  EMPLOYEE_VS_DEPENDANT_VISITS: 'EMPLOYEE_VS_DEPENDANT_VISITS',
  ENCOUNTER_TYPES: 'ENCOUNTER_TYPES',
  EMPLOYEE_ENROLLMENT_RETENTION: 'EMPLOYEE_ENROLLMENT_RETENTION',
  TOP_MEDICATIONS: 'TOP_MEDICATIONS',
  TOP_CARE_JOURNEYS: 'TOP_CARE_JOURNEYS',
  TOP_LABS: 'TOP_LABS',
  TOP_IMAGING: 'TOP_IMAGING',
  TOP_SYMPTOMS_CHIEF_COMPLAINTS: 'TOP_SYMPTOMS_CHIEF_COMPLAINTS',
  ENROLLED_FOR_CARE: 'ENROLLED_FOR_CARE',
  ENROLLED_FOR_CHRONIC_CARE: 'ENROLLED_FOR_CHRONIC_CARE',
  EMPLOYEE_WELLNESS_INDEX: 'EMPLOYEE_WELLNESS_INDEX',
  CUSTOMER_SATISFACTION: 'CUSTOMER_SATISFACTION',
  USER_SATISFACTION: 'USER_SATISFACTION',
  EMPLOYER_SATISFACTION: 'EMPLOYER_SATISFACTION',
  CHRONIC_RISKS: 'CHRONIC_RISKS',
  PROJECTED_ROI: 'PROJECTED_ROI',
  EXPENSE_PARETO_CURVES: 'EXPENSE_PARETO_CURVES',
  TOTAL_SAVINGS: 'TOTAL_SAVINGS',
  PROCEDURES_SAVINGS: 'PROCEDURES_SAVINGS',
  SMOKING_SENSATION: 'SMOKING_SENSATION',
  EXPENSE_PARETO_CURVES_TREND: 'EXPENSE_PARETO_CURVES_TREND',
  CHRONIC_RISKS_TREND: 'CHRONIC_RISKS_TREND',
  EMPLOYEE_WELLNESS_INDEX_TREND: 'EMPLOYEE_WELLNESS_INDEX_TREND',
  NEW_ENROLLED: 'NEW_ENROLLED',
  CLAIMS: 'CLAIMS',
  APPOINTMENT_BY_TIME: 'APPOINTMENT_BY_TIME',
  APPOINTMENT_BY_DAY: 'APPOINTMENT_BY_DAY',
  APPOINTMENT_AGGREGATE: 'APPOINTMENT_AGGREGATE',
  APPOINTMENT_TYPE: 'APPOINTMENT_TYPE',
  SMS_MESSAGING: 'SMS_MESSAGING',
  PATIENT_APP_MESSAGING: 'PATIENT_APP_MESSAGING',
  VOICE_CALLING_DIRECTION_WISE: 'VOICE_CALLING_DIRECTION_WISE',
  ED_UTILIZATION_AMOUNT: 'ED_UTILIZATION_AMOUNT',
  ED_UTILIZATION_PERCENT: 'ED_UTILIZATION_PERCENT',
  ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT: 'ED_UTILIZATION_EMPLOYEE_VS_DEPENDENT',
  ED_UTILIZATION_AMORTIZATION: 'ED_UTILIZATION_AMORTIZATION',
  TOTAL_CLAIM_COST: 'TOTAL_CLAIM_COST',
  USER_MESSAGING: 'USER_MESSAGING',
  MEMBERSHIP_REVENUE_GROWTH: 'MEMBERSHIP_REVENUE_GROWTH',
  MEMBERSHIP_INACTIVATED: 'MEMBERSHIP_INACTIVATED',
  MEMBERSHIP_NET_GROWTH: 'MEMBERSHIP_NET_GROWTH',
  OUT_OFFICE_HOUR_USER_ENGAGEMENT: 'OUT_OFFICE_HOUR_USER_ENGAGEMENT',
  ENGAGEMENT_ON_APPLIED_RULES: 'ENGAGEMENT_ON_APPLIED_RULES',
  FOLD_CONVERSIONS: 'FOLD_CONVERSIONS',
  FOLD_INACTIVE_ENGAGEMENT: 'FOLD_INACTIVE_ENGAGEMENT',
  DPC_STRATIFICATION: 'DPC_STRATIFICATION',
  IP_CLAIM_COST: 'IP_CLAIM_COST',
  URGENT_CARE_CLAIM_COST: 'URGENT_CARE_CLAIM_COST',
  BILLING_CPT_CODES: 'BILLING_CPT_CODES',
  BILLING_CPT_CODES_AVOIDED_ED: 'BILLING_CPT_CODES_AVOIDED_ED',
  MEMBER_SATISFACTION: 'MEMBER_SATISFACTION',
  ALL_CLAIM_DATA: 'ALL_CLAIM_DATA',
  GROUP_SAVING: 'GROUP_SAVING',
  NET_PROMOTER_SCORE: 'NET_PROMOTER_SCORE',
  MEMBER_SATISFACTION_SURVEY: 'MEMBER_SATISFACTION_SURVEY',
  COMMUNICATION_STATS: 'COMMUNICATION_STATS',
  APPOINTMENT_SATISFACTION: 'APPOINTMENT_SATISFACTION',
  APPOINTMENT_RATING: 'APPOINTMENT_RATING',
  AVOIDED_WORKDAY_ABSENCE_SAVING: 'AVOIDED_WORKDAY_ABSENCE_SAVING',
  AVOIDABLE_VISIT_SAVING: 'AVOIDABLE_VISIT_SAVING',
  PRE_DPC_VS_POST_DPC: 'PRE_DPC_VS_POST_DPC',
  TELEPHONE_BY_DAY: 'TELEPHONE_BY_DAY',
  TELEPHONE_BY_TIME: 'TELEPHONE_BY_TIME',
  PATIENT_ABSENCE_ANALYSIS: 'PATIENT_ABSENCE_ANALYSIS',
  USER_VIDEO_USAGE: 'USER_VIDEO_USAGE',
  NOT_ENROLLED_FOR_CARE: 'NOT_ENROLLED_FOR_CARE',
  USER_APPOINTMENT_TIME_USAGE: 'USER_APPOINTMENT_TIME_USAGE'
};


export const EMPLOYER_REPORT_FILTER_CODES = {
  DATE_RANGE: 'DATE_RANGE',
  DASHBOARD_DATE_RANGE: 'DASHBOARD_DATE_RANGE',
  GENDER: 'GENDER',
  AGE_GROUP: 'AGE_GROUP',
  CONDITION: 'CONDITION',
  GENERIC: 'GENERIC',
  EMPLOYEE_TYPE: 'EMPLOYEE_TYPE',
  MSG_SENDER_TYPE: 'MSG_SENDER_TYPE',
  EXPENSE_PARETO_CURVE_PERCENTAGE: 'EXPENSE_PARETO_CURVE_PERCENTAGE',
  TOP_CHRONIC_CONDITION: 'TOP_CHRONIC_CONDITION',
  APPOINTMENT_MODE: 'APPOINTMENT_MODE',
  APPOINTMENT_TYPE: 'APPOINTMENT_TYPE',
  APPOINTMENT_STATUS: 'APPOINTMENT_STATUS',
  LOCATION: 'LOCATION',
  DAY_NAME: 'DAY_NAME',
  TIME_GROUP: 'TIME_GROUP',
  CPT_CODES: 'CPT_CODES',
  REVENUE_CODES: 'REVENUE_CODES',
  CATEGORY: 'CATEGORY',
  CALL_TYPE: 'CALL_TYPE',
  OUT_OF_OFFICE_TYPE_CODE: 'OUT_OF_OFFICE_TYPE_CODE',
  PARENT_RULE: 'PARENT_RULE',
  CHILD_RULES: 'CHILD_RULES',
  RULE_ID: 'RULE_ID',
  BILLING_CPT_CODES: 'BILLING_CPT_CODES',
  CLAIM_MEMBER_CODE: 'CLAIM_MEMBER_CODE',
  FORM_ID: 'FORM_ID',
  FORM_STATUS_CODE: 'FORM_STATUS_CODE',
  NPS_SCORE_CATEGORY: 'NPS_SCORE_CATEGORY',
  FORM_QUESTION_ID: 'FORM_QUESTION_ID',
  CHRONIC_CONDITION: 'CHRONIC_CONDITION',
  NOT_ENGAGED_MEMBER_MONTH: 'NOT_ENGAGED_MEMBER_MONTH',
  ENGAGED_MEMBER_MONTH: 'ENGAGED_MEMBER_MONTH',
  PRE_VS_POST_DCP_WEEK: 'PRE_VS_POST_DCP_WEEK',
  ENGAGED_OF_CARE: 'ENGAGED_OF_CARE',
  NOT_SEEN_PATIENT_MONTH: 'NOT_SEEN_PATIENT_MONTH',
  MEMBERSHIP: 'MEMBERSHIP'
};

export const SECTION_LIST = {
  OVERVIEW: 'OVERVIEW',
  TRENDS: 'TRENDS',
  OPPORTUNITIES: 'OPPORTUNITIES',
  SAVINGS: 'SAVINGS',
  EVENTS_CONDUCTED: 'EVENTS_CONDUCTED',
  WINS: 'WINS',
  APP_ANALYTICS_ENGAGEMENT: 'APP_ANALYTICS_ENGAGEMENT',
  PATIENT_STATISTICS: 'PATIENT_STATISTICS',
  UTILIZATION: 'UTILIZATION',
  AVOIDABLE_EMERGENCY_VISITS: 'AVOIDABLE_EMERGENCY_VISITS',
  PATIENT_ENCOUNTERS: 'PATIENT_ENCOUNTERS',
  CLAIMS_COST_ANALYSIS: 'CLAIMS_COST_ANALYSIS',
  IP_URGENT_CARE_UTILIZATION: 'IP_URGENT_CARE_UTILIZATION',
  OUTCOMES: 'OUTCOMES',
  OPERATIONS: 'OPERATIONS'
};

export const WIDGET_TYPE = {
  CARD: 'CARD',
  COUNT: 'COUNT',
  BAR_CHART: 'BAR_CHART',
};
export const DISPLAY_TYPE = {
  COUNT: 'COUNT',
  PIE_CHART: 'PIE_CHART',
  BAR_CHART: 'BAR_CHART',
  BAR_CHART_WITH_STACK_COLUMN: 'BAR_CHART_WITH_STACK_COLUMN',
  BAR_CHART_WITH_STACK_AND_GROUP_COLUMN: 'BAR_CHART_WITH_STACK_AND_GROUP_COLUMN',
  BAR_CHART_WITH_GROUP_COLUMN: 'BAR_CHART_WITH_GROUP_COLUMN',
  H_BAR_CHART_WITH_TOP_ITEM: 'H_BAR_CHART_WITH_TOP_ITEM',
  COUNT_WITH_LINE_GRAPH: 'COUNT_WITH_LINE_GRAPH',
  COUNT_VERTICAL_WITH_LINE_GRAPH: 'COUNT_VERTICAL_WITH_LINE_GRAPH',
  LISTING_WIDGET_WITH_LIST: 'LISTING_WIDGET_WITH_LIST',
  LISTING_WIDGET_WITH_PROGRESS: 'LISTING_WIDGET_WITH_PROGRESS',
  STATUS_NUMBER_LINE: 'STATUS_NUMBER_LINE',
  SAVING_WIDGET: 'SAVING_WIDGET',
  SAVING_WIDGET_LINE_PIE: 'SAVING_WIDGET_LINE_PIE',
  APPOINTMENT_WITH_GROUP_BAR_VIEW: 'APPOINTMENT_WITH_GROUP_BAR_VIEW',
  APPOINTMENT_WITH_HORIZONTAL_GROUP_VIEW: 'APPOINTMENT_WITH_HORIZONTAL_GROUP_VIEW',
  APPOINTMENT_WITH_HORIZONTAL_VIEW: 'APPOINTMENT_WITH_HORIZONTAL_VIEW',
  APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW: 'APPOINTMENT_WITH_HORIZONTAL_STACK_VIEW',
  HORIZONTAL_GROUP_BAR_WITH_LINE_VIEW: 'HORIZONTAL_GROUP_BAR_WITH_LINE_VIEW',
  HORIZONTAL_STACK_BAR_VIEW: 'HORIZONTAL_STACK_BAR_VIEW',
  MULTIPLE_LINE_CHARTS: 'MULTIPLE_LINE_CHARTS',
  HORIZONTAL_BAR_CHART: 'HORIZONTAL_BAR_CHART',
  BASIC_LINE_CHART: 'BASIC_LINE_CHART',
  DUAL_MULTIPLE_LINE_CHARTS: 'DUAL_MULTIPLE_LINE_CHARTS',
  MULTIPLE_LINE_CHARTS_WITH_SLIDER: 'MULTIPLE_LINE_CHARTS_WITH_SLIDER',
  MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE: 'MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
  SAVING_GROUP_CUSTOM: 'SAVING_GROUP_CUSTOM',
  BIDIRECTIONAL_BAR_GRAPH: 'BIDIRECTIONAL_BAR_GRAPH',
  DETAIL_WITH_STACK_COLUMN: 'DETAIL_WITH_STACK_COLUMN',
  DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE: 'DETAIL_WITH_MIX_BAR_CHART_WITH_STACK_COLUMN_WITH_LINE',
  DETAIL_WITH_BAR_CHART_WITH_STACK_COLUMN: 'DETAIL_WITH_BAR_CHART_WITH_STACK_COLUMN',
  STAR_RATING_CHART: 'STAR_RATING_CHART',
  MULTI_PLOT_VIEW_CHART: 'MULTI_PLOT_VIEW_CHART',
  APPOINTMENT_WITH_STACK_BAR_VIEW: 'APPOINTMENT_WITH_STACK_BAR_VIEW'
};
export const monthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];


export const DData = {
  edit: false,
  email: 'dumytstforEmail@yopmail.com',
  employerId: '',
  externalUserId: '',
  firstName: 'dumyName',
  id: 0,
  lastName: 'dumyName',
  role: 'EXTERNAL_CASE_MANAGER',
  roleName: 'External Case Manager',
};


export const AUTO_RESPONSE_TYPES = {
  SMS: "SMS",
  EMAIL: 'EMAIL',
  WIDGET: 'WIDGET',
  CHAT: 'CHAT',
  PATIENT_ONE_ON_ONE_CHAT: 'PATIENT_ONE_ON_ONE_CHAT',
}
export const PROVIDER_APP_URL =
  'https://apps.apple.com/us/app/fold-health/id1626246460';

export const THRESHOLD = 1024;

export const ORDER_TYPE = {
  LAB: 'LAB',
  RAD: 'RADIOLOGY',
  MED: 'MEDICATION'
};

export const ADD_NEW_REASON_FOR_VISIT = 'ADD_NEW_REASON_FOR_VISIT';

export const HTTP_ERROR_CODE = {
  METHOD_NOT_ALLOWED: 405,
  INVALID_INPUT: 400,
  RESOURCE_NOT_FOUND: 404,
}

export const PATIENT_NOTE_API_ERROR_CODE = {
  SIGNED_NOTE_DELETE: 'SIGNED_NOTE_DELETE',
  SIGNED_ORDER_NOTE_DELETE: 'SIGNED_ORDER_NOTE_DELETE',
};

export const COPY_MESSAGE_CODE = {
  COPY_MODE_OFF: 'COPY_MODE_OFF',
  COPY_MODE_ON: 'COPY_MODE_ON',
  COPY_MODE_COUNT: 'COPY_MODE_COUNT',
  COPY_MODE_COPIED: 'COPY_MODE_COPIED',
  COPY_MODE_COUNT_OFF: 'COPY_MODE_COUNT_OFF',
}

export const CONSENT_CONST = {
  consentToEmail: 'EMAIL',
  consentToText: 'SMS',
  consentToCall: 'VOICE'
};
export const GROUP_EVENT_CODE = {
  GROUP_CONVERSATION_CREATED: 'GROUP_CONVERSATION_CREATED',
  GROUP_CONVERSATION_DETAIL_UPDATED: 'GROUP_CONVERSATION_DETAIL_UPDATED',
  GROUP_MEMBER_ADDED: 'GROUP_MEMBER_ADDED',
  GROUP_MEMBER_REMOVED: 'GROUP_MEMBER_REMOVED',
  GROUP_MESSAGE_UPDATED: 'GROUP_MESSAGE_UPDATED',
  GROUP_NAME_UPDATED: 'GROUP_NAME_UPDATED'
};

export const GROUP_EVENT_CODE_ARRAY = Object.values(GROUP_EVENT_CODE);

export const SEXUAL_ORIENTATION_OTHER_CODE = 'Something else';

export const CONVERSATION_CONST = {
  GROUP_CHAT: 'Group Chat'
}

export const changeNumberFromDefaultLocation = 'This number is already set as default for current location. By confirming , this number will set as default for new location. '
export const THIRTEEN_INCH_WIDTH = 1248
export const eleven_inch_width = 1056

export const TASK_POOL_LOCAL_TYPE = {
  OTHER_USER: 'otherPool',
  SELECTED_USER: 'userPool'
};
export const CONTACT_CLAIMS_CONST = {
  CPT_CODE: 'CPT_CODE',
  REVENUE_CODE: 'REVENUE_CODE'
}

export const nonEntryPropertyList = [
  'countNum',
  'monthDt',
  'month',
  'totalAmount',
  'alcoholAmount',
  'drugAmount',
  'psychAmount',
  'injury',
  'total',
  'alcohol',
  'drug',
  'psych',
  'injury',
  'monthDate',
  'memberCount'
];

export const keyVisibleForAccountDashboard = [
  'selfSponsored',
  'oneOff',
  'Self-Sponsored'
]

export const LOCATION_FILTER_RESOURCE_CODE = [
  ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS,
  ANALYTICS_RESOURCE.APPOINTMENT_TYPE,
  ANALYTICS_RESOURCE.ENCOUNTER_TYPES,
  ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY,
  ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME,
  ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED
];

export const NOTE_TYPE = {
  CLINICAL_NOTE: 'CLINICAL_NOTE',
  CONTACT_NOTE: 'CONTACT_NOTE'
}

export const ALL_LOCATION_CODE = 'ALL_LOCATIONS';
export const POPULATION_GROUP_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC',
  STATIC_LEAD: 'STATIC_LEAD',
  DYNAMIC_LEAD: 'DYNAMIC_LEAD',
} as const;

export const AUTO_RESPONSE_ASSIGNEE_TYPES = {
  USER: 'USER',
  LOCATION: 'LOCATION',
};

export const MOBILE_LOCAL_STORAGE = {
  DEFAULT_SELECTED_TAB: 'defaultSelectedTab'
};
export const APPOINTMENT_MODE_CODE = {
  IN_PERSON: 'IN_PERSON',
  VIDEO: 'VIDEO'
};

export const HIDE_TOTAL_COUNT_IN_LEGEND = [
  ANALYTICS_RESOURCE.EMPLOYEE_COUNT,
  ANALYTICS_RESOURCE.ENROLLED_FOR_CARE,
  ANALYTICS_RESOURCE.CUSTOMER_SATISFACTION,
  ANALYTICS_RESOURCE.USER_SATISFACTION,
  ANALYTICS_RESOURCE.EMPLOYER_SATISFACTION,
  ANALYTICS_RESOURCE.USER_SATISFACTION,
  ANALYTICS_RESOURCE.MEMBERSHIP_INACTIVATED,
  ANALYTICS_RESOURCE.MEMBERSHIP_NET_GROWTH,
  ANALYTICS_RESOURCE.MEMBERSHIP_REVENUE_GROWTH,
];

export const scrollViewEmployer = false;

export const CLAIMS_MEMBER_STATUS = {
  MEMBER: 'MEMBER',
  NON_MEMBER: 'NON_MEMBER'
};

export const SMS_DELIVERY_STATUS = {
  SENT: 'sent',
  DELIVERED: 'delivered',
  UNDELIVERED: 'undelivered',
  FAILED: 'failed',
};

export const CHAT_DELIVERY_STATUS = {
  SENT: 'sent',
  DELIVERED: 'delivered',
  UNDELIVERED: 'undelivered',
  FAILED: 'failed',
  API_FAILED: 'API failed'
};

export const CRM_ACTIVITY_TIMELINE_CONST = {
  ALL_ACTIVITIES: 'All activities',
  MESSAGE: 'MESSAGE',
  CALL: 'CALL',
  APPOINTMENT: 'APPOINTMENT',
  AUTOMATION: 'AUTOMATION',
  MEMBERSHIP: 'MEMBERSHIP',
  TASK: 'TASK',
  FORM: 'FORM_RESPONSE',
  VIDEO_CALL: 'VIDEO_CALL',
}

export const FILTER_OPTIONS = [
  {label: 'All activities', value: CRM_ACTIVITY_TIMELINE_CONST.ALL_ACTIVITIES},
  {label: 'Messages', value: CRM_ACTIVITY_TIMELINE_CONST.MESSAGE},
  {label: 'Call', value: CRM_ACTIVITY_TIMELINE_CONST.CALL},
  // {label: 'Appointment', value: CRM_ACTIVITY_TIMELINE_CONST.APPOINTMENT},
  // {label: 'Notes', value: CRM_ACTIVITY_TIMELINE_CONST.NOTES},
  // {label: 'Automation', value: CRM_ACTIVITY_TIMELINE_CONST.AUTOMATION},
  // {label: 'Membership', value: CRM_ACTIVITY_TIMELINE_CONST.MEMBERSHIP},
  {label: 'Task', value: CRM_ACTIVITY_TIMELINE_CONST.TASK},
  {label: 'Form', value: CRM_ACTIVITY_TIMELINE_CONST.FORM},
  {label: 'Video Call', value: CRM_ACTIVITY_TIMELINE_CONST.VIDEO_CALL},
];


export const COUNTRY_CODE = {
  US_COUNTRY_CODE: '+1'
}
export const GOAL_STATUS_CODES = {
  ACHIEVED: 'achieved',
  NOT_ACHIEVED: 'not-achieved',
  NO_CHANGE: 'no-change',
}

export const GOAL_TRACK_VALUES = {
  ON_TRACK: 'On Track',
  OFF_TRACK: 'Off Track',
  NO_CHANGE: 'Select',
}


export const DRILL_DOWN_FILTERS = {
  COMMUNICATION_TYPE_DROPDOWN: 'COMMUNICATION_TYPE_DROPDOWN'
}

export const DRILL_DOWN_FILTERS_MAP = {
  [ANALYTICS_RESOURCE.COMMUNICATION_STATS]: [
    DRILL_DOWN_FILTERS.COMMUNICATION_TYPE_DROPDOWN,
  ],
};

export const KEYBOARD_KEY_CODE = {
  ENTER: 'Enter'
}
export const MENTION_READ_STATUS = {
  READ: 'read',
  UNREAD: 'unread',
  ALL: 'all',
};

export const NOTIFICATION_SETTING = {
  BOTH: 'BOTH',
  IN: 'IN',
  OUT: 'OUT',
  ON: 'ON',
  OFF: 'OFF',
}

export const CONSENT_CODE = {
  VOICE: 'Voice',
  CALL: 'Call',
  SMS: 'SMS',
  TEXT: 'Text'
};


export enum CONVERSATION_STATUS {
  OPEN = 0,
  RESOLVED = 1,
  PENDING = 2,
  SNOOZED = 3
};

export enum MESSAGE_STATUS {
  sent = 0,
  delivered = 1,
  read = 2,
  failed = 3,
}

export const CONVERSATION_STATUS_STRING = {
  OPEN: 'open',
  RESOLVED: 'resolved',
};
export const CREATE_CHANNEL_CONFIRMATION_MODAL = {
  REMOVE_ASSIGNED_USER: 'REMOVE_ASSIGNED_USER'
}

export const DEFAULT_GENERAL_GROUP = 'DEFAULT_GENERAL_GROUP';

export const MOMENT_DATE_CONST = {
  START_OF: 'START_OF',
  END_OF: 'END_OF',
};

export const MEMBER_STATUS_ACTIVE = 'ACTIVE';

export const MEMBERSHIP_STATUS_ALL = 'all';
export const MEETING_VENDORS = {
  ZOOM: 'ZOOM',
  HMS: 'HMS',
}
export const ONLINE_STATUS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

export const CONVERSATION_PATH_LIST = [
  '/inbox/assigned_me',
  '/inbox/assigned_other',
  '/inbox/unassigned',
  '/inbox/mentions',
  '/inbox/missed',
  '/inbox/archived',
  '/inbox/allConversations',
  '/inbox/conversations',
  '/inbox/conversation',
  '/inbox/sms',
  '/inbox/email',
  '/inbox/internal',
  '/inbox/fax'
]

export const CONVERSATION_TAGGED_ITEM_TYPE = {
  TASK: 'TASK',
  NOTE: 'NOTE',
  FORM: 'FORM',
  ARTICLES: 'ARTICLES',
  ATTACHMENT: 'ATTACHMENT'
};

export const CAMPAIGN_STATUS = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  ENDED: 'ENDED',
  ABORTED: 'ABORTED',
  PAUSED: 'PAUSED',
  RESUMED: 'RESUMED'
} as const

export const CAMPAIGN_ACTIONS = {
  EDIT : 'EDIT',
  HISTORY: 'HISTORY',
  DUPLICATE: 'DUPLICATE',
  ABORT: 'ABORT',
  DELETE: 'DELETE',
  PLAY: 'PLAY',
  PAUSE: 'PAUSE',
  START: 'START',
  RESUME: 'RESUME'
}

export const STATE_WISE_CAMPAIGN_ACTIONS = {
  ACTIVE: [
    CAMPAIGN_ACTIONS.HISTORY, CAMPAIGN_ACTIONS.DUPLICATE, CAMPAIGN_ACTIONS.ABORT
  ],
  DRAFT: [
    CAMPAIGN_ACTIONS.DELETE
  ],
  ENDED: [
    CAMPAIGN_ACTIONS.HISTORY, CAMPAIGN_ACTIONS.DELETE
  ],
  ABORTED: [CAMPAIGN_ACTIONS.HISTORY, CAMPAIGN_ACTIONS.DELETE],
  RESUMED: [CAMPAIGN_ACTIONS.HISTORY, CAMPAIGN_ACTIONS.DUPLICATE, CAMPAIGN_ACTIONS.ABORT],
  PAUSED: [CAMPAIGN_ACTIONS.HISTORY, CAMPAIGN_ACTIONS.DUPLICATE, CAMPAIGN_ACTIONS.ABORT],
}

export const SCROLL_DIRECTION = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
} as const;

export const MESSAGE_FLAT_LIST_CONST = {
  SCROLL_EVENT_THROTTLE: 1000 / 60,
  INITIAL_ITEMS_TO_RENDER: 6,
  MAX_ITEMS_TO_RENDER: 6,
  ON_END_REACHED_THRESHOLD: 0.1,
  ON_START_REACHED_THRESHOLD: 1,
} as const;

export const CALL_STATUS_LABELS = {
  ABANDONED_CALL: 'ABANDONED_CALL',
  VOICE_MAIL: 'VOICE_MAIL',
} as const;

export const MASKED_PHONE_NUMBER = `###-###-####`;
export const HEADER_ACTION_ITEM = [
  CONVERSATION_ACTION_CODES.START_SMS,
  CONVERSATION_ACTION_CODES.START_EMAIL,
  CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
  CONVERSATION_ACTION_CODES.CHART,
  CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT,
  CONVERSATION_ACTION_CODES.EDIT,
  CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
  CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT,
];
export const PATIENT_SEARCH_MODULE_CODE = {
  CONVERSATION_LIST: 'CONVERSATION_LIST',
}

export const DEFAULT_EMPLOYER_REPORTING_CODE = 'DEFAULT_EMPLOYER_REPORTING';
export const TASK_ATTACHMENT_SOURCE = {
  MEDIA: 'MEDIA',
};
export const SCREEN_CONTEXT = {
  APPOINTMENT_WIDGET:'APPOINTMENT_WIDGET',
};

export const FORM_CONTACT_PERMISSION_SUBJECT = {
  Form: 'FORM',
  FormLog: 'FORM_LOG',
};
export const EFAX_DRAWER_CODE ={
  UPDATE_INBOX: 'UPDATE_INBOX',
  ADD_INBOX: 'ADD_INBOX',
}

export const INSURANCE_NUMBER = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  TERTIARY: 'Tertiary'
};
export const MESSAGE_ACTION_CONST = {
  UPDATE_MESSAGE: 'UPDATE_MESSAGE'
};
export const APPOINTMENT_WIDGET_RESOURCES = [
  ANALYTICS_RESOURCE.EMPLOYEE_VS_DEPENDANT_VISITS,
  ANALYTICS_RESOURCE.APPOINTMENT_TYPE,
  ANALYTICS_RESOURCE.ENCOUNTER_TYPES,
  ANALYTICS_RESOURCE.APPOINTMENT_BY_DAY,
  ANALYTICS_RESOURCE.APPOINTMENT_BY_TIME,
  ANALYTICS_RESOURCE.APPOINTMENT_SELF_SCHEDULED
];


export const POWER_BI_REPORT_ERROR = {
  reportName: 'Please give report name',
};

export const TOLL_FREE_NUMBERS_PREFIX = [
  '800',
  '888',
  '877',
  '866',
  '855',
  '844',
  '833',

  '+1800',
  '+1888',
  '+1877',
  '+1866',
  '+1855',
  '+1844',
  '+1833',
] as const;


export const CONVERSATION_BUS_ACTION_CODE = {
  ASSIGNEE_CHANGED: 'ASSIGNEE_CHANGED',
  CONVERSATION_STATUS_CHANGED: 'CONVERSATION_STATUS_CHANGED',
  CONVERSATION_MSG_READ: 'CONVERSATION_MSG_READ',
};
export const ACCOUNTS_AND_ROLES_ACTION_CONST = {
  PRACTICE_NAME: 'PRACTICE_NAME',
  EHR_USER: 'EHR_USER',
  ROLES: 'ROLES',
  UPDATE_ALL_LOCATION_ROLES: 'UPDATE_ALL_LOCATION_ROLES',
  GLOBAL_USER_ROLES: 'GLOBAL_USER_ROLES',
  LOCATION_AND_ROLE: 'LOCATION_AND_ROLE'
};

export const INSURANCE_MEDIA_NUMBER = {
  PRIMARY_FRONT: '1-1',
  PRIMARY_BACK: '1-2',
  SECONDARY_FRONT: '2-1',
  SECONDARY_BACK: '2-2',
}
export const TEAM_USERS_TYPE = {
  INVITED_USERS: 'INVITED_USERS',
  ACCOUNT_USERS: 'ACCOUNT_USERS'
}
export const TEAM_USERS_TYPE_LIST= [
  {
    value: TEAM_USERS_TYPE.ACCOUNT_USERS,
    label: 'Current Users',
  },
  {
    value: TEAM_USERS_TYPE.INVITED_USERS,
    label: 'Invited Users',
  },
];

export const QUALITY_MEASURES_PATIENT_TYPE = {
  PATIENTS_QUALIFIED: 'PATIENTS_QUALIFIED',
  PATIENTS_UNQUALIFIED: 'PATIENTS_UNQUALIFIED'
}
export const SHOW_COMMENT_ERROR = 'showCommentError';
export const IS_SAVE_CLICK = 'isSaveClick';

export const IMAGING_REPORT = {
  DIAGNOSTIC_REPORT : "DiagnosticReport",
  IMAGING_RESULT: "IMAGINGRESULT",
  SYSTEM_ATHENA: "athena",
  IMAGING_REPORTS:"Imaging reports",
}

export enum SORT_BY{
  DATE= "date",
  NAME= 'name',
};

export enum SORT_ORDER {
  DESC= 'desc',
  ASC= 'asc',
};

export const ORDER ={
  LAST: 'last'
}

export const SELECTED_VIEW_TYPE ={
  ADD_ROLE_DRAWER: 'ADD_ROLE_DRAWER'
}
export const EXTERNAL_USER_FILTER_CODE = {
  LOCATION: 'LOCATION',
  ACCOUNT: 'ACCOUNT'
}

export const ACCESS_LEVEL ={
  LOCATION: 'LOCATION',
  GLOBAL: 'GLOBAL'
}

export const DASHBOARD_PERMISSION_ACTION: { [key: string]: string } = {
  HOME: 'HOME',
  CONSUMER: 'CONSUMER',
  CALENDAR: 'CALENDAR',
  TASKS: 'TASKS',
  INBOX: 'INBOX',
  CALL_LOGS: 'CALL_LOGS',
  LEAD: 'LEADS',
  CAMPAIGN_MANAGER: 'CAMPAIGN_MANAGER',
  ANALYTICS: 'ANALYTICS',
  SETTING: 'SETTING',
  PATIENT_DETAILS: 'PATIENT_DETAILS',
  SIGN_AMEND_EHR_NOTE: 'SIGN_AMEND_EHR_NOTE'
};

export const SETTING_PERMISSION_ACTION: { [key: string]: string } = {
  ADMIN_PATIENTS: 'ADMIN_PATIENTS',
  COMMUNICATION: 'COMMUNICATION',
  SCHEDULE: 'SCHEDULE',
  FORMS: 'FORMS',
  CONTENT_MANAGEMENT: 'CONTENT_MANAGEMENT',
  CARE_JOURNEY: 'CARE_JOURNEY',
  WEB_WIDGETS: 'WEB_WIDGETS',
  TASK_MANAGEMENT: 'TASK_MANAGEMENT',
  MEMBERSHIPS: 'MEMBERSHIPS',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  AUTOMATION: 'AUTOMATION',
  CALL: 'CALL',
  WEARABLES: 'WEARABLES'
};
export const QM_CONST = {
  PROGRAMS: 'programs'
}

export const TASK_TYPES = {
  CARE_GAPS: 'care_gaps'
}
export const SCORE_TYPE = {
  HCC_V_24: 'HCC_V_24',
  HCC_V_28: 'HCC_V_28',
  FOLD_SCORE: 'FOLD_SCORE',
}


export const AVAILABILITY_CONST = {
  VIRTUAL_LOCATION: "VIRTUAL_LOCATION"
}

export const CARE_GAP_CONDITION_TYPE = {
  SATISFIED: 'SATISFIED',
  UN_SATISFIED: 'UN_SATISFIED'
}

export const LAST_REDIRECT_EXPIRY_DATE_KEY = 'LAST_REDIRCET_DUE_TO_MEMORY_EXCEED';

export const LAST_REDIRECT_EXPIRY_DATE_KEY_NON_MEMORY =
  'LAST_REDIRCET_DUE_TO_MEMORY_EXCEED_NON_MEMORY';

export const MEMORY_THRESHOLD_LIMIT = 1.3;

export const MEMORY_THRESHOLD_TIMEOUT = 15;

export const MEMORY_CLEANUP_TIMEOUT = 180
