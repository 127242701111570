import {useLazyQuery, useQuery} from '@apollo/client';
import {
  Button as AntButton,
  Drawer,
  Modal,
  notification,
  Popover,
  Row,
  Typography,
  Space
} from 'antd';
import {} from 'antd';
import {
  Avatar,
  Button,
  Divider,
  HStack,
  IconButton,
  Image,
  Text as NativeText,
  Tooltip,
  useToast,
  View,
  VStack
} from 'native-base';
import { useToast as useCustomToast } from '../Toast/ToastProvider';
import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Dimensions, Pressable, useWindowDimensions} from 'react-native';
import {default as Icon} from 'react-native-vector-icons/AntDesign';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  BUTTON_TYPE,
  CLOUD_TELEPHONY_CODE,
  CONVERSATION_BUS_ACTION_CODE,
  CONVERSATION_PATH_LIST,
  CONVERSATION_STATUS,
  GROUP_MEMBER_TYPE,
  ONLINE_STATUS,
  PARENT_CODE,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE
} from '../../constants';
import {CONFIG_CODES, ON_OFF_CONFIG_VALUE} from '../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../constants/ActionConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../constants/Configs';
import {MLOV_CATEGORY, MLOV_CODES, USER_ROLE_CODES} from '../../constants/MlovConst';
import {
  CONVERSATION_LOCAL_EVENT_CODES,
  CUSTOM_MESSAGE_EVENT_CODES,
  SUPPORTED_EVENT_CODE,
  WEB_SOCKET_EVENT_CODE
} from '../../constants/WebSocketConst';
import {AuthContext} from '../../context/AuthContext';
import {CommonDataContext} from '../../context/CommonDataContext';
import {ILoginUserData} from '../../Interfaces';
import {LockScreen} from '../../screens/MainScreen/LockScreen';
import {LOCAL_STORAGE_CONST} from '../../screens/MainScreen/MainScreenConst';
import {UserQueries} from '../../services';
import BaseService from '../../services/CommonService/BaseService';
import {
  GetUsersTaskAccess,
  GetUsersTaskPoolAccess
} from '../../services/TaskPool/TaskPoolQueries';
import {GET_USER_FOR_SCHEDULE_ACCESS} from '../../services/User/UserQueries';
import {Colors} from '../../styles';
import '../../styles/CSS/CommonTableUiStyles.css';
import {
  getConsentDataInBulk,
  giveConsentInBulk
} from '../../utils/BulkConsentService';
import {
  filterWorkflowUser,
  getAccountId,
  getAccountUUID, getCurrentUserRole,
  getCurrentUserRoleCodes,
  getFormattedToNumber,
  getUserFullName,
  getUserUUID, isDisplayOutlookTokenExpiryAlert, isEmptyArray, isLoginUserBusinessOwner, isMasterAccount, navigateToOtherScreen
} from '../../utils/commonUtils';
import {showToast, ToastType} from '../../utils/commonViewUtils';
import {EventBus} from '../../utils/EventBus';
import LocalStorage from '../../utils/LocalStorage';
import {MessageBus} from '../../utils/MessageBus';
import {getContactTypeId, getMlovCodeIdObj, getMlovId} from '../../utils/mlovUtils';
import {AddNoteView} from '../common/AddNoteView';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import {AppointmentType} from '../common/CalendarWidget/BookingWorkflows/AppointmentTypeSelection/AppointmentTypeEnum';
import AppointmentBooking from '../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import BookingWorkflow from '../common/CalendarWidget/BookingWorkflows/BookingWorkflow';
import {ParticipantType} from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {IAllUserPoolSelect} from '../common/CareDashboard/CareDashboardWidget/UserAutoComplete';
import {capitalizeText} from '../common/ContactRelationView/ContactRelationUtils';
import {DisplayCardAvatar} from '../common/DisplayCard/DisplayCardAvatar';
import EmailDrawerCommon from '../common/EmailPopupView/EmailDrawerCommon';
import MeetingView from '../common/MeetingView/MeetingView';
import PhoneCalling from '../common/PhoneCalling/PhoneCalling';
import RecentItemsView from '../common/RecentItemsView/RecentItemsView';
import ResizablePip from '../common/ResizablePip/ResizablePip';
import ArroyLeft from '../common/Svg/SideMenu/ArrowLeft';
import TimerSvg from '../common/Svg/SideMenu/TimerSvg';
import {PERSON_ACTION_CODES} from '../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {AddOrUpdateLead} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import AddEditUser from '../RightSideContainer/Contacts/TeamMembers/AddEditUser/AddEditUser';
import {IUsersResponse} from '../RightSideContainer/Contacts/TeamMembers/interfaces';
import AddOrUpdateCampaign from '../RightSideContainer/ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import RightSideContainer from '../RightSideContainer/RightSideContainer';
import CreateSmsConversationDrawer from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {
  IContact,
  IConversationData
} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getDefaultPath} from '../SideMenuBar/SideBarHelper';
import SideMenuBar from '../SideMenuBar/SideMenuBar';
import {ICall, IConversationAssigneeBusAction, IThemeAttachments} from './interface';
import {styles} from './Styles';
import {GlobalWarningsContext} from './GlobalWarningContext/GlobalWarningsContext';
import {IGlobalWarnings, IMentionMessageCallBack} from '../../utils/interfaces';
import {Auth} from 'aws-amplify';
import {useIntl} from 'react-intl';
import {FoldButton} from '../CommonComponents/FoldButton/FoldButton';
import ShowMicroPhoneOffPopUp from '../common/PhoneCalling/MircroPhonePermission';
import {CommonWebSocket, initializeWebSocketConnection} from '../../utils/WebSocketUtils';
import { getCurrentSubdomain } from '../../screens/MainScreen/MainScreenHelper';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import { IEmailInboxTokenStatusChangedData } from '../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/interfaces';
import { getExpiredEmailInbox } from '../common/EmailDrawerCommonV2/EmailInboxApi';
import ExpiredInboxAlert from '../common/EmailDrawerCommonV2/ExpiredInboxAlert';
import ExpiredInboxDrawer from '../common/EmailDrawerCommonV2/ExpiredInboxDrawer';
import {localBroadcastEvent} from '../../utils/CustomEventHandler';
import {IMessageCreated} from '../../Interfaces/WebSocketEventInterfaces';
import {IContentAttributesAssigneeData} from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/Interfaces';
import {ConversationActionBus} from '../../utils/ConversationActionBus';
import {handleErrorMsgForAssign} from '../RightSideContainer/TeamInbox/Conversations/conversationUtils';
import {unAssignConversationAPI} from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationAPIService';
import {assignStatusValue} from '../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import { onConversationMsgRead } from './ConversationActionUtils';
import {updateConversationMentionsData } from './MessageActionUtils';
import { Device, Call } from '@twilio/voice-sdk';
import { createPortal } from 'react-dom';
import useMemoryMonitor from '../CustomHooks/useMemoryMonitor';
const {Text} = Typography;
const BodyContainer = () => {
  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  );
  const conversationNotificationKey = `open_conversation_notification`;
  const isRefreshConversationAfterOfflineOnlineEnabled = isAccountConfigEnabled(CONFIG_CODES.REFRESH_CONVERSATION_AFTER_OFFLINE_ONLINE);
  const intl = useIntl();
  const location = useLocation();
  const navigateToScreen = useNavigate();
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const userFullName = getUserFullName();
  const currentUserRolesList = getCurrentUserRoleCodes();
  const accountThemeConfig: any = commonData.accountThemeConfig || {};
  const accountData = userData.accounts && userData.accounts[0];
  const [menuCollaps, setMenuCollapse] = useState<boolean>(true);
  const [globalWarningOpen, setGlobalWarningOpen] = useState<IGlobalWarnings>({} as IGlobalWarnings);
  const axios = BaseService.getSharedInstance().axios;
  const userUuid = getUserUUID();
  const mlovData = useContext(CommonDataContext);
  // // const contactTypeUuid = getContactTypeId('CUSTOMER');
  const contactTypeUuid = getContactTypeId('CUSTOMER');
  const currentUserId = getUserUUID();
  const currentUserName = getUserFullName();
  const currentUserUUID = getUserUUID();
  const accountUuid = getAccountUUID();
  const [accountUsers, setAccountUsers] = useState<any[]>([]);
  const currentUserRoles = getCurrentUserRole();
  const isMasterAccountFlag = isMasterAccount()
  const isDisplayOutlookTokenExpiryAlertForAccount =
    isDisplayOutlookTokenExpiryAlert();
    const groupMemberTypeList = mlovData.MLOV[MLOV_CATEGORY.GroupMemberType];
    const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);

  const [inboxState, setInboxState] = React.useState({
    showExpiredAlert: false,
    isExpiredInboxDrawerOpen: false
  })
  const showScrollIndicator = isAccountConfigEnabled(CONFIG_CODES.SHOW_SCROLL_INDICATOR);

  const consentProviderTypeId = getMlovId(mlovData.MLOV, MLOV_CATEGORY.CONSENT_PROVIDER_TYPE, MLOV_CODES.CONTACT);
  const isBusinessOwner = isLoginUserBusinessOwner()

  const [lockScreen, setLockScreen] = useState(false)
  const [userEditData, setUserEditData] = useState({
    showUserEdit: false,
    userInfo: [] as any,
    name: userData.name,
    profileImage: userData.avatar_url.includes('404')
      ? ''
      : userData.avatar_url,
    email: userData.email,
  });

  const [userList, setUserList] = useState<
    Array<{
      id: string;
      name: string;
      uuid: string;
      email: string;
    }>
  >([]);
  const [userTaskDisplayCategory, setUserTaskDisplayCategory] = useState([]);
  const [userTaskPoolAccess, setUserTaskPoolAccess] = useState(false);
  const [userPoolSelect, setAllUserPoolSelect] = useState<IAllUserPoolSelect[]>(
    []
  );
  const [twilioWebDevice, setTwilioWebDevice] = useState<Device | undefined>(undefined);
  const loggedInUserId = getUserUUID();
  const accessUserTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );
  const {checkMemoryUsage} = useMemoryMonitor()

  const onCloseAddEditDrawer = () => {
    setUserEditData({...userEditData, showUserEdit: false});
  };

  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
    variables: {
      searchString: '%%',
      accountId: getAccountId(),
    },
  });
  const toast = useToast();
  const customToast = useCustomToast();
  const fetchUserData = async () => {
    const data = await getUsersData();
    setUserEditData({...userEditData, userInfo: data?.data?.users[1]});
  };

  const [recenetItemPopoverVisible, setRecenetItemPopoverVisible] =
    useState(false);

  const {height} = useWindowDimensions();
  const headerRef = useRef<any>(null);
  const footerRef = useRef<any>(null);
  const parentElemRef = useRef<any>(null);
  const menuElemRef = useRef<any>(null);
  const recentViewRef = useRef<any>(null);

  const updateProfileImage = (data: any) => {
    setUserEditData({
      ...userEditData,
      profileImage: data.profileImage,
      name: data.name,
    });
  };
  const messageBus = MessageBus.getMessageBusInstance();
  const conversationActionBus = ConversationActionBus.getConversationActionInstance();
  const onSendMessageEvent = async (data: any) => {
    await messageBus.sendMessage(data);
  };


  const onConversationActionEvent = async (data: any) => {
    conversationActionBus.onConversationAssigneeChanged(data);
  };

  const onConversationStatusChangedActionEvent = async (data: any) => {
    conversationActionBus.onConversationStatusChangedOnEvent(data);
  };

  const onConversationMsgReadEvent = async (data: any) => {
    data.abortSignal = abortControllerRef?.current?.signal;
    conversationActionBus.onConversationReadOnEvent(data)
  }


  const onConversationActionSuccessCallback = (eventData: {
    conversationActionCode: string,
    actionData: IConversationAssigneeBusAction
  }) => {
    const {conversationActionCode, actionData} = eventData;
    switch (conversationActionCode) {
      case CONVERSATION_BUS_ACTION_CODE.ASSIGNEE_CHANGED:
        onConversationAssigneeChanged(actionData);
        break;
      case CONVERSATION_BUS_ACTION_CODE.CONVERSATION_STATUS_CHANGED:
        onConversationStatusChanged(actionData);
        break;
      case CONVERSATION_BUS_ACTION_CODE.CONVERSATION_MSG_READ:
        onConversationMsgRead(actionData, userUuid);
        break;
    }
  };

  const onConversationActionErrorCallback = (eventData: {
    conversationActionCode: string,
    actionData: any,
    error: any
  }) => {
    const {conversationActionCode, error} = eventData;
    switch (conversationActionCode) {
      case CONVERSATION_BUS_ACTION_CODE.ASSIGNEE_CHANGED:{
        const errorMsg = handleErrorMsgForAssign(error?.response?.data);
        showToast(
          customToast,
          errorMsg?.length
            ? errorMsg
            : intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
          2000,
          true
        );
        break;
      }
      case CONVERSATION_BUS_ACTION_CODE.CONVERSATION_STATUS_CHANGED: {
          const errorMsg = error?.response?.data?.CONVERSATION_ALREADY_RESOLVED;
          showToast(
            customToast,
            errorMsg?.length
              ? errorMsg
              : intl.formatMessage({id: 'apiErrorMsg'}),
            ToastType.error,
            2000,
            true
          );
          break;
      }
    }
  };

  const onMessageSuccessCallback = async (data: IMentionMessageCallBack) => {
    updateConversationMentionsData({
      args: data,
      groupMemberCodeIdObj,
      accountUUID,
      userUuid,
    });
  };


  const onConversationAssigneeChanged = (actionData: IConversationAssigneeBusAction) => {
    const contentAttributesAssigneeData: IContentAttributesAssigneeData = actionData?.assignedUserRes?.messageData?.contentAttributes;
    if (actionData?.assignedUserRes?.conversationUuid) {
      const toastMessage = actionData?.conversationData?.status === CONVERSATION_STATUS.RESOLVED
          ? actionData?.assignedUserRes?.messageData?.content
          : intl.formatMessage({id: 'userAssignSuccessfully'});
      showToast(
        customToast,
        toastMessage,
        ToastType.success,
        1000,
        true
      );
      const conversationRespData = actionData?.assignedUserRes?.messageData?.conversation;
      if (conversationRespData?.status === CONVERSATION_STATUS.RESOLVED) {
        localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ARCHIVED, {
          conversation: actionData?.conversationData,
          messageData: actionData?.assignedUserRes?.messageData,
        });
      } else {
        const eventData = {
          conversation: actionData?.conversationData,
          assignUser: {
            assigneeId: contentAttributesAssigneeData?.assigneeToUserData?.id,
            assigneeName: name,
            previousAssigneeId: contentAttributesAssigneeData?.previousAssigneeData?.uuid,
            conversationUuid: actionData.conversationData?.uuid,
            displayId: actionData.conversationData?.displayId,
            conversationId: actionData.conversationData?.id,
            assignByUserId: contentAttributesAssigneeData?.assigneeByUserData?.id
          },
          messageData: actionData?.assignedUserRes?.messageData
        };
        localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNEE_CHANGED, eventData);
        localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNED, eventData);
      }
    }
  };

  const onConversationStatusChanged = async (actionData: IConversationAssigneeBusAction) => {
    if (actionData?.updateConversationStatus?.conversationData?.uuid) {
      const toastMessage = actionData?.updateConversationStatus?.conversationData?.status === CONVERSATION_STATUS.RESOLVED
          ? intl.formatMessage({id: 'archivedSuccessfully'})
          : intl.formatMessage({id: 'unarchivedSuccessfully'});
      showToast(
        customToast,
        toastMessage,
        ToastType.success,
        1000,
        true
      );

      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_STATUS_CHANGED_EVENT, {
        conversation: actionData?.conversationData,
        messageData: actionData?.updateConversationStatus?.messageData
      });
      if (actionData?.updateConversationStatus?.conversationData?.status === assignStatusValue.ARCHIVED) {
        localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ARCHIVED, {
          conversation: actionData?.conversationData,
          messageData: actionData?.updateConversationStatus?.messageData
        });
      } else if (actionData?.updateConversationStatus?.conversationData?.status === assignStatusValue.OPEN) {
        localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ARCHIVED, {
          conversation: actionData?.conversationData,
          messageData: actionData?.updateConversationStatus?.messageData
        });
      }
      const body = {
        conversationUuid: actionData?.conversationData?.uuid || '',
      };
      if(actionData?.conversationData?.assignedUser && actionData?.conversationData?.assignedUser?.uuid){
        const unAssignedResp = await unAssignConversationAPI({
          body,
        });
        if (unAssignedResp?.data?.messageData) {
          localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ASSIGNED, {
            conversation: actionData?.conversationData,
            messageData: unAssignedResp?.data?.messageData
          });
        }
      }
    }
  };



  const showSupportBtn = () => {
    const $supportButton: any = document?.querySelector('#jsd-widget')
    if (location.pathname == '/download-report') {
      if ($supportButton) {
        $supportButton.style.display = 'none'
      }
    } else {
      if ($supportButton) {
        $supportButton.style.display = 'block'
      }
    }
  }
  const closeConversationNotification = () => {
    if (!CONVERSATION_PATH_LIST.includes(location.pathname)) {
      notification.close(conversationNotificationKey);
    }
  };
  const openNotification = () => {
    if (notification) {
      notification.close(conversationNotificationKey);
    }
    const btn = (
      <Space>
        <AntButton type="primary" size="small" onClick={() => {
          notification.close(conversationNotificationKey);
          const eventBus = EventBus.getEventBusInstance();
          eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.REFRESH_CONVERSATION_ON_USER_ONLINE, {
            onlineStatus: ONLINE_STATUS.ONLINE
          })
          checkMemoryUsage()
        }}>
          Refresh
        </AntButton>
      </Space>
    );
    notification.info({
      message: 'Conversation Refresh',
      description: `Internet instability has been detected. It's possible that new messages have arrived in your inbox but haven't been displayed yet. To view any potential unread messages, kindly click the refresh button to reload the messages screen.`,
      placement: 'topRight',
      duration: 0,
      btn: btn,
      key: conversationNotificationKey,
      style: {
        width: 600,
      },
    });
  };
  const onUserOnlineStatusChanged = (eventData: {
    onlineStatus: string;
  }) => {
    if (eventData?.onlineStatus === ONLINE_STATUS.ONLINE) {
      initializeWebSocketConnection(userData);
      if (CONVERSATION_PATH_LIST.includes(location.pathname)) {
        openNotification();
      }
    } else if (eventData?.onlineStatus === ONLINE_STATUS.OFFLINE) {
      CommonWebSocket.closeWebSocket();
    }

  };
  useEffect(() => {
    showSupportBtn();
    closeConversationNotification();
    const eventBus = EventBus.getEventBusInstance();
    eventBus.removeEventListener(updateProfileImage);
    eventBus.removeEventListener(onSendMessageEvent);
    eventBus.removeEventListener(onConversationActionEvent);
    eventBus.removeEventListener(onConversationStatusChangedActionEvent);
    eventBus.removeEventListener(onConversationMsgReadEvent)

    conversationActionBus.removeCallback({
      callback: onConversationActionSuccessCallback,
      errorCallback: onConversationActionErrorCallback
    });
    messageBus.removeCallback({
      onMentionMessageCallBack: onMessageSuccessCallback,
    });

    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.PROFILE_URL_UPDATE,
      updateProfileImage
    );
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.SEND_MESSAGE,
      onSendMessageEvent
    );
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNEE_ACTION_BUS,
      onConversationActionEvent
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_STATUS_CHANGED_ACTION_BUS,
      onConversationStatusChangedActionEvent
    );

    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_MSG_READ_ACTION_BUS,
      onConversationMsgReadEvent
    )

    conversationActionBus.registerCallbacks({
      callback: onConversationActionSuccessCallback,
      errorCallback: onConversationActionErrorCallback
    });

    messageBus.registerCallbacks({
      onMentionMessageCallBack: onMessageSuccessCallback
    });

    if (isRefreshConversationAfterOfflineOnlineEnabled) {
      eventBus.addEventListener(
        CUSTOM_MESSAGE_EVENT_CODES.USER_ONLINE_STATUS,
        onUserOnlineStatusChanged
      );
    }


    if (
      headerRef.current &&
      footerRef.current &&
      parentElemRef.current &&
      menuElemRef.current &&
      recentViewRef.current
    ) {
      const recentViewHeight =
        parentElemRef.current.offsetHeight -
        (headerRef.current.offsetHeight +
          menuElemRef.current.offsetHeight +
          footerRef.current.offsetHeight);

      recentViewRef.current.style.height = `${recentViewHeight}px`;
    }
    fetchUserData();
    setUserEditData({
      ...userEditData,
      profileImage: userData.avatar_url.includes('404')
        ? ''
        : userData.avatar_url,
    });
    return () => {
      eventBus.removeEventListener(updateProfileImage);
      eventBus.removeEventListener(onSendMessageEvent);
      eventBus.removeEventListener(onConversationActionEvent);
      eventBus.removeEventListener(onConversationStatusChangedActionEvent);
      eventBus.removeEventListener(onConversationMsgReadEvent)

      if (isRefreshConversationAfterOfflineOnlineEnabled) {
        eventBus.removeEventListener(onUserOnlineStatusChanged);
      }
      conversationActionBus.removeCallback({
        callback: onConversationActionSuccessCallback,
        errorCallback: onConversationActionErrorCallback
      });
      messageBus.removeCallback({
        onMentionMessageCallBack: onMessageSuccessCallback,
      });
    };
  }, [menuCollaps, location]);

  useEffect(() => {
  }, []);

  /**
   * Get all Account users for login user
   */
  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const accountUsers: any[] = [];
      data.users = filterWorkflowUser(data.users, loggedInUserId);
      if (data && data?.users && data?.users.length) {
        (data.users || []).forEach((user: any) => {
          accountUsers.push({
            userName: user.name,
            userId: user.uuid,
            email: user.email || '',
          });
        });
      }
      setAccountUsers(accountUsers);
    },
    onError: (error: any) => {

    },
  });

  /**
   * Login user pool task access (Which users he can manage)
   */
  const getUserTaskPoolAccess = useQuery(GetUsersTaskPoolAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    //skip: accountUserQueryLoading,
    variables: {
      // userId: accountUsers.map(
      //   (accountUserItem: any) => accountUserItem.userId
      // ),
      userId: [loggedInUserId],
      userPoolTypeId: accessUserTypeId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      // fetching all account users amd filtering login user data
      const filterUserPoolAccessData: any = data.userPoolAccesses?.filter(
        (dataItem: any) => dataItem.userId === loggedInUserId
      );

      if (filterUserPoolAccessData && filterUserPoolAccessData.length) {
        // logged In user have the other pool user access
        const userPoolAccessUsersData =
          filterUserPoolAccessData[0]?.user_pool?.userPoolUsers;
        const loggedInUser = accountUsers.filter(
          (accountItem: any) => accountItem.userId === loggedInUserId
        );
        if (loggedInUser && loggedInUser.length) {
          userPoolAccessUsersData.unshift(loggedInUser[0]);
        }

        // flag to manage dropdown whether there are multiple users to handle or not
        if (userPoolAccessUsersData && userPoolAccessUsersData.length > 1) {
          setUserTaskPoolAccess(true);
        } else {
          setUserTaskPoolAccess(false);
        }
        //userDataPostProcessing(userPoolAccessUsersData);
        fetchAllTaskPoolAccessUserPool(userPoolAccessUsersData);
      } else {
        // logged In user do not have the other pool user access
        setUserTaskPoolAccess(false);
        fetchAllTaskPoolAccessUserPool([
          {
            id: '',
            userId: loggedInUserId,
            isDeleted: false,
          },
        ]);
      }
    },
    onError: (error: any) => {

    },
  });

  /**
   * Login user's manageable users's task pool (For Add task task pool dropdown)
   */
  const [getAllTaskAccessUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const accessUserTaskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );

  const fetchAllTaskPoolAccessUserPool = async (users: any[]) => {
    try {
      const userIds = users.map((userItem: any) => userItem?.userId);
      const taskPoolData = await getAllTaskAccessUserTaskPools({
        variables: {
          userId: userIds,
          userPoolTypeId: accessUserTaskPoolTypeId,
        },
      });
      const newAllUserPool: IAllUserPoolSelect[] = [];
      taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
        const userPoolId = userPoolItem?.userPoolId;
        const userPoolName = userPoolItem?.userPool?.name;
        const userPoolUsers = userPoolItem?.userPool?.userPoolUsers;

        const findIndex = newAllUserPool.findIndex(
          (userPoolItem: IAllUserPoolSelect) =>
            userPoolName === userPoolItem.userPoolName
        );
        if (findIndex < 0) {
          newAllUserPool.push({
            userPoolId,
            userPoolName,
            userPoolUserIds: userPoolUsers.map(
              (userPoolUserItem: any) => userPoolUserItem.userId
            ),
          });
        }
      });
      setAllUserPoolSelect(newAllUserPool);
    } catch (error) {

    } finally {
    }
  };

  /**
   * getUsers Query
   */
  const [getUsers] = useLazyQuery(UserQueries.GET_USERS_FOR_CALENDAR, {
    variables: {
      accountId: accountUuid,
    },
  });

  const fetchSystemUsers = async () => {
    const getUsersResult = await getUsers();
    if (getUsersResult.data?.users) setUserList(getUsersResult.data.users);
    const userTaskDisplayCategory = await getUsersTaskDisplayCategory();

    if (getUsersResult.data?.users) {
      setUserList(getUsersResult.data.users);
      setUserTaskDisplayCategory(
        userTaskDisplayCategory.data.userTaskDisplayCategories || []
      );
    }
  };

  /**
   * getUsers Task Display Category Query
   */
  const [getUsersTaskDisplayCategory] = useLazyQuery(
    UserQueries.GET_USER_TASK_DISPLAY_CATEGORY,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      variables: {
        userId: currentUserId,
      },
    }
  );


  const ssoSignOut = () => {
    Auth.signOut({global: true});
  };

  const logout = () => {
    return axios.post('crm-nest/logout');
  }

  const userSignOut = (): void => {
    twilioWebDevice?.destroy();
    logout().then((res) => {
      const ssoConfig =
        commonData.accountConfigData?.SSO_CONFIG?.accountConfigurations;
        const isCsUser = currentUserRolesList.includes('CUSTOMER_SUCCESS');

      if (ssoConfig && ssoConfig.length > 0) {
        const ssoValue = ssoConfig[0].value;
        const ssoValueData = JSON.parse(ssoValue);
        const redirectSignOutUrl = window.location.host.includes('localhost')
          ? 'http://localhost:3000'
          : ssoValueData.Auth.oauth.redirectSignOut;
        const domain = ssoValueData.Auth.oauth.domain;
        const clientId = ssoValueData.Auth.userPoolWebClientId;
        const providerId = JSON.parse(ssoValue).provider;
        const ssoCognitoLogoutUrl = `https://${domain}/logout?response_type=code&client_id=${clientId}&redirect_uri=${redirectSignOutUrl}&logout_uri=${redirectSignOutUrl}&scope=openid+profile+email&identity_provider=${providerId}`;
        window.location.href = ssoCognitoLogoutUrl;
        ssoSignOut();
        LocalStorage.removeItem('loginDone');
        LocalStorage.removeItem('isWorkflowLogin');
        LocalStorage.removeItem('consent_Obj');
        LocalStorage.removeItem('user');
        //window.location.reload();
      } else if(isCsUser){
          const ssoValue = commonData.accountConfigData?.WORKFLOW_SSO_CONFIG?.defaultValue;
          const ssoValueData = JSON.parse(ssoValue!);
          const domain = ssoValueData.Auth.oauth.domain;
          const clientId = ssoValueData.Auth.userPoolWebClientId;
          const providerId = ssoValueData.provider;

          const redirectSignOutUrl = window.location.host.includes('localhost')
          ? 'http://localhost:3000/cs'
          : `https://${getCurrentSubdomain()}/cs`;


          const ssoCognitoLogoutUrl = `https://${domain}/logout?response_type=code&client_id=${clientId}&identity_provider=${providerId}&redirect_uri=${redirectSignOutUrl}&logout_uri=${redirectSignOutUrl}`;
          const ssoAzureLogoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(ssoCognitoLogoutUrl)}`
          window.location.href = ssoAzureLogoutUrl;

          LocalStorage.removeItem('loginDone');
          LocalStorage.removeItem('isWorkflowLogin');
          LocalStorage.removeItem('consent_Obj');
          LocalStorage.removeItem('user');
      } else {
        navigateToScreen('/login', {state: {logout: true}});
      }
    });
  };

  const getLogoElem = (): JSX.Element => {
    let logoElem = <></>;
    const tempThemeAdditionalData: string =
      accountThemeConfig.additionalAttributes || '{}';

    const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
      tempThemeAdditionalData
    );
    if (finalThemeAdditionalAttributes?.attachment?.logo?.isLogoWithName) {
      logoElem = (
        <Pressable
          onPress={() => {
            navigateToScreen(getDefaultPath(userUuid, '/'), {replace: true});
          }}
        >
          <View
            justifyContent={'flex-start'}
            style={{
              flexDirection: 'row',
              marginLeft: menuCollaps ? 0 : 15,
            }}
          >
            <View style={styles.logoView}>
              <Image
                style={styles.logoImg}
                resizeMode="contain"
                source={{
                  uri: finalThemeAdditionalAttributes.attachment.logo.url,
                  cache: 'force-cache',
                }}
                alt='image'
              />
            </View>
            {/* {!menuCollaps ? (
              <Text color={'#fff'} style={styles.headerText}>
                {accountData.name}
              </Text>
            ) : null} */}
          </View>
        </Pressable>
      );
    } else if (finalThemeAdditionalAttributes?.attachment?.logo?.url) {
      logoElem = (
        <Pressable
          onPress={() => {
            navigateToScreen(getDefaultPath(userUuid, '/'), {replace: true});
          }}
        >
          <VStack
            style={
              !menuCollaps ? styles.headerStyle : styles.headerStyleCollapse
            }
          >
            <View style={styles.logoView}>
              {menuCollaps ? (
                <Avatar
                  size="sm"
                  source={require('../../assets/images/Abstract.png')}
                ></Avatar>
              ) : (
                <Image
                  width={'120px'}
                  height={'40px'}
                  resizeMode={'stretch'}
                  // style={styles.logoImg}
                  source={{
                    uri: finalThemeAdditionalAttributes.attachment.logo.url,
                    cache: 'force-cache',
                  }}
                  alt='image'
                />
              )}
            </View>
          </VStack>
        </Pressable>
      );
    } else {
      logoElem = (
        <Pressable
          onPress={() => {
            navigateToScreen(getDefaultPath(userUuid, '/'), {replace: true});
          }}
        >
          {/* <View style={styles.logoView}>
            <Image
              style={styles.logoImg}
              source={require('../../assets/images/Abstract.png')}
            />
          </View> */}

          <NativeText color={'#fff'} style={styles.headerText}>
            {accountData.name}
          </NativeText>
        </Pressable>
      );
    }
    return logoElem;
  };
  const handleVisibleChange = (e: boolean) => {
    setRecenetItemPopoverVisible(e);
  };
  const getSetUptoken = async () => {
    return axios.get(
      `/cloud-telephony/phone-numbers/token?userUUID=${userUuid}`
    );
  };

  const activeCallList = useRef([] as ICall[]);

  const containerRef = useRef(document.createElement('div'));
  containerRef.current.style.position = 'fixed';
  containerRef.current.style.zIndex = '1010';

  // this usestate is only used to update the UI related to calls to rerender the active call list
  const [updateUIToggle, setCallUpdateUIToggle] = useState(false);
  const [actionViewState, setActionViewState] = useState({
    selectedActionView: '',
    selectedRowData: {} as any,
    contactData: {} as IContact,
    formattedContactData: {},
  });
  const [openModal, setOpenModal] = useState({
    type: '',
    data: null as any,
  });
  
  const phoneSetup = () => {
    getSetUptoken()
      .then((response) => {
        const deviceOptions: Device.Options = {
          allowIncomingWhileBusy: true,
          codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
          closeProtection: true,
        };
  
        if (!twilioWebDevice) {
          const twilioDevice = new Device(response?.data?.token, deviceOptions);
          setTwilioWebDevice(twilioDevice);
  
          twilioDevice.on('registered', () => {});
          twilioDevice.on('incoming', onIncomingCallReceived);
          twilioDevice.on('cancel', onCallCanceled);
          twilioDevice.on('connect', onIncomingCallConnected);
          twilioDevice.on('disconnect', onCallDisconnected);
          twilioDevice.on('error', (error) => {});
          twilioDevice.on('offline', () => {
            activeCallList.current = [];
            setCallUpdateUIToggle((prev) => !prev);
          });

          twilioDevice.register()
        } else {
          twilioWebDevice.updateToken(response?.data?.token);
        }
      })
      .catch((error) => {});
  };
  
  const [showMicrophonePopup, setShowMicrophonePopup] = useState(false);
  
  useEffect(() => {
    phoneSetup();
  }, []);

  const onIncomingCallReceived = (connection: any) => {
    let isConference = false;
    if (connection?.customParameters?.get("conference")) {
        isConference = true;
    }

    if (activeCallList.current.length) {
      activeCallList.current.push({call: connection, isConference: isConference, isIncoming: true});
    } else {
      activeCallList.current = [{call: connection, isConference: isConference, isIncoming: true}];
    }

    connection?.on('cancel', () => {
      removeCall(connection);
    });

    setCallUpdateUIToggle(prev => !prev)
  }

  const removeCall = (call: Call) => {
    activeCallList.current = activeCallList?.current?.filter(
      (callItem) => callItem?.call !== call
    );
    setCallUpdateUIToggle((prev) => !prev);
  }

  const onIncomingCallConnected = (connection: any) => {
    updateCallConnection(connection);
  };

  const onCallCanceled = (connection: any) => {
    disconnectCall(connection);
  };

  const onCallDisconnected = (connection: any) => {
    disconnectCall(connection);
  };


  const updateCallConnection = (connection: any) => {
    const updateCallList = activeCallList.current;
    const index = activeCallList.current.findIndex(
      (callConnection) =>
        callConnection?.call?.parameters?.CallSid === connection?.parameters?.CallSid
    );
    if (index > -1) {
      const updateCallConnection = activeCallList.current[index];
      updateCallConnection.isConnected = true;
      updateCallList[index] = updateCallConnection;
    }
    activeCallList.current = updateCallList;
    setCallUpdateUIToggle(prev => !prev)
  }

  const disconnectCall = (connection: any) => {
    const updateCallList = activeCallList.current;
    const index = activeCallList.current.findIndex(
      (callConnection) =>
        callConnection?.call?.parameters?.CallSid === connection?.parameters?.CallSid
    );
    if (index > -1) {
      const updateCallConnection = activeCallList.current[index];
      updateCallConnection.isDisconnected = true;
      updateCallList[index] = updateCallConnection;
      activeCallList.current = updateCallList;
      setCallUpdateUIToggle(prev => !prev)
      updateCallList.splice(index, 1);
    }
    activeCallList.current = updateCallList;
    setCallUpdateUIToggle(prev => !prev);
  }

  const isAnotherCallInProgress = (connection: any) => {
    const index = activeCallList.current.findIndex(
      (callConnection) =>
        callConnection?.call?.parameters?.CallSid !== connection?.parameters?.CallSid
    );
    if (index > -1) {
      const anotherCall = activeCallList.current[index];
      return anotherCall.isConnected || false;
    }
    return false;
  }

  const MINUTE_MS = 900000;

  const onViewChangeActionPerformed = (
    actionCode: string,
    rowData?: any
  ): any => {
    let finalActionCode =
      actionCode || RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW;

    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW;
        break;
      case COMMON_ACTION_CODES.MAIL:
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP;
        break;
      case COMMON_ACTION_CODES.CALL:
        const formattedContactData = getFormDataFromLeadData(
          rowData || {},
          commonData
        );
        setActionViewState((prev) => {
          return {
            ...prev,
            formattedContactData: formattedContactData,
          };
        });
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW;
        break;
      case COMMON_ACTION_CODES.SMS:
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER;
        break;
      case COMMON_ACTION_CODES.ADDED_OR_UPDATED:
        // getContactData();
        break;
      case PERSON_ACTION_CODES.CREATE_NOTES:
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE;
        break;
      case PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT:
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_SCHEDULE_POPUP;
        break;
      default:
        finalActionCode = RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW;
        break;
    }
    setActionViewState((prev) => {
      return {
        ...prev,
        selectedRowData: rowData,
        selectedActionView: finalActionCode,
      };
    });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      phoneSetup();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  const accountUUID = getAccountUUID();
  const checkMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({audio: true});
      return false
    } catch (error) {
      setShowMicrophonePopup(true);
      return true
    }
  }

  const callOutbound = async (data: any) => {
    const isMicroPhoneOff = await checkMicrophonePermission();
    if (isMicroPhoneOff) {
      return
    }

    if (activeCallList?.current?.length > 0) {
      customToast({
        message: intl.formatMessage({id: 'alreadyOnAnotherCall'}),
        toastType: ToastType.error,
        duration: 3000,
        closeAllPrevToast: true,
      });
      return;
    }

    const {contactData, fromNumber, toNumber} = data;
    if (twilioWebDevice) {
      const formattedToNumber = getFormattedToNumber(toNumber);
      const params = {
        params: {
          To: `${toNumber ? formattedToNumber : `+1${contactData.phoneNumber}`}`,
          From: `${fromNumber}`,
          accountUUID: accountUUID,
          userUUID: userUuid,
          contactData: JSON.stringify(contactData)
        }
      };
      try {
        const connection = await twilioWebDevice?.connect(params);

        if (connection) {
          activeCallList.current = [{call: connection}];

          connection?.on('accept', () => {
            activeCallList.current = activeCallList.current.map((item)=>{
              if(item?.call == connection) {
                return {
                  ...item,
                  isConnected: true
                }
              }
              return item;
            })
            setCallUpdateUIToggle((prev) => !prev);
          });

          connection?.on('error', (error: any) => {});

          connection?.on('disconnect', () => {
            removeCall(connection);
          });

          connection?.on('cancel', () => {
            removeCall(connection);
          });

          setCallUpdateUIToggle((prev) => !prev);
        }
      } catch (error) {}
    }
  };

  // AUTHOR:: ABHAY CHAUDHARY

  // BULK CONSENT ::START
  const getConsentCountData = async () => {
    const response = await getConsentDataInBulk();
    const key = `open${Date.now()}`;
    const btn = (
      <Row>
        <AntButton
          size="small"
          onClick={() => {
            onUpdateClick(key);
          }}
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 3,
            paddingBottom: 3,
            height: 40,
            marginRight: 12,
          }}
        >
          <Text style={{paddingLeft: 5, paddingRight: 5}}>
            Yes, we have consent for all{' '}
          </Text>
        </AntButton>
        <AntButton
          size="small"
          onClick={() => {
            onCancelClick(key);
          }}
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 3,
            paddingBottom: 3,
            height: 40,
            marginRight: 12,
          }}
        >
          <Text style={{paddingLeft: 5, paddingRight: 5}}>No, not now</Text>
        </AntButton>
        <AntButton
          size="small"
          onClick={() => {
            onDontShowAgainClick(key);
          }}
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 3,
            paddingBottom: 3,
            height: 40,
            marginRight: 12,
          }}
        >
          <Text style={{paddingLeft: 5, paddingRight: 5}}>
            Don’t show again
          </Text>
        </AntButton>
      </Row>
    );
    if (response?.data?.count > 30) {
      const message = `There are more than ${response?.data?.count} patients whose consent for communication is pending. Would you like to give consent now ?`;
      setTimeout(() => {
        notification.info({
          message,
          placement: 'bottomRight',
          duration: 0,
          btn: btn,
          key: key,
          style: {
            width: 600,
          },
        });
      }, 3 * 1000);
    }
  };

  const isContactConsentRequired = () => {
    const configs = commonData?.accountConfigData?.[CONFIG_CODES.CONSENT_REQUIRED]
    const result = configs?.accountConfigurations?.find(
      (item) => item.value == ON_OFF_CONFIG_VALUE.ON
    );
    return !!result
  };

  useEffect(() => {
    // AUTHOR:: ABHAY CHAUDHARY
    // BULK CONSENT ::START
    const isConsentRequired = isContactConsentRequired();
    if (isBusinessOwner && isConsentRequired) {
      LocalStorage.getItem('consentCheckObj').then((res) => {
        const consentCheckObj = JSON.parse(res);
        if (!consentCheckObj) {
          getConsentCountData();
        }
      });
    }
    // BULK CONSENT ::END
  }, []);

  const destroyCallDevice = () => {
    twilioWebDevice?.removeAllListeners?.();
    twilioWebDevice?.destroy?.();
  }

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.removeEventListener(callOutbound);
    eventBus.addEventListener('CLOUD_CALL', callOutbound);
    eventBus.addEventListener('CLOUD_CALL_DESTROY', destroyCallDevice);

    return () => {
      eventBus.removeEventListener(callOutbound);
      eventBus.removeEventListener(destroyCallDevice);
    }
  }, [twilioWebDevice]);

  const setPopoverDisableInGlobalAction = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent('POPOVER', {
      visiblePopover: false,
    });
  };

  const callShortKey = (data: any) => {
    const {type} = data;
    if (type === 'appointment') {
      setOpenModal({
        type: COMMON_ACTION_CODES.ADD_SCHEDULE,
        data: null,
      });
    } else if (type === 'member') {
      setOpenModal({
        type: COMMON_ACTION_CODES.ADD_MANUALLY,
        data: null,
      });
    } else if (type === 'campaign') {
      //navigateToOtherScreen(navigateToScreen, '/campaignManagement/create');
      setOpenModal({
        type: COMMON_ACTION_CODES.CREATE_CAMPAIGN,
        data: null,
      });
    } else if (type === 'automation') {
      navigateToOtherScreen(
        navigateToScreen,
        '/#/admin/patients/automation/create?currentTab=TRIGGER&flowType=PATIENTS'
      );
    } else if (type === 'task') {
      setOpenModal({
        type: COMMON_ACTION_CODES.ADD_TASK,
        data: null,
      });
    }
    setPopoverDisableInGlobalAction();
  };


  const switchLockScreen = () => {
    const currentScreen: any = {
      lockScreen: !lockScreen
    }
    localStorage.setItem(LOCAL_STORAGE_CONST?.lockScreen, JSON.stringify(currentScreen));
    setLockScreen(!lockScreen);
    if (notification) {
      notification.close(conversationNotificationKey);
    }
  }

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener('SHORTKEYS', callShortKey);
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.LOCK_SCREEN,
      switchLockScreen
    );
    return () => {
      eventBus.removeEventListener(switchLockScreen);
      eventBus.removeEventListener(callShortKey);
    }
  }, []);

  const onDontShowAgainClick = (key: any) => {
    const consentCheckObj = {
      isDontShowAgain: true,
    };
    LocalStorage.setItem(
      'consentCheckObj',
      JSON.stringify(consentCheckObj)
    ).then(() => {
      notification.close(key);
    });
  };
  const onCancelClick = (key: any) => {
    notification.close(key);
  };
  const onUpdateClick = async (key: any) => {

    const response = await giveConsentInBulk(
      userUuid,
      consentProviderTypeId
    );
    if (response.data) {
      notification.close(key);
    } else {
      notification.close(key);
    }
  };

  const checkIfInboxExpired = async () => {
    try {
      if (!isDisplayOutlookTokenExpiryAlertForAccount) {
        return;
      }
      const expiredInboxes = await getExpiredEmailInbox();
      if (isEmptyArray(expiredInboxes)) {
        setInboxState((prev) => {
          return {
            ...prev,
            showExpiredAlert: false,
          };
        });
        return;
      }
      setInboxState((prev) => {
        return {
          ...prev,
          showExpiredAlert: true,
        };
      });
    } catch (error) {
      setInboxState((prev) => {
        return {
          ...prev,
          showExpiredAlert: false,
        };
      });
    }
  };

  const onEmailInboxTokenStatusChangeEvent = (
    data: IEmailInboxTokenStatusChangedData
  ) => {
    if (!isDisplayOutlookTokenExpiryAlertForAccount) {
      return;
    }
    if (data && data.isTokenExpired && data.inboxData?.id) {
      setInboxState((prev) => {
        return {
          ...prev,
          showExpiredAlert: true,
        };
      });
    } else {
      setInboxState((prev) => {
        return {
          ...prev,
          showExpiredAlert: false,
        };
      });
    }
  };

  const handleAcceptCall = (connection : Call, callItem : ICall) => {
    connection?.accept();
    connection?.on('disconnect', () => {
      disconnectCall(connection);
    });
    connection?.on('error', (error) => {});
    activeCallList.current = activeCallList.current.map((item)=>{
      if (item == callItem) {
        return {
          ...callItem,
          isConnected: true
        }
      } else if (item?.isConnected) {
        item?.call?.disconnect?.();
        return {
          ...callItem,
          isConnected: false,
          isDisconnected: true
        }
      }
      return item;
    })
    setCallUpdateUIToggle((prev) => !prev);
  }

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.EMAIL_INBOX_TOKEN_EXPIRY_STATUS,
      onEmailInboxTokenStatusChangeEvent
    );
    return () => {
      eventBus.removeEventListener(onEmailInboxTokenStatusChangeEvent);
    };
  }, [inboxState]);

  useEffect(() => {
    checkIfInboxExpired();
    document.body.appendChild(containerRef.current);
    return () => {
      try {
        document.body.removeChild(containerRef.current);
      } catch {}
    }
  }, []);

  const memoizedPhoneCalling = React.useCallback((callItem: ICall) => {
    const call = callItem?.call;
    const contactData = JSON.parse(call?.customParameters?.get("contactData") || "{}");
    
    return (
      <PhoneCalling
        key={call?.parameters?.CallSid}
        phoneSetup={phoneSetup}
        contactData={contactData as any}
        callObject={call}
        onCall={callItem?.isConnected ?? false}
        direction={call?.direction}
        isConference={callItem?.isConference ?? false}
        isConnected={callItem?.isConnected ?? false}
        isDisconnected={callItem?.isDisconnected ?? false}
        onLockScreen={lockScreen}
        isAnotherCallInProgress={isAnotherCallInProgress(call)}
        onActionPerformed={(actionCode: string, rowData: any) => {
          if (actionCode === COMMON_ACTION_CODES.ACCEPT_CALL) {
            const connection = rowData as Call;
            handleAcceptCall(connection, callItem);
            return;
          } else if (actionCode === COMMON_ACTION_CODES.CALL_REJECT) {
            disconnectCall(call);
            return;
          }
          onViewChangeActionPerformed(actionCode, rowData);
        }}
      />
    );
  }, []);

  const removeDuplicateCalls = (calls: ICall[]): ICall[] => {
    const uniqueCalls: ICall[] = [];
    const callSidSet = new Set<string>();

    for (const call of calls) {
      const callSid = call?.call?.parameters?.CallSid;
      if (!callSidSet.has(callSid)) {
        uniqueCalls.push(call);
        callSidSet.add(callSid);
      }
    }

    return uniqueCalls;
  };
  
  const renderedCalls = React.useMemo(() => {
    activeCallList.current = removeDuplicateCalls(activeCallList.current);
    return activeCallList.current.map((callItem: ICall) => {
      const call = callItem?.call;

      return (
        <ResizablePip
          key={call?.parameters?.CallSid + "_RPIP"}
          minConstraints={[350, 300]}
          maxConstraints={[400, 400]}
          boxPosition={
            callItem?.isIncoming && !callItem?.isConnected
              ? {left: '40%', top: '30%'}
              : {right: '1%', bottom: '1%'}
          }
        >
          {memoizedPhoneCalling(callItem)}
        </ResizablePip>
      );
    });
  }, [activeCallList?.current, memoizedPhoneCalling, activeCallList?.current?.length]);

  return (
    <>
      {lockScreen ? (
        <View flex={1}>
          <LockScreen userData={userData} switchLockScreen={switchLockScreen} />
        </View>
      ) : (
        <Fragment>
          <HStack flex={1}>
            {!location.pathname.includes('/login') && (
              <VStack
                width={10}
                marginRight={-1.5}
                style={[
                  !menuCollaps && false
                    ? {minWidth: 250, overflow: 'scroll', height}
                    : {width: 85, height: height},
                ]}
                display={location.pathname == '/download-report' ? 'none' : ''}
                borderRightColor={Colors.Custom.BorderColor}
                borderRightWidth={1}
                justifyContent={'space-between'}
                ref={parentElemRef}
                backgroundColor={Colors.primary['500']}
              >
                <VStack>
                  <View
                    ref={headerRef}
                    style={{
                      flexDirection: menuCollaps ? 'column' : 'row',
                      alignItems: 'center',
                      justifyContent: menuCollaps ? 'center' : undefined,
                      paddingHorizontal: menuCollaps ? 16 : 27,
                      // paddingBottom: 8,
                    }}
                  >
                    {false && (
                      <Pressable
                        onPress={() => {
                          setMenuCollapse(!menuCollaps);
                        }}
                      >
                        <View
                          style={{
                            width: 24,
                            height: 24,
                            marginBottom: menuCollaps ? 10 : 0,
                          }}
                        >
                          <ArroyLeft />
                        </View>
                      </Pressable>
                    )}
                    {/* {true && getLogoElem()} */}

                    {!menuCollaps && (
                      <View
                        width={'100%'}
                        alignItems={'flex-start'}
                        style={{marginLeft: 1, flexDirection: 'column'}}
                      >
                        <Pressable
                          onPress={() => {
                            navigateToScreen(getDefaultPath(userUuid, '/'), {
                              replace: true,
                            });
                          }}
                        >
                          <NativeText color={'#fff'} style={styles.headerText}>
                            {capitalizeText(accountData.name)}
                          </NativeText>
                        </Pressable>
                      </View>
                    )}
                  </View>
                  <View
                    ref={menuElemRef}
                    // overflow={'scroll'}
                    style={{maxHeight: height - 100, overflow: 'scroll'}}
                  >
                    <SideMenuBar
                      menuCollaps={menuCollaps}
                      onSideBarMenuChange={(menuCode) => {
                      }}
                      onGlobalWarningOpen={(menuData: IGlobalWarnings) => {
                        setGlobalWarningOpen(menuData);
                      }}
                    />
                  </View>
                </VStack>
                <VStack overflow={'hidden'} style={{}}>
                  <View
                    // ref={footerRef}
                    style={{
                      overflow: 'scroll',
                    }}
                  >
                    {
                      <View
                        ref={footerRef}
                        style={{
                          justifyContent: 'flex-end',
                          overflow: 'scroll',
                          paddingHorizontal: 16,
                        }}
                      >
                        {!menuCollaps && !isMasterAccountFlag && (
                          <View>
                            <RecentItemsView />
                          </View>
                        )}
                        {menuCollaps && !isMasterAccountFlag && (
                          <View>
                            <Divider
                              marginBottom={5}
                              style={{width: '100%'}}
                              bg={Colors.Custom.Gray200}
                            />
                            <Popover
                              overlayInnerStyle={{
                                bottom: 3,
                                borderRadius: 16,
                                padding: 0,
                              }}
                              content={<RecentItemsView isPopover={true} />}
                              trigger="click"
                              placement="right"
                              visible={recenetItemPopoverVisible}
                              onVisibleChange={handleVisibleChange}
                              overlayStyle={{borderRadius: 8}}
                            >
                              <Button
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginBottom: 20,
                                  // paddingHorizontal: 10,
                                  paddingVertical: 5,
                                  backgroundColor: Colors.primary['400'],
                                }}
                                onPress={() => {
                                  setRecenetItemPopoverVisible(true);
                                }}
                              >
                                <View style={{width: 20, height: 20}}>
                                  <TimerSvg />
                                </View>
                              </Button>
                            </Popover>
                          </View>
                        )}
                      </View>
                    }
                    {/* WILE REMOVE THIS ONCE NEW UI FINALIZE|| AUTHOR :: ABHAY */}
                    {false && (
                      <VStack
                        pl={6}
                        justifyContent={'center'}
                        height={16}
                        borderTopWidth={1}
                        borderColor={'#4D2D84'}
                      >
                        <Pressable
                          onPress={() => {
                            setMenuCollapse(!menuCollaps);
                          }}
                        >
                          {menuCollaps ? (
                            <View
                              style={{
                                width: 24,
                                height: 24,
                                marginBottom: menuCollaps ? 10 : 0,
                                transform: [{rotate: '180deg'}],
                              }}
                            >
                              <ArroyLeft />
                            </View>
                          ) : (
                            <View
                              style={{
                                width: 24,
                                height: 24,
                                marginBottom: menuCollaps ? 10 : 0,
                              }}
                            >
                              <ArroyLeft />
                            </View>
                          )}
                        </Pressable>
                      </VStack>
                    )}
                    {false && (
                      <View ref={footerRef}>
                        <HStack
                          alignSelf={menuCollaps ? 'center' : ''}
                          flex={1}
                          style={styles.footerHStack}
                        >
                          <VStack flex={!menuCollaps ? 0.3 : ''}>
                            <Pressable
                              onPress={() =>
                                setUserEditData({
                                  ...userEditData,
                                  showUserEdit: true,
                                })
                              }
                            >
                              <DisplayCardAvatar
                                avatarStyle={{
                                  avatarSize: '12',
                                }}
                                isLetterAvatarShow={true}
                                userData={{
                                  userId: userData.id,
                                  userType: GROUP_MEMBER_TYPE.USER,
                                  imgSrc: userEditData?.profileImage || '',
                                  roles: currentUserRolesList,
                                  userName: userData.name,
                                }}
                              />
                            </Pressable>
                          </VStack>
                          {!menuCollaps ? (
                            <>
                              <VStack flex={0.7}>
                                <Pressable
                                  onPress={() =>
                                    setUserEditData({
                                      ...userEditData,
                                      showUserEdit: true,
                                    })
                                  }
                                >
                                  <NativeText>{userEditData.name}</NativeText>
                                  <NativeText fontSize={10} color={'gray.500'}>
                                    {userEditData.email}
                                  </NativeText>
                                </Pressable>
                              </VStack>
                              <View style={styles.footerBtn}>
                                <Tooltip label="Sign Out" placement="top">
                                  <IconButton
                                    onPress={userSignOut}
                                    icon={
                                      <Icon
                                        name="logout"
                                        color={Colors.Custom.Gray600}
                                        size={15}
                                      />
                                    }
                                  />
                                </Tooltip>
                              </View>
                            </>
                          ) : null}
                          {userEditData.showUserEdit && (
                            <AddEditUser
                              roleIsDisable
                              onClose={(actionCode) => {
                                if (actionCode === 'COMPLETE') {
                                  onCloseAddEditDrawer();
                                  return;
                                }
                                onCloseAddEditDrawer();
                              }}
                              user={{
                                id: userData.id,
                                email: userEditData.email,
                                name: userEditData.name,
                                avatar_url: userEditData.profileImage,
                                uuid: userData.uuid,
                              }}
                              parentCode={PARENT_CODE.SIDE_MENU_PANEL}
                            />
                          )}
                        </HStack>
                      </View>
                    )}
                  </View>
                </VStack>
              </VStack>
            )}
            {false && (
              <>
                <VStack
                  zIndex={1000}
                  width={0}
                  height={10}
                  position={'relative'}
                  right={3}
                  top={'90vh'}
                >
                  <Pressable
                    style={{marginTop: 40}}
                    onPress={() => setMenuCollapse(!menuCollaps)}
                  >
                    <Avatar size="xs" bgColor={Colors.primary['500']}>
                      <Icon
                        size={14}
                        color={'white'}
                        name={menuCollaps ? 'doubleright' : 'doubleleft'}
                      />
                    </Avatar>
                  </Pressable>
                </VStack>
              </>
            )}
            <VStack
              flex={1}
              justifyContent={'start'}
              style={styles.rightSideContainer}
            >
              <GlobalWarningsContext.Provider
                value={{
                  globalWarningData: globalWarningOpen,
                }}
              >
                <RightSideContainer showScrollIndicator={showScrollIndicator}/>
              </GlobalWarningsContext.Provider>
            </VStack>
            {actionViewState.selectedActionView ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
              <AddOrUpdateLead
                singleLeadData={actionViewState.selectedRowData}
                onFormCompleteAction={(actionCode: string) => {
                  onViewChangeActionPerformed(actionCode);
                }}
                personType={
                  actionViewState.selectedRowData?.contactType?.contactType
                    ?.code
                }
                personTypeUuid={
                  actionViewState.selectedRowData?.contactType?.contactType?.id
                }
              />
            )}
            {actionViewState.selectedActionView ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
              <EmailDrawerCommon
                onMailCompleteAction={onViewChangeActionPerformed}
                contactData={actionViewState?.selectedRowData}
                // contactData={{
                //   uuid: contactInfo?.uuid,
                //   name: contactInfo?.name,
                //   email: contactInfo?.email,
                //   accountId: contactInfo?.accountId,
                // }}
              />
            )}
            {actionViewState.selectedActionView ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
              <CreateSmsConversationDrawer
                isDrawerVisible={true}
                selectedInbox={{} as any}
                onCreateSmsConversationActionPerformed={(
                  actionCode: string,
                  actionData: any
                ) => {
                  setActionViewState((prev) => {
                    return {
                      ...prev,
                      selectedActionView: '',
                    };
                  });
                  if (
                    actionData?.selectedInboxConversationData
                      ?.conversations?.[0]?.id
                  ) {
                    // navigateToScreen('/inbox/sms', {replace: true});
                  }
                }}
                selectedPatient={actionViewState?.selectedRowData}
              />
            )}
            {Object.keys(actionViewState.formattedContactData).length > 0 &&
              actionViewState.selectedActionView ===
                RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
                <MeetingView
                  personData={actionViewState.formattedContactData}
                  selectedConversation={{} as IConversationData}
                  onPersonActionPerformed={onViewChangeActionPerformed}
                />
              )}

            {actionViewState.selectedActionView ===
              RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE && (
              <AddNoteView
                selectedData={{}}
                contactId={actionViewState.selectedRowData?.id}
                onFormActionPerformed={(actionCode: any) => {
                  onViewChangeActionPerformed(actionCode);
                }}
              />
            )}
            {actionViewState.selectedActionView ===
              RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_SCHEDULE_POPUP && (
              <BookingWorkflow
                user={{uuid: userUuid, name: userFullName}}
                isOpen={
                  actionViewState.selectedActionView ===
                  RIGHT_SIDE_CONTAINER_CODE.APPOINTMENT_SCHEDULE_POPUP
                    ? true
                    : false
                }
                appointmentType={AppointmentType.bookAppointment}
                defaultParticipants={[
                  {
                    label: actionViewState.selectedRowData?.name,
                    key: actionViewState.selectedRowData?.name,
                    value: actionViewState.selectedRowData?.name,
                    type: ParticipantType.patient,
                  },
                ]}
                onClose={() => {
                  onViewChangeActionPerformed(COMMON_ACTION_CODES.CANCEL);
                }}
                onCancel={() => {
                  onViewChangeActionPerformed(COMMON_ACTION_CODES.CANCEL);
                }}
              />
            )}

            {openModal.type === COMMON_ACTION_CODES.ADD_SCHEDULE && (
              <AppointmentBooking
                isVisible={true}
                onComplete={() => {
                  setOpenModal({
                    type: '',
                    data: null,
                  });
                }}
                onCancel={() => {
                  setOpenModal({
                    type: '',
                    data: null,
                  });
                }}
              />
            )}

            {openModal.type === COMMON_ACTION_CODES.ADD_MANUALLY && (
              <AddOrUpdateLead
                singleLeadData={undefined}
                onFormCompleteAction={(actionCode: string) => {
                  setOpenModal({
                    type: '',
                    data: null,
                  });
                }}
                personTypeUuid={contactTypeUuid}
                personType={PERSON_TYPES.CUSTOMER}
              />
            )}

            <Drawer
              visible={
                openModal.type === COMMON_ACTION_CODES.CREATE_CAMPAIGN
                  ? true
                  : false
              }
              width={Dimensions.get('window').width * 0.7}
              onClose={() => {
                setOpenModal({
                  type: '',
                  data: null,
                });
              }}
              headerStyle={{
                display: 'none',
              }}
            />

            {openModal.type === COMMON_ACTION_CODES.ADD_MANUALLY && (
              <AddOrUpdateLead
                singleLeadData={undefined}
                onFormCompleteAction={(actionCode: string) => {
                  setOpenModal({
                    type: '',
                    data: null,
                  });
                }}
                personTypeUuid={contactTypeUuid}
                personType={PERSON_TYPES.CUSTOMER}
              />
            )}

            {openModal.type === COMMON_ACTION_CODES.ADD_TASK && (
              <AddOrUpdateTask
                isVisible={true}
                assignee={{
                  value: currentUserUUID,
                  label: getUserFullName(),
                  key: currentUserUUID,
                  type: ParticipantType.staff,
                  details: userData,
                }}
                onComplete={(data) => {
                  setOpenModal({
                    type: '',
                    data: null,
                  });
                }}
                onCancel={() => {
                  setOpenModal({
                    type: '',
                    data: null,
                  });
                }}
              />
            )}

            <Drawer
              visible={
                openModal.type === COMMON_ACTION_CODES.CREATE_CAMPAIGN
                  ? true
                  : false
              }
              width={Dimensions.get('window').width * 0.7}
              onClose={() => {
                setOpenModal({
                  type: '',
                  data: null,
                });
              }}
              headerStyle={{
                display: 'none',
              }}
            >
              {openModal.type === COMMON_ACTION_CODES.CREATE_CAMPAIGN && (
                <AddOrUpdateCampaign
                  shouldShowEdit={true}
                  initData={undefined}
                  close={(status) => {
                    if (status != 'cancel') {
                      showToast(
                        toast,
                        status == 'created'
                          ? 'Campaign created successfully.'
                          : 'Campaign updated successfully.',
                        ToastType.success
                      );
                    }

                    setOpenModal({
                      type: '',
                      data: null,
                    });
                  }}
                />
              )}
            </Drawer>
          </HStack>
        </Fragment>
      )}
      {showMicrophonePopup && (
        <ShowMicroPhoneOffPopUp
          onActionPerformed={() => {
            setShowMicrophonePopup(false);
          }}
        />
      )}

      {inboxState.showExpiredAlert ? (
        <ExpiredInboxAlert
          onClose={() => {
            setInboxState((prev) => {
              return {
                ...prev,
                showExpiredAlert: false,
              };
            });
          }}
          onConfirm={() => {
            setInboxState((prev) => {
              return {
                ...prev,
                showExpiredAlert: false,
                isExpiredInboxDrawerOpen: true,
              };
            });
          }}
          isOpen={inboxState.showExpiredAlert}
        />
      ) : (
        <></>
      )}

      {inboxState.isExpiredInboxDrawerOpen ? (
        <ExpiredInboxDrawer
          isOpen={inboxState.isExpiredInboxDrawerOpen}
          onClose={() => {
            setInboxState((prev) => {
              return {
                ...prev,
                isExpiredInboxDrawerOpen: false,
              };
            });
          }}
        />
      ) : (
        <></>
      )}
      {createPortal(
        activeCallList?.current?.length ? renderedCalls : <></>,
        containerRef.current 
      )}
    </>
  );
};

export default BodyContainer;
