import {IMlov} from '../../../../Interfaces/CommonInterfaces';
import {getContactFiltersObject, getSearchContactsFilterObject} from '../../../RightSideContainer/Contacts/ContactsUtils';
import { TAB_KEYS } from '../../../RightSideContainer/constants';
import {
  ALL_PATIENT_FILTER,
  PROGRAM_DEFAULT_FILTERS,
  DEFAULT_STEPS_CODES,
  PROGRAM_STEPS_DEFAULT_FILTERS,
  PROGRAM_STEPS_FILTER_CODE,
  PROGRAM_FILTER_CODE,
  PATIENT_FILTERS_INFO,
} from '../constant';
import {IDashboardProgramFilter, IGetContactFilterParams, IPatientCategoryCount} from '../interface';

export const getContactFilter = (params: IGetContactFilterParams) => {
  const {filter, tabCode, commonContextData, accountId, careProgramStepTypeIds, careProgramTypeIds, careProgramStepStatusIds, ruleCodes, showContactsWithPendingAlerts, customFilterCode, carePlanStatusIds, isCarePlanNotAssigned} = params;
  const contactFilter: any = {
    _or: [],
    _and: [],
    contactTagging: {},
    contactInboxes: {},
  };
  return getSearchContactsFilterObject({
    filter: filter ? contactFilter : '',
    searchString: (filter || {})?.searchText || '',
    tagItem: (filter || {})?.tagItem,
    inboxItem: (filter || {})?.inboxItem,
    contactTypeId: (filter || {})?.personTypeUuid,
    selectedUserPracticeLocations: (filter || {})?.selectedUserPracticeLocation,
    status: (filter || {})?.status,
    formData: (filter || {})?.formData,
    employerId: '',
    mlovData: commonContextData,
    tab: tabCode,
    loggedInStatus: (filter || {})?.loggedInStatus,
    careProgramStepTypeIds: careProgramStepTypeIds || [],
    careProgramTypeIds: careProgramTypeIds || [],
    careProgramStepStatusIds: careProgramStepStatusIds || [],
    ruleCodes: ruleCodes || [],
    showContactsWithPendingAlerts: showContactsWithPendingAlerts || false,
    customFilterCode: customFilterCode || '',
    ...((carePlanStatusIds || [])?.length && {
      carePlanStatusIds: carePlanStatusIds
    }),
    ...(isCarePlanNotAssigned != undefined && {
      isCarePlanNotAssigned: isCarePlanNotAssigned
    })
  });
};

export const filterContactsStripeId = (contacts: any[]) => {
  const contactsStripeId = (contacts || []).map((contact: any) => {
    return contact?.contactAdditionalInfos?.stripeCustomerId;
  });
  return contactsStripeId;
};

const getFiltersToExclude = (tabCode: string) => {
  switch (tabCode) {
    case TAB_KEYS.PATIENT:
      return [PROGRAM_STEPS_FILTER_CODE.CREATE_PLAN]
    default:
      return []
  }
}

export const getAwvFilteredResponse=(popGroupAwvDueResponse:any, contactUuidsToRemove:any)=>{
  popGroupAwvDueResponse.data.result.forEach((result: any) => {
    result.ruleResult.contactUuids = result.ruleResult.contactUuids.filter((uuid: string) => !contactUuidsToRemove.includes(uuid));
    result.ruleResult.contacts = result.ruleResult.contacts.filter((contact: any) => !contactUuidsToRemove.includes(contact.contactUuid));
  });
  return popGroupAwvDueResponse
}

export const getAwvFilteredContactIds = (contactUuids:any, contactUuidsToRemove:any)=>{
  return contactUuids?.filter((uuid: string) => !contactUuidsToRemove.includes(uuid));
}

const filterNonRequiredStepFilter = (stepsFilter: IMlov[], tabCode: string): string[] => {
  const filterCategoryCodes: string[] = [];
  (stepsFilter || []).forEach(step => {
    if (DEFAULT_STEPS_CODES.includes(step?.code)) {
      filterCategoryCodes.push(step?.code);
    }
  });
  return filterCategoryCodes;
}

const getFilterInSequence = (filterCategoryCodes: string[], selectedCategoryCode?: string) => {
  let filter: IDashboardProgramFilter[] = [];
  const defaultFilters = (PROGRAM_STEPS_DEFAULT_FILTERS || []).filter(categoryFilter => {
    return filterCategoryCodes.includes(categoryFilter.categoryCode);
  }) || [];
  filter = filter.concat(defaultFilters, PROGRAM_DEFAULT_FILTERS);
  const subFilters = PROGRAM_DEFAULT_FILTERS.find(item => item.code === selectedCategoryCode)
  if (subFilters?.subFilters) {
    filter = filter.concat(subFilters.subFilters)
  }
  filter.unshift(ALL_PATIENT_FILTER)

  filter = filter.sort((filterSequence1: IDashboardProgramFilter, filterSequence2: IDashboardProgramFilter) => {
     if (filterSequence1?.sequence === filterSequence2?.sequence) {
        return 0;
      }
      if (filterSequence1?.sequence > filterSequence2?.sequence) {
        return 1;
      }
      return -1;
  });
  return filter;
}

const setFilterTabCount = (filter: IDashboardProgramFilter[], dashboardTabCount: IPatientCategoryCount[], tabCode: string) => {
  for(const tabFilter of dashboardTabCount) {
    if (tabFilter?.tabCode === tabCode) {
      (tabFilter?.categoryWiseCount || []).forEach(categoryWiseFilter => {
        const findFilter = (filter || []).find(categoryFilter => {
          return categoryFilter?.categoryCode == categoryWiseFilter?.categoryCode;
        });
        if (findFilter?.categoryCode) {
          findFilter.count = categoryWiseFilter?.count;
          return true;
        }
      });
    }
  }
}

export const getDashboardProgramFilter = (
  tabCode: string,
  stepsFilter: IMlov[],
  dashboardTabCount: IPatientCategoryCount[],
  selectedCategoryCode: string
): IDashboardProgramFilter[] => {
  const filterCodes: string[] = filterNonRequiredStepFilter(stepsFilter, tabCode);
  const filter: IDashboardProgramFilter[] = getFilterInSequence(filterCodes, selectedCategoryCode);
  setFilterTabCount(filter, dashboardTabCount, tabCode);
  return filter;
};


export const getDashboardProgramFilterCategoryCode = (
  tabCode: string,
  stepsFilter: IMlov[],
  selectedCategoryCode: string
): string[] => {
  const filterCodes: string[] = filterNonRequiredStepFilter(stepsFilter, tabCode);
  const filter: IDashboardProgramFilter[] = getFilterInSequence(filterCodes, selectedCategoryCode);
  return (filter || []).map(categoryFilter => categoryFilter.categoryCode)
};
