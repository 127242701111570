import {Table} from 'antd';
import {View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import TableWrapper from '../../TableWrapper/TableWrapper';
import {getTableViewColumns} from '../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/Helper/LeadListViewColumns';
import {IContactListViewProp} from '../interface';
import {CarePlanTopBarView} from '../CarePlanTopBarView';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../../../constants/ActionConst';
import {
  onMultipleSelectedItem,
  removeDuplicates,
} from '../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {MLOV_CATEGORY, TASK_ALERT_CATEGORY_CODES} from '../../../../constants/MlovConst';
import {
  IS_MINICONTACT_VIEW_ON_LEADS,
  RECENT_ITEM_TYPES,
} from '../../../../constants/StringConst';
import {getMlovId, getMlovIdFromCode, getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {
  getAccountId,
  getUserUUID,
} from '../../../../utils/commonUtils';
import {useLazyQuery, useMutation} from '@apollo/client';
import RecentItemsQueries from '../../../../services/RecentItems/RecentItemsQueries';
import MessagingContactDetailsDrawer from '../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import { IDashboardColumnProps } from '../../../../services/ContactCareProgram/interface';
import { LeadQueries } from '../../../../services';

export const ContactListTableView = (props: IContactListViewProp) => {
  const {
    filterCodes,
    onChangeCategoryCode,
    customerDataList,
    selectedCategoryCode,
    pageNumber,
    pageSize,
    onPageChange,
    totalRecords,
    tableLoader,
    tabCode,
    handleFoldScoreSort,
    handleHccV24Sort,
    handleHccV28Sort,
    sortOrderBy,
  } = props;

  const commonData = useContext(CommonDataContext);
  const recentPatientType = getMlovId(
    commonData.MLOV,
    MLOV_CATEGORY.RECENT_ITEM_TYPES,
    RECENT_ITEM_TYPES.PATIENTS,
  );
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const [createRecentpatient] = useMutation(
    RecentItemsQueries.CREATE_RECENT_ITEM,
  );

  const {height} = Dimensions.get('window');
  const finalHeight = height - 296;

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [stateData, setStateData] = useState({
    searchText: '',
    searchedColumn: '',
    filteredInfo: null,
    sortedInfo: null,
    isLoading: true,
    leadList: [],
    currentPage: pageNumber,
    offSet: pageNumber,
    pageSize: pageSize,
    sortD: '',
    order_by: {
      updatedAt: 'desc',
    },
    selectedItems: [] as any,
    selectedRowKey: [] as any,
    customerDataList: [],
    loading: false,
    contactCount: null,
    hideConvertToPatient: false,
  });

  useEffect(() => {
    setStateData({
      ...stateData,
      selectedItems: [],
      selectedRowKey: [],
    });
    onActionPerformed(COMMON_ACTION_CODES.ON_BULK_ACTION, []);
  }, [props.clearAllEvent]);

  const onCallAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.CALL, rowData);
  };
  const onSmsAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.SMS, rowData);
  };
  const onChatAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.CHAT, rowData);
  };
  const onMailAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.MAIL, rowData);
  };
  const onMergeContactAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.MERGE, rowData);
  };
  const onAddRelationAction = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.ADD_RELATION, rowData);
  };
  const onConvertTo = (rowData: any, actionCode: any) => {
    props?.onActionPerformed(actionCode, rowData);
  };
  const onSetTag = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.SET_TAG, rowData);
  };

  const onDeactivate = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.DEACTIVATE, rowData);
  };

  const onEditLead = (rowData: any) => {
    props?.onActionPerformed(COMMON_ACTION_CODES.EDIT, rowData);
  };

  const onActionPerformed = (actionCode: string, rowData: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.EDIT:
        onEditLead(rowData);
        break;
      case COMMON_ACTION_CODES.CALL:
        onCallAction(rowData);
        break;
      case COMMON_ACTION_CODES.SMS:
        onSmsAction(rowData);
        break;
      case COMMON_ACTION_CODES.CHAT:
        onChatAction(rowData);
        break;
      case COMMON_ACTION_CODES.MAIL:
        onMailAction(rowData);
        break;
      case COMMON_ACTION_CODES.MERGE:
        onMergeContactAction(rowData);
        break;
      case COMMON_ACTION_CODES.ADD_RELATION:
        onAddRelationAction(rowData);
        break;
      case COMMON_ACTION_CODES.CONVERT_TO_CUSTOMER:
        onConvertTo(rowData, actionCode);
        break;
      case COMMON_ACTION_CODES.SET_TAG:
        onSetTag(rowData);
        break;
      case COMMON_ACTION_CODES.ADD_NOTE:
        props?.onActionPerformed(COMMON_ACTION_CODES.ADD_NOTE, rowData);
        break;
      case COMMON_ACTION_CODES.RESET_PASSWORD:
        props?.onActionPerformed(COMMON_ACTION_CODES.RESET_PASSWORD, rowData);
        break;
      case COMMON_ACTION_CODES.SEND_FORMS:
        props?.onActionPerformed(COMMON_ACTION_CODES.SEND_FORMS, rowData);
        break;
      case COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.PRINT_CLINICAL_PROFILE,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.CREATE_TASK:
        props?.onActionPerformed(COMMON_ACTION_CODES.CREATE_TASK, rowData);
        break;
      case COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_AWV_VISIT:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_AWV_VISIT,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.SEND_MEMBERSHIP_LINK:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.SEND_MEMBERSHIP_LINK,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.CREATE_AUTOMATION:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.CREATE_AUTOMATION,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.ON_BULK_ACTION:
        props?.onActionPerformed(COMMON_ACTION_CODES.ON_BULK_ACTION, rowData);
        break;
      case COMMON_ACTION_CODES.DEACTIVATE:
        onDeactivate(rowData);
        break;
      case 'RowClick':
        onRowClicked(rowData);
        break;
      case COMMON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL:
        props?.onActionPerformed(
          COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL,
          rowData,
        );
        break;
      case COMMON_ACTION_CODES.ECM_OUTREACH_VIEW:
        props?.onActionPerformed(COMMON_ACTION_CODES.ECM_OUTREACH_VIEW, rowData);
        break;
      case COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT:
        props?.onActionPerformed(COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT, rowData);
        break;
      case COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT:
        props?.onActionPerformed(COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT, rowData);
        break;
      case COMMON_ACTION_CODES.ACTIONABLE_TASK_VIEW:
        props?.onActionPerformed(COMMON_ACTION_CODES.ACTIONABLE_TASK_VIEW, rowData);
        break;
      case COMMON_ACTION_CODES.ACTIONABLE_ALERT_VIEW:
        props?.onActionPerformed(COMMON_ACTION_CODES.ACTIONABLE_ALERT_VIEW, rowData);
        break;
      case COMMON_ACTION_CODES.CARE_PLAN_GOALS_VIEW:
        props?.onActionPerformed(COMMON_ACTION_CODES.CARE_PLAN_GOALS_VIEW, rowData);
        break;
    }
  };

  const sortFoldScore = (order?: string) => {
    handleFoldScoreSort(order);
  };

  const sortHccV24 = (order?: string) => {
    handleHccV24Sort(order);
  };

  const sortHccV28 = (order?: string) => {
    handleHccV28Sort(order);
  };

  const tableColumnParams: IDashboardColumnProps = {
    onActionPerformed: onActionPerformed,
    tabCode: tabCode,
    isCareProgramEnabled: true,
    filterCode: selectedCategoryCode,
    selectedItemsCount: stateData?.selectedItems?.length || 0,
    sortOrderBy: sortOrderBy,
    sortFoldScore: sortFoldScore,
    sortHccV24: sortHccV24,
    sortHccV28: sortHccV28
    
  };

  const handleAddRecentPateint = async (contactUUID: string) => {
    await createRecentpatient({
      variables: {
        accountId: accountId,
        itemUUID: contactUUID,
        recentItemTypeUUID: recentPatientType,
        userUuid: userUuid,
      },
    });
  };

  const onRowClicked = (rowData: any) => {
    try {
      const contactUUID = rowData?.singleLeadData?.uuid;
      if (IS_MINICONTACT_VIEW_ON_LEADS) {
        setSelectedRow(rowData);
      } else {
        props?.navigateOrOpenContactIdDrawer?.(rowData.id);
      }
      handleAddRecentPateint(contactUUID);
    } catch (error) {
    }
  };

  const taskAlertDisplayCategoryMlovs = getMlovListFromCategory(
      commonData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
    ) || [];
  const alertDisplayCategoryId = getMlovIdFromCode(
    taskAlertDisplayCategoryMlovs,
    TASK_ALERT_CATEGORY_CODES.PENDING
  )

  return (
    <>
      {selectedRow && (
        <MessagingContactDetailsDrawer
          contactData={selectedRow?.contactData}
          isDrawerVisible={selectedRow ? true : false}
          contactType={selectedRow?.contactType}
          onSideBarActionPerformed={(action?: any, actionData?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setSelectedRow(null);
              return;
            } else if (action == COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
              props?.onActionPerformed(action, actionData);
              setSelectedRow(null);
              return;
            }
            props?.onActionPerformed(action, actionData);
          }}
          borderLessView={true}
        />
      )}
      <View
        style={{
          backgroundColor: '#fff',
          overflow: 'hidden',
        }}>
        <CarePlanTopBarView
          filterCodes={filterCodes}
          onChangeCategoryCode={onChangeCategoryCode}
          selectedCategoryCode={selectedCategoryCode}
          filterLoading={tableLoader}
        />
        <TableWrapper
          pagination={{
            pageSize: pageSize,
            total: totalRecords,
            current: pageNumber,
            onChange: onPageChange,
          }}>
          <Table
            rowClassName={(record, index) =>
              index % 2 == 0 ? 'table-row-light' : ''
            }
            rowKey={record => {
              return record.id;
            }}
            loading={tableLoader}
            scroll={{x: 1200, y: finalHeight}}
            className="lead-list-table-view patient-list-view"
            columns={getTableViewColumns(tableColumnParams, alertDisplayCategoryId)}
            dataSource={customerDataList}
            onChange={() => {
              //
            }}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: stateData?.selectedRowKey,
              onSelect: (item: any) => {
                onMultipleSelectedItem(
                  item,
                  stateData.selectedItems,
                  (returnItems: any) => {
                    const selectedKey: any = [];
                    returnItems.forEach((element: any) => {
                      selectedKey.push(element.id);
                    });
                    onActionPerformed(
                      COMMON_ACTION_CODES.ON_BULK_ACTION,
                      returnItems,
                    );
                    setStateData(prev => {
                      return {
                        ...prev,
                        selectedItems: returnItems,
                        selectedRowKey: selectedKey,
                      };
                    });
                  },
                );
              },
              onSelectAll: (isSelected: boolean, data: any) => {
                if (!isSelected) {
                  const arrayKey = [...stateData?.selectedRowKey];
                  const arrayItems = [...stateData?.selectedItems];
                  customerDataList?.map((item?: any) => {
                    arrayKey.map((itemId?: any) => {
                      if (item?.id == itemId) {
                        const index = arrayKey.indexOf(itemId);
                        arrayKey.splice(index, 1);
                        setStateData({
                          ...stateData,
                          //selectedItems: [],
                          selectedRowKey: arrayKey,
                        });
                      }
                    });
                    arrayItems.map((itemId?: any) => {
                      if (item?.id == itemId?.id) {
                        const index = arrayItems.indexOf(itemId);
                        arrayItems.splice(index, 1);
                        setStateData({
                          ...stateData,
                          selectedItems: arrayItems,
                          selectedRowKey: arrayKey,
                        });
                      }
                    });
                  });
                  onActionPerformed(
                    COMMON_ACTION_CODES.ON_BULK_ACTION,
                    arrayItems,
                  );
                } else {
                  const arrayItems = [...stateData?.selectedItems];
                  customerDataList?.forEach((item?: any) => {
                    arrayItems.push(item);
                  });
                  //Remove duplicate from Arraylist
                  const uniqueArray = removeDuplicates(arrayItems, 'id');
                  const selectedKey: any = [];
                  uniqueArray.forEach((element: any) => {
                    selectedKey.push(element.id);
                  });

                  setStateData({
                    ...stateData,
                    selectedItems: uniqueArray,
                    selectedRowKey: selectedKey,
                  });
                  onActionPerformed(
                    COMMON_ACTION_CODES.ON_BULK_ACTION,
                    uniqueArray,
                  );
                }
              },
            }}
            onRow={(_record: any) => {
              return {
                onClick: () => {
                  onRowClicked(_record);
                },
              };
            }}
            pagination={false}
          />
        </TableWrapper>
      </View>
    </>
  );
};
