import {Divider} from 'native-base';
import {DisplayText} from '../common/DisplayText/DisplayText';
import Stack from '../common/LayoutComponents/Stack';
import { FoldButton } from '../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../constants';
import { useIntl } from 'react-intl';
import SidecarCreateAction from './SidecarCreateAction';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import { TaskPanelType } from '../TaskCard/TaskEnum';

interface SidecarTitleProps {
  paddingHorizontal?: number;
  paddingVertical?: number;
  onClose: () => void;
  titleId: string;
  actionButtonId?: string;
  actionButtonPress?: () => void;
  showCreateNewButton?: boolean;
  panelType?: TaskPanelType;
  patientUuid?: string;
}

export const SidecarTitle = (props: SidecarTitleProps) => {
  const {
    onClose,
    paddingHorizontal,
    titleId,
    paddingVertical,
    actionButtonId,
    actionButtonPress,
    showCreateNewButton
  } = props;
  const intl = useIntl();
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: paddingHorizontal || 16,
          paddingVertical: paddingVertical || 0,
        }}
      >
        <DisplayText
          textLocalId={titleId}
          size={'mdSemibold'}
          extraStyles={{
            color: 'black',
            fontSize: 14,
            fontWeight: '500',
          }}
        />
        {actionButtonId && (
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                actionButtonPress?.();
              },
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: actionButtonId || '',
              }),
              withRightBorder: false,
            }}
          ></FoldButton>
        )}
        {showCreateNewButton && !showOnlyInternalChatInSidecar && (
          <SidecarCreateAction
            patientUuid={props.patientUuid}
            panelType={props.panelType}
          />
        )}
      </Stack>
      <Divider />
    </Stack>
  );
};
