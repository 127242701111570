import {Center, View} from 'native-base';
import {JOURNEYS_TABLE_VIEW} from '../../../constants';
import { isOrderingEnabled } from '../../../utils/capabilityUtils';
// import {
//   dealData,
//   ticketData,
// } from '../../../dummyData/personRightContainerData';
import {ITabDataProps, ITabProps} from '../../common/TabList/interface';
import PatientCareJourneyListView from '../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourney/PatientCareJourneyListView';
import {ProgramsTable} from '../../RightSideContainer/Journeys/JourneysOfCare/Table/ProgramsTable';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
// import {DealList} from '../RightContainer/ListView/DealList';
// import {TicketList} from '../RightContainer/ListView/TicketList';
import {IMiddleContainerTab, IQuickAction} from './interfaces';
import LatestConversation from './Messages/LatestConversation';
import {
  DISPLAY_TABS_BY_PERSON_TYPE,
  DISPLAY_VALUES_BY_PERSON_TYPE,
  MIDDLE_CONTAINER_TABS,
  MIDDLE_CONTAINER_TABS_BY_PERSON_TYPE,
  MIDDLE_CONTAINER_TABS_LIST,
  QUICK_ACTION_LIST,
  TAB_CODES,
  TAB_LIST,
} from './MiddleContainerConst';
import { EHRName } from './PatientNotes/interfaces';
import { getCurrentEHR } from './PatientNotes/PatientNotesHelper';
import {timelineDummyData} from './TimelineHelper';
import {TimelineView} from './TimelineView';

export const getQuickActionObjListByPersonType = (
  personType: string
): IQuickAction[] => {
  const personQuickActionCodeList = DISPLAY_VALUES_BY_PERSON_TYPE[personType];
  const quickActionObjList = QUICK_ACTION_LIST.filter(
    (actionObj: IQuickAction) => {
      return personQuickActionCodeList.includes(actionObj.actionCode);
    }
  );
  return quickActionObjList || [];
};

export const getDataByTabType = (tabType: string) => {
  const data = timelineDummyData.filter(
    (timelineData) => timelineData.timelineType === tabType
  );
  return data;
};

// another argument data will be added to this function. that data will be passed down to thec components.
export const getTabComponentByCode = (
  tabCode: string,
  personData: any,
  fhirDataList?: any,
  conversation?: any
): JSX.Element => {
  let selectedTabElem = <></>;
  switch (tabCode) {
    case TAB_CODES.ACTIVITY:
      selectedTabElem = (
        <TimelineView
          mainTimelineTitle=""
          timelineData={timelineDummyData}
          fhirDataList={fhirDataList}
          personData={personData}
        />
      );
      break;
    case TAB_CODES.NOTES:
      selectedTabElem = (
        <TimelineView
          mainTimelineTitle="February 2022"
          timelineData={getDataByTabType('NOTES')}
          personData={personData}
        />
      );
      break;
    case TAB_CODES.CONVERSATION:
      selectedTabElem = (
        <LatestConversation
          mainTimelineTitle="February 2022"
          timelineData={getDataByTabType('MESSENGER')}
          personData={personData}
          conversation={conversation}
        />
      );
      break;
    case TAB_CODES.EMAIL:
      selectedTabElem = (
        <TimelineView
          mainTimelineTitle="February 2022"
          timelineData={getDataByTabType('EMAIL')}
          personData={personData}
        />
      );
      break;
    case TAB_CODES.TASK:
      selectedTabElem = (
        <TimelineView
          mainTimelineTitle="February 2022"
          timelineData={getDataByTabType('TASK')}
          personData={personData}
        />
      );
      break;
    case TAB_CODES.SMS:
      selectedTabElem = (
        <TimelineView
          mainTimelineTitle="February 2022"
          timelineData={getDataByTabType('SMS')}
          personData={personData}
        />
      );
      break;
    case TAB_CODES.CAMPAIGN:
      selectedTabElem = (
        <TimelineView
          mainTimelineTitle="February 2022"
          timelineData={getDataByTabType('CAMPAIGN')}
          personData={personData}
        />
      );
      break;

    case TAB_CODES.JOURNEYS:
      selectedTabElem = (
        <View marginTop={2} width="full">
          {/* <ProgramsTable
            hideSearchBar
            view={JOURNEYS_TABLE_VIEW.PATIENT_JOURNEYS}
            personData={personData}
          /> */}
          <PatientCareJourneyListView personData={personData} />
        </View>
      );
      break;

    case TAB_CODES.TASKS:
      selectedTabElem = (
        <View marginTop={2} width="full">
          {/* <Tasks personData={personData} /> */}
        </View>
      );

      break;
  }
  return selectedTabElem;
};

export const getTabListByPersonType = (
  personType: string,
  personData: any
): ITabProps[] => {
  const personTabCodeList = DISPLAY_TABS_BY_PERSON_TYPE[personType];
  const tabObjList: ITabProps[] = [];
  TAB_LIST.forEach((tabObj: ITabDataProps) => {
    if (personTabCodeList.includes(tabObj.tabCode)) {
      const singleTabObj: ITabProps = {
        key: tabObj.tabCode,
        title: tabObj.title,
        tabContainerElem: (): any => {
          return (
            <Center>
              {getTabComponentByCode(tabObj.tabCode, personData, [])}
            </Center>
          );
        },
      };
      tabObjList.push(singleTabObj);
    }
  });
  return tabObjList || [];
};

export const getMiddleContainerTabsByPersonType = (
  personType: string,
  enableCareProgram: boolean,
  locationId: string | undefined
): IMiddleContainerTab[] => {
  const currentEHR = getCurrentEHR(locationId, '');
  const isFold = currentEHR === EHRName.FOLD;
  const orderingEnabled = isFold && isOrderingEnabled(locationId);
  const tabList: IMiddleContainerTab[] = [];
  const personLabCodeList = MIDDLE_CONTAINER_TABS_BY_PERSON_TYPE[personType];

  MIDDLE_CONTAINER_TABS_LIST.forEach((item) => {
    if (personLabCodeList?.includes(item.tabCode)) {
      const singleTab: IMiddleContainerTab = {
        tabCode: item.tabCode,
        tabTitle: item.tabTitle,
      };
      if (item.tabCode == MIDDLE_CONTAINER_TABS.ORDERS) {
        if (orderingEnabled) {
          tabList.push(singleTab);
        }
      } else if (item.tabCode == MIDDLE_CONTAINER_TABS.CARE_PLAN || item.tabCode == MIDDLE_CONTAINER_TABS.ALERTS) {
        // commenting for now as this story is in progress
        if (enableCareProgram) {
          tabList.push(singleTab);
        }
      } else {
        tabList.push(singleTab);
      }
    }
  });

  return tabList;
};

export const getFormattedPractitionersData = (
  data: any
): {id: string; name: string}[] => {
  const entry = data.entry;
  return entry.map((item: any) => {
    const resource = item.resource;
    const fullName = getFullName(resource);
    return {
      id: resource.id,
      name: fullName,
    };
  });
};

export const getFullName = (resource: any): string => {
  const name: any[] = resource.name || [];
  if (name.length == 0) {
    return '';
  }
  if (name[0].text && name[0].text?.length > 0) {
    return name[0].text;
  }
  if (name[0].given && name[0].given?.length > 0 && name[0].family?.length) {
    return name[0].given[0] + ' ' + name[0].family;
  }
  return '';
};
