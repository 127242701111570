import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  Checkbox as AntdCheckbox,
  Calendar,
  Collapse,
  Divider,
  Input,
  notification,
  Popover,
  Spin,
  Table,
  Tooltip
} from 'antd';
import {
  Divider as NativeBaseDivider,
  Pressable,
  Skeleton,
  Spinner
} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Text, View, ViewStyle} from 'react-native';
import AlertSeverityIcon from '../../assets/Icons/AlertSeverityIcon';
import NoteLinkIcon from '../../assets/Icons/NoteLinkIcon';
import {DATE_FORMATS, TASK_TYPES} from '../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../constants/Configs';
import {FHIR_RESOURCE} from '../../constants/FhirConstant';
import {
  MLOV_CATEGORY,
  TASK_ALERT_CATEGORY_CODES,
  TASK_ALERT_CUSTOM_CATEGORY_CODES,
  TASK_PRIORITY_CODES,
  TASK_STATUS
} from '../../constants/MlovConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {FlowType} from '../../context/WorkflowContext';
import {ILoginUserData, IMlov} from '../../Interfaces';
import {SEND_EDUCATION_CONTENT} from '../../services/CareJourney/CareJourneyQueries';
import {getDocumentsReferenceWithFilters} from '../../services/CommonService/AidBoxService';
import {
  CREATE_AUDIT_LOG_FOR_ALERT_ACTION,
  GET_TASK_ALERT_PREFERENCE_REASONS,
  GET_TASK_ALERTS_TYPES,
} from '../../services/Task/TaskQueries';
import {Colors} from '../../styles/Colors';
import {getResourceAbilities} from '../../utils/capabilityUtils';
import {getAccountUUID, getUserFullName, getUserUUID} from '../../utils/commonUtils';
import {getCareDashboardDateRange, getDateObject, getDateStrFromFormat, getEndOfDay, getFormattedDate, getMomentObjectWithDateStringAndFormat, getNextCalendarDate, getPreviousCalendarDate, isBeforeOrSameDate, } from '../../utils/DateUtils';
import {
  getMlovCodeFromId,
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovObjectFromId
} from '../../utils/mlovUtils';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import {manageAttachmentsListData} from '../common/AddTask/AddTaskUtils';
import {AppointmentType} from '../common/CalendarWidget/BookingWorkflows/AppointmentTypeSelection/AppointmentTypeEnum';
import BookingWorkflow from '../common/CalendarWidget/BookingWorkflows/BookingWorkflow';
import {CalendarView} from '../common/CalendarWidget/CalendarWidgetEnums';
import {ParticipantType} from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {ITasksAttachments} from '../common/CareDashboard/CareDashboardInterfaces';
import {FilterWrapperComponent} from '../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import ContactSendForm from '../common/ContactSendForm/ContactSendForm';
import Stack from '../common/LayoutComponents/Stack';
import ClearActionIcon from '../common/Svg/ClearActionIcon';
import AddTaskSvg from '../common/Svg/SideCarSvg/AddTaskSvg';
import AssessmentSvg from '../common/Svg/SideCarSvg/AssessmentSvg';
import BackArrowSvg from '../common/Svg/SideCarSvg/BackArrowSvg';
import ClosedCollapseSvg from '../common/Svg/SideCarSvg/ClosedCollpaseSvg';
import DismissedAlertsSvg from '../common/Svg/SideCarSvg/DismissedAlertsSvg';
import DismissIconSvg from '../common/Svg/SideCarSvg/DismissIconSvg';
import OpenedCollapseSvg from '../common/Svg/SideCarSvg/OpenedCollapseSvg';
import PatientEducationSvg from '../common/Svg/SideCarSvg/PatientEducationSvg';
import PendingAlertsSvg from '../common/Svg/SideCarSvg/PendingAlertsSvg';
import ResolvedAlertsSvg from '../common/Svg/SideCarSvg/ResolvedAlertsSvg';
import ScheduleAppointmentSvg from '../common/Svg/SideCarSvg/ScheduleAppointmentSvg';
import SnoozedAlertsSvg from '../common/Svg/SideCarSvg/SnoozedAlertsSvg';
import SuggestedActionsSvg from '../common/Svg/SideCarSvg/SuggestedActionsSvg';
import ThreeDotsSvg from '../common/Svg/SideCarSvg/ThreeDotsSvg';
import useGetBatchedAccountUsers from '../CustomHooks/useGetBatchedAccountUsers';
import {ButtonType} from '../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import {getEhrConfig} from '../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {INotesFormattedDataProps} from '../PersonOmniView/MiddleContainer/interfaces';
import {DocStatus} from '../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {getFormattedNoteForCareTimeline} from '../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import {FORM_CATEGORY_TYPES} from '../RightSideContainer/Forms/FormsConstants';
import {getNodeMetadata} from '../RightSideContainer/Journeys/AddOrUpdateJourney/JourneyMetadataService';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {setFieldsVisibilityFromPreviousNode} from '../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeHelper';
import {
  ICondition,
  IUserInputField,
} from '../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import SideMenu from '../RightSideContainer/Workflow/FlowComponent/StateNodes/SideMenu';
import AlertActionReasonView from './AlertActionReasonView';
import AttachmentsList from './components/AttachmentsList';
import SidecarConfirmationPopup from './components/SidecarConfirmationPopup';
import {
  ALERT_ACTION_CODES,
  ALERT_CATEGORY_CODES_MAPPED,
  ALERTS_ACTIONS_MENU,
  EDUCATION_CONTENT_NODE_DETAILS,
  NODE_INPUT_KEYS
} from './constants';
import {
  IAlertActionReasons,
  IAlertsViewActionsState,
  IAlertsViewComponentState,
  ITaskAlertsType,
} from './interfaces';
import useFetchAlerts, {ITaskAlert} from './SideBarCustomHooks/useFetchAlerts';
import './SideCar.css';
import {SidecarAddNotesDrawer} from './SidecarAddNotesDrawer';
import {
  getFilteredActionsMenuForAlerts,
  getSeverityNamesFromPriorityCodes,
} from './SidecarUtils';
import SidePagination from './SidePagination';
import SnoozeView from './SnoozeView';

const {Column} = Table;
const {Panel} = Collapse;

interface IAlertsViewProps {
  showFiltersTabInAlerts: boolean;
  onFilterApplied: (filterCount: number, isResetFilter?: boolean) => void;
  searchText: string;
  onPendingCountChange: (count: number) => void;
  contactId?: string;
  formattedContactData?: any;
  isActionableDrawer?: boolean;
  contactData?: IContact;
  openCareGapDetail?:(alert: ITaskAlert)=> void;
  filterStyle?: ViewStyle
}

const AlertsView = (props: IAlertsViewProps) => {
  const mlovData = useContext(CommonDataContext);
  const userData = mlovData.userData || ({} as ILoginUserData);
  const intl = useIntl();
  const userUuid = getUserUUID();
  const contactLocationUuid =
    props?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
  const isSideCarContext = !!mlovData.sidecarContext?.isSidecar;
  const taskPriorityMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_PRIORITY
    );
    const taskAlertPreferenceReasonsMlovs = getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ALERT_DISPLAY_PREFERENCE_REASONS
    )
  const taskAlertDismissReasonsMlovs = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_ALERT_DISMISS_REASONS
  );
  const taskAlertResolveReasonsMlovs = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_ALERT_RESOLVE_REAOSNS
  );
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const accountUUID = getAccountUUID();
  const taskAlertDisplayCategoryMlovs = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
  )?.sort((a, b) => {
    if (a.code === 'pending') {
      return -1;
    }
    if (b.code === 'pending') {
      return 1;
    }
    return 0;
  });
  const taskTypeActionsMlovList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_TYPE_ACTIONS
  );

  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    contactLocationUuid
  );
  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };

  const ehrCapabilities = mlovData.ehrCapabilities || [];


  const currentEHR = ehrCapabilities?.length
    ? ehrCapabilities?.[0]?.ehrName
    : '';
  const ehrConfig = getEhrConfig(currentEHR);

  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;

  const initialFilters = {
    selectedPriorityCodes: [] as string[],
    selectedTaskAlertDisplayCodes: [] as string[],
    formData: {
      selectedPriorityList: [] as IMlov[],
      selectedTaskAlertTypeList: [] as ITaskAlertsType[],
    },
  };

  const {
    userList: accountUserList,
  } = useGetBatchedAccountUsers();

  const [componentState, setComponentState] =
    useState<IAlertsViewComponentState>({
      pendingAlertsPage: 1,
      snoozedAlertsPage: 1,
      resolvedAlertsPage: 1,
      dismissedAlertsPage: 1,
      pageSize: 5,
      search: '',
      filtersInitialData: {
        priorityTypeList: taskPriorityMlovs?.filter((item) => item.code !== TASK_PRIORITY_CODES.LOW),
        taskAlertTypeCategoriesList: [],
      },
      filters: initialFilters,
      pendingAlertsPrioritySort: 'default',
      snoozedAlertsPrioritySort: 'default',
      resolvedAlertsPrioritySort: 'default',
      dismissedAlertsPrioritySort: 'default',
      showSearch: false,
      activePanels: [TASK_ALERT_CATEGORY_CODES.PENDING],
      pendingAlerts: [] as ITaskAlert[],
      snoozedAlerts: [] as ITaskAlert[],
      resolvedAlerts: [] as ITaskAlert[],
      dismissedAlerts: [] as ITaskAlert[],
      caregapsAlerts: [] as ITaskAlert[],
      pendingLoading: true,
      snoozedLoading: false,
      resolvedLoading: false,
      dismissedLoading: false,
      caregapsLoading: true,
      caregapsTotalCount: 0,
      pendingTotalCount: 0,
      snoozedTotalCount: 0,
      resolvedTotalCount: 0,
      dismissedTotalCount: 0,
      pendingCountLoading: true,
      snoozedCountLoading: true,
      dismissedCountLoading: true,
      resolvedCountLoading: true,
    });

  const [actionsState, setActionsState] = useState<IAlertsViewActionsState>({
    showDismissAlertReasonView: false,
    showResolveAlertReasonView: false,
    showAddTaskDrawer: false,
    showAppointmentDrawer: false,
    showContactSendForm: false,
    showEducationalContentDrawer: false,
    showAddNotesDrawer: false,
    alertActionReasons: [],
    showSnoozeView: false,
    showDatePicker: false,
    showUpdateNotesDrawer: false,
  });
  const [attachmentState, setAttachmentState] = useState<{showAttachmentDrawer: boolean, attachments: ITasksAttachments[]}>({
    showAttachmentDrawer: false,
    attachments: []
  });

  const [typeNodes, setTypeNodes] = useState<any>();
  const [selectedDateForSnooze, setSelectedDateForSnooze] = useState<string>(getNextCalendarDate(CalendarView.day, new Date()).toISOString());
  const [isOpenActionsPopover, setIsOpenActionsPopover] = useState<
    Record<string, boolean>
  >({});

  const [onHoverType, setOnHoverType] = useState<Record<string, boolean>>({});
  const [expandedDescriptions, setExpandedDescriptions] = useState<
    Record<string, boolean>
  >({});

  const toggleDescription = (id: string) => {
    setExpandedDescriptions((prevState: any) => ({
      ...prevState,
      [id]: !prevState?.[id],
    }));
  };
  const needsTruncation = (text: string) => {
    return text.length > 100;
  };

  const getNewTaskMember = () => {
    return {
      value: props?.formattedContactData?.contactUUID,
      label: props?.formattedContactData?.name,
      key: props?.formattedContactData?.contactUUID,
      type: ParticipantType.patient,
      contactData: props?.formattedContactData?.contactData,
      data: props?.formattedContactData?.contactData,
    };
  };

  const {fetchData, handleAlertStatusChange, loaders} = useFetchAlerts({
    contactId: props.contactId,
  });

  const {data: taskAlertTypes, loading: loadingTaskAlertTypes} = useQuery<{
    getTaskTypes: {taskTypes: ITaskAlertsType[]};
  }>(GET_TASK_ALERTS_TYPES, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      params: {
        isAlert: true,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const alertsData = data?.getTaskTypes?.taskTypes;
      setComponentState((prev) => ({
        ...prev,
        filtersInitialData: {
          ...prev.filtersInitialData,
          taskAlertTypeCategoriesList: alertsData,
        },
      }));
    },
    onError: (error: any) => {

    },
  });

  const [createAuditLogForAlertAction] = useMutation(CREATE_AUDIT_LOG_FOR_ALERT_ACTION, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onCompleted: (data) => {
      //
    },
    onError: (error) => {
      //
    }
  })

  const logAuditAction = (
    actionCode: string,
    auditData: {
    formLogIds?: string[],
    appointmentId?: string,
    taskId?: string,
    documentId?: string,
    noteId?: string,
    docStatus?: string
  }, selectedTaskAlertForAction?: ITaskAlert) => {
    const taskAlert = selectedTaskAlertForAction || actionsState.selectedTaskAlertForAction;
    const actionId = getMlovIdFromCode(taskTypeActionsMlovList, actionCode);
    setActionsState((prev) => ({
      ...prev,
      selectedTaskAlertForAction: undefined,
    }));
    createAuditLogForAlertAction({
      variables: {
        params:
        {
          taskId: taskAlert?.id,
          taskTypeActionId: actionId,
          auditData: auditData,
        }
      }
    })
  }

  const [sendPatientEducation, {loading: sendPatientEducationLoading}] =
    useMutation(SEND_EDUCATION_CONTENT, {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: (data) => {
        if (actionsState?.selectedTaskAlertForAction) {
          logAuditAction(
            ALERT_ACTION_CODES.SEND_PATIENT_EDUCATION,
            { documentId: data?.sendEducationContentToContact?.documentId },
            actionsState?.selectedTaskAlertForAction,)
        }
        setActionsState((prev) => ({
          ...prev,
          showEducationalContentDrawer: false,
          selectedTaskAlertForAction: undefined,
        }));
        notification.success({
          message: intl.formatMessage({id: 'contentSentSuccessfully'}),
          duration: 3.0,
          placement: 'topRight',
        });
      },
      onError: (error: any) => {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      },
    });

  const [getAlertActionReasons, {loading: loadingAlertActionReasons}] =
    useLazyQuery<{
      getTaskAlertDisplayPreferenceReasonConfigs: {
        configs: IAlertActionReasons[];
      };
    }>(GET_TASK_ALERT_PREFERENCE_REASONS, {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const alertActionReasonsData =
          data?.getTaskAlertDisplayPreferenceReasonConfigs?.configs;
        setActionsState((prev) => ({
          ...prev,
          alertActionReasons: alertActionReasonsData,
        }));
      },
      onError: (error: any) => {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      },
    });

  useEffect(() => {
    const stateFieldsToChange = {
      pendingAlertsPage: 1,
      snoozedAlertsPage: 1,
      resolvedAlertsPage: 1,
      dismissedAlertsPage: 1,
    };
    fetchAlerts(componentState.activePanels, stateFieldsToChange, true);
    fetchCareGaps();
  }, [props.searchText,loadingTaskAlertTypes]);
  
  useEffect(() => {
    props.onPendingCountChange(componentState.pendingTotalCount + componentState.caregapsTotalCount);
  }, [componentState.pendingTotalCount, componentState.caregapsTotalCount])

  useEffect(() => {
    props.onPendingCountChange(componentState.pendingTotalCount + componentState.caregapsTotalCount);
  }, [componentState.pendingTotalCount, componentState.caregapsTotalCount])

  useEffect(() => {
    getNodeMetaData();
  }, []);

  const fetchAlerts = async (
    statusCodes: string[],
    stateFieldsToUpdate?: {[key: string]: any},
    getAlertCountOfAllCategories?: boolean,
    filters?: IAlertsViewComponentState['filters']
  ) => {
    if (loadingTaskAlertTypes) {
      return
    }
    if (!!stateFieldsToUpdate) {
      setComponentState((prev) => ({
        ...prev,
        ...stateFieldsToUpdate,
      }));
    }
    const taskTypeIds: string[] = []
    taskAlertTypes?.getTaskTypes?.taskTypes.forEach(alertType => {
      if (alertType.code !== TASK_TYPES.CARE_GAPS) {
        taskTypeIds.push(alertType.id)
      }
    })
    const paramsArray = statusCodes.map((statusCode) => {
      const params = {
        page: 1,
        pageSize: componentState.pageSize,
        search: componentState.search,
        filters: filters ?? componentState.filters,
        prioritySort: '',
        statusCode,
        taskTypeIds: taskTypeIds
      };

      let stateKeyToUpdate = '';
      let loadingKeyToUpdate = '';
      let totalCountKeyToUpdate = '';
      let countLoadingKeyToUpdate = '';

      switch (statusCode) {
        case TASK_ALERT_CATEGORY_CODES.PENDING:
          setComponentState((prev) => ({
            ...prev,
            pendingLoading: true,
            pendingCountLoading: true,
          }));
          params.page =
            stateFieldsToUpdate?.pendingAlertsPage ||
            componentState.pendingAlertsPage;
          params.prioritySort =
            stateFieldsToUpdate?.pendingAlertsPrioritySort ||
            componentState.pendingAlertsPrioritySort;
          stateKeyToUpdate = 'pendingAlerts';
          loadingKeyToUpdate = 'pendingLoading';
          totalCountKeyToUpdate = 'pendingTotalCount';
          countLoadingKeyToUpdate = 'pendingCountLoading';
          break;
        case TASK_ALERT_CATEGORY_CODES.SNOOZED:
          setComponentState((prev) => ({
            ...prev,
            snoozedLoading: true,
            snoozedCountLoading: true,
          }));
          params.page =
            stateFieldsToUpdate?.snoozedAlertsPage ||
            componentState.snoozedAlertsPage;
          params.prioritySort =
            stateFieldsToUpdate?.snoozedAlertsPrioritySort ||
            componentState.snoozedAlertsPrioritySort;
          stateKeyToUpdate = 'snoozedAlerts';
          loadingKeyToUpdate = 'snoozedLoading';
          totalCountKeyToUpdate = 'snoozedTotalCount';
          countLoadingKeyToUpdate = 'snoozedCountLoading';
          break;
        case TASK_ALERT_CATEGORY_CODES.RESOLVED:
          setComponentState((prev) => ({
            ...prev,
            resolvedLoading: true,
            resolvedCountLoading: true,
          }));
          params.page =
            stateFieldsToUpdate?.resolvedAlertsPage ||
            componentState.resolvedAlertsPage;
          params.prioritySort =
            stateFieldsToUpdate?.resolvedAlertsPrioritySort ||
            componentState.resolvedAlertsPrioritySort;
          stateKeyToUpdate = 'resolvedAlerts';
          loadingKeyToUpdate = 'resolvedLoading';
          totalCountKeyToUpdate = 'resolvedTotalCount';
          countLoadingKeyToUpdate = 'resolvedCountLoading';
          break;
        case TASK_ALERT_CATEGORY_CODES.DISMISSED:
          setComponentState((prev) => ({
            ...prev,
            dismissedLoading: true,
            dismissedCountLoading: true,
          }));
          params.page =
            stateFieldsToUpdate?.dismissedAlertsPage ||
            componentState.dismissedAlertsPage;
          params.prioritySort =
            stateFieldsToUpdate?.dismissedAlertsPrioritySort ||
            componentState.dismissedAlertsPrioritySort;
          stateKeyToUpdate = 'dismissedAlerts';
          loadingKeyToUpdate = 'dismissedLoading';
          totalCountKeyToUpdate = 'dismissedTotalCount';
          countLoadingKeyToUpdate = 'dismissedCountLoading';
          break;
        default:
          throw new Error(`Unknown statusCode: ${statusCode}`);
      }

      return {
        params,
        stateKeyToUpdate,
        loadingKeyToUpdate,
        totalCountKeyToUpdate,
        countLoadingKeyToUpdate,
      };
    });
    // Kept this code for future reference if count on all categories is needed
    const allCodes = Object.values(TASK_ALERT_CATEGORY_CODES);
    const codesForAggregateQuery = getAlertCountOfAllCategories
      ? allCodes.filter(
          (code) =>
            !statusCodes?.includes(code) &&
            code === TASK_ALERT_CATEGORY_CODES.PENDING
            // This is to get count for pending alerts tab when it is collapsed but count is needed. When count for all categories needs to be shown, this condition of pending can be removed
        )
      : undefined;
    codesForAggregateQuery?.forEach((displayCategoryCode) => {
      const countLoadingKeyToUpdate = `${displayCategoryCode.toLowerCase()}CountLoading`;
      setComponentState((prev) => ({
        ...prev,
        [countLoadingKeyToUpdate]: true,
      }));
    });
    const paramsForAggregateQuery = {
      filters: filters ?? componentState.filters,
    };
    const fetchedAlertDataArray = await fetchData(
      props.searchText,
      paramsArray,
      codesForAggregateQuery,
      paramsForAggregateQuery
    );
    fetchedAlertDataArray.forEach((fetchedAlertData, index) => {
      if (paramsArray?.length > 0 && index <= paramsArray?.length - 1) {
        const {
          stateKeyToUpdate,
          loadingKeyToUpdate,
          totalCountKeyToUpdate,
          countLoadingKeyToUpdate,
        } = paramsArray[index];
                setComponentState((prev) => ({
          ...prev,
          [stateKeyToUpdate]: fetchedAlertData.data || [],
          [loadingKeyToUpdate]: false,
          [totalCountKeyToUpdate]: fetchedAlertData.totalCount,
          [countLoadingKeyToUpdate]: false,
        }));
      } else if (!!codesForAggregateQuery) {
        const displayCategoryCode =
          codesForAggregateQuery[index - paramsArray?.length || 0];
        const totalCountKeyToUpdate = `${displayCategoryCode.toLowerCase()}TotalCount`;
        const countLoadingKeyToUpdate = `${displayCategoryCode.toLowerCase()}CountLoading`;
        const loadingKeyToUpdate = `${displayCategoryCode.toLowerCase()}Loading`;
        setComponentState((prev) => ({
          ...prev,
          [loadingKeyToUpdate]: false,
          [totalCountKeyToUpdate]: fetchedAlertData.totalCount,
          [countLoadingKeyToUpdate]: false,
        }));
      }
    });
    return fetchedAlertDataArray;
  };

  const getNodeMetaData = () => {
    getNodeMetadata(
      FlowType.careJourney,
      (response) => {
        const nodeMap = response?.nodeMasterData?.nodeMap;
        const typeNodes = getNodeList(
          EDUCATION_CONTENT_NODE_DETAILS,
          nodeMap
        );
        const nodeType = 'CareJourneyContentV3';
        if (nodeType) {
          let typeData: any;
          if (typeNodes) {
            typeData = typeNodes.find((obj: any) => obj[nodeType]);
          }
          const userInputFieldList = typeData[nodeType].userInputFieldList;
          const {inputFields: updatedInputFieldList} =
            setFieldsVisibilityFromPreviousNode(
              userInputFieldList,
              nodeMap,
              undefined,
              FlowType.careJourney
            );

          const userInputFieldListCopy = updatedInputFieldList.map(
            (userInputField: IUserInputField) => {
              return {
                ...userInputField,
                value:
                  userInputField.value == undefined
                    ? userInputField.defaultValue
                    : userInputField.value,
              };
            }
          );
          typeData[nodeType].userInputFieldList = userInputFieldListCopy;
          setTypeNodes(typeData[nodeType]);
        }
      },
      () => {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      }
    );
  };

  const getNodeList = (
    taskType: typeof EDUCATION_CONTENT_NODE_DETAILS,
    allTaskType: any
  ) => {
    const tasktypeData: any = [];
    if (allTaskType.hasOwnProperty(taskType.type)) {
      const taskObject: any = {};
      taskObject[taskType.type] = allTaskType[taskType.type];
      const filteredUserInputFieldList = taskObject[
        taskType.type
      ].userInputFieldList.filter((item: any) =>
        taskType.userInputFieldListKeys.includes(item.key)
      );
      taskObject[taskType.type].userInputFieldList = [];
      taskObject[taskType.type].userInputFieldList.push(
        ...filteredUserInputFieldList
      );
      tasktypeData.push(taskObject);
    }
    return tasktypeData;
  };

  const handleFilterChange = (isResetFilter?: boolean) => {
    let filterCount = 0;
    if (!isResetFilter) {
      if (componentState.filters.formData.selectedPriorityList.length > 0) {
        filterCount += 1;
      }
      if (componentState.filters.formData.selectedTaskAlertTypeList.length) {
        filterCount += 1;
      }
    }
    props.onFilterApplied(filterCount, isResetFilter);
    const stateFieldsToChange = {
      pendingAlertsPage: 1,
      snoozedAlertsPage: 1,
      resolvedAlertsPage: 1,
      dismissedAlertsPage: 1,
    };
    const statusCodes = [];
    if (
      componentState?.activePanels.includes(TASK_ALERT_CATEGORY_CODES.PENDING)
    ) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.PENDING);
    }
    if (
      componentState?.activePanels.includes(TASK_ALERT_CATEGORY_CODES.SNOOZED)
    ) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.SNOOZED);
    }

    if (
      componentState?.activePanels.includes(TASK_ALERT_CATEGORY_CODES.RESOLVED)
    ) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.RESOLVED);
    }
    if (
      componentState?.activePanels.includes(TASK_ALERT_CATEGORY_CODES.DISMISSED)
    ) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.DISMISSED);
    }
    fetchAlerts(
      statusCodes,
      stateFieldsToChange,
      true,
      isResetFilter ? initialFilters : undefined
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComponentState((prev) => ({
      ...prev,
      search: event?.target?.value,
      page: 1,
    }));
    fetchAlerts([
      TASK_ALERT_CATEGORY_CODES.PENDING,
      TASK_ALERT_CATEGORY_CODES.SNOOZED,
      TASK_ALERT_CATEGORY_CODES.RESOLVED,
      TASK_ALERT_CATEGORY_CODES.DISMISSED,
    ]);
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    displayCategoryCode: string
  ) => {
    const sortOrder =
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : 'default';
    const stateKeyToUpdate = `${displayCategoryCode.toLowerCase()}AlertsPrioritySort`;
    const stateFieldsToChange = {
      [stateKeyToUpdate]: sortOrder,
    };
    fetchAlerts([displayCategoryCode], stateFieldsToChange);
  };

  const handlePaginationChange = (
    displayCategoryCode: string,
    newPage: number,
    newPageSize: number
  ) => {
    const stateKeyToUpdate = `${displayCategoryCode.toLowerCase()}AlertsPage`;
    const stateFieldsToUpdate = {
      [stateKeyToUpdate]: newPage,
      pageSize: newPageSize,
    };
    fetchAlerts([displayCategoryCode], stateFieldsToUpdate);
  };

  const handlePanelChange = (key: string | string[]) => {
    const newActivePanels = Array.isArray(key) ? key : [key];
    const stateFieldsToUpdate = {activePanels: newActivePanels};
    const newlyAddedPanels = newActivePanels?.filter(
      (code) => !componentState?.activePanels?.includes(code)
    );
    const statusCodes = [];

    if (newlyAddedPanels?.includes(TASK_ALERT_CATEGORY_CODES.PENDING)) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.PENDING);
    }
    if (newlyAddedPanels?.includes(TASK_ALERT_CATEGORY_CODES.SNOOZED)) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.SNOOZED);
    }
    if (newlyAddedPanels?.includes(TASK_ALERT_CATEGORY_CODES.RESOLVED)) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.RESOLVED);
    }
    if (newlyAddedPanels?.includes(TASK_ALERT_CATEGORY_CODES.DISMISSED)) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.DISMISSED);
    }
    setComponentState((prev) => ({
      ...prev,
      ...stateFieldsToUpdate,
    }));
    if (statusCodes.length > 0) {
      fetchAlerts(statusCodes);
    }
  };
  const fetchCareGaps = async () => {
    if (loadingTaskAlertTypes) {
      return
    }
    const taskTypeIds: string[] = []
    taskAlertTypes?.getTaskTypes?.taskTypes.forEach(alertType => {
      if (alertType.code === TASK_TYPES.CARE_GAPS) {
        taskTypeIds.push(alertType.id)
      }
    })
    const params = {
      page: 1,
      pageSize: componentState.pageSize,
      search: componentState.search,
      prioritySort: '',
      taskTypeIds: taskTypeIds,
      statusCode: TASK_ALERT_CATEGORY_CODES.PENDING
    };
    const paramsArray = [
      {
        params: params,
        stateKeyToUpdate: 'caregapsAlerts',
        loadingKeyToUpdate: 'caregapsLoading',
        totalCountKeyToUpdate: 'caregapsTotalCount'
      }
    ]
    const fetchedAlertDataArray = await fetchData(
      props.searchText,
      paramsArray,
      [],
    );
    fetchedAlertDataArray.forEach((fetchedAlertData, index) => {
      if (paramsArray?.length > 0 && index <= paramsArray?.length - 1) {
        const {
          stateKeyToUpdate,
          loadingKeyToUpdate,
          totalCountKeyToUpdate,
        } = paramsArray[index];
        setComponentState((prev) => ({
          ...prev,
          [stateKeyToUpdate]: fetchedAlertData.data || [],
          [loadingKeyToUpdate]: false,
          [totalCountKeyToUpdate]: fetchedAlertData.totalCount,
        }));
      }
    })
  }
  const getComponentStateForCode = (code: string) => {
    const lowerCode = code.toLowerCase();
    return {
      totalCount: componentState[
        `${lowerCode}TotalCount` as keyof IAlertsViewComponentState
      ] as number,
      alerts: componentState[
        `${lowerCode}Alerts` as keyof IAlertsViewComponentState
      ] as ITaskAlert[],
      loading: componentState[
        `${lowerCode}Loading` as keyof IAlertsViewComponentState
      ] as boolean,
    };
  };
  const handleCareGapDetail = (record: ITaskAlert) =>{
    props.openCareGapDetail?.(record);
  }
  const handleVisibleChange = (visible: any, recordId: string) => {
    setSelectedDateForSnooze(getNextCalendarDate(CalendarView.day, new Date()).toISOString());
    setActionsState((prev) => ({
      ...prev,
      showSnoozeView: false,
      showDatePicker: false,
    }));
    setIsOpenActionsPopover({[recordId]: visible});
  };

  const getAlertCountView = (loading: boolean, alertCount: number) => {
    return (
      loading ? (
        <Stack
          direction={'row'}
          style={{
            marginBottom: 2,
            justifyContent: 'center',
          }}
        >
          <Skeleton.Text
            alignContent={'center'}
            width={10}
            justifyContent={'center'}
            alignItems={'center'}
            lines={0.5}
            marginTop={1}
          ></Skeleton.Text>
        </Stack>
      ) : (
        <Stack
          direction={'row'}
          style={{
            backgroundColor: Colors.FoldPixel?.GRAY50,
            borderRadius: 4,
            borderWidth: 0.5,
            borderColor: Colors.FoldPixel?.GRAY100,
          }}
        >
          <Text
            style={{
              color: Colors.FoldPixel?.GRAY300,
              fontWeight: '400',
              fontSize: 14,
              paddingHorizontal: 4,
              paddingVertical: 2,
            }}
          >
            {alertCount}
          </Text>
        </Stack>
      )
    )
  }

  const renderTable = (
    alerts: any,
    loading: boolean,
    totalCount: number,
    displayCategoryCode: string
  ) => {
    return (
      <Table
        className="alertsTable"
        dataSource={alerts}
        rowKey="id"
        pagination={false}
        loading={{
          spinning: loading,
          indicator: (
            <View style={{marginTop: 10}}>
              <Spin size={'default'} />
            </View>
          ),
        }}
        onChange={(pagination: any, filters: any, sorter: any) =>
          handleTableChange(pagination, filters, sorter, displayCategoryCode)
        }
        locale={{
          emptyText: intl.formatMessage({id: 'noAlerts'}),
        }}
      >
        <Column
          title={
            <Text
              style={{
                color: Colors?.FoldPixel?.GRAY300,
                fontWeight: '500',
                fontSize: 14,
              }}
            >
              S
            </Text>
          }
          dataIndex="severity"
          key="severity"
          sorter
          width={'5%'}
          render={(text, record: ITaskAlert) => {
            const priorityCode = getMlovCodeFromId(
              taskPriorityMlovs,
              record?.priorityId
            );
            const severityValue = getSeverityNamesFromPriorityCodes(priorityCode)
            return (
              <Tooltip title={severityValue}>
                <View style={{ alignItems: 'center' }}>
                  <AlertSeverityIcon priorityCode={priorityCode} />
                </View>
              </Tooltip>
            );
          }}
        />
        <Column
          title={
            <Text
              style={{
                color: Colors?.FoldPixel?.GRAY300,
                fontWeight: '500',
                fontSize: 14,
              }}
            >
              Alert Details
            </Text>
          }
          dataIndex="title"
          key="title"
          width={'55%'}
          render={(text, record: ITaskAlert) => {
            const displayCategoryId = getMlovIdFromCode(taskAlertDisplayCategoryMlovs, displayCategoryCode)
            const needsTruncationForDescription = needsTruncation(
              record?.description
            );
            const reasonObject = record?.taskAlertDisplayPreferences?.find((alertPreference) => {
              const isResolvedCategory = displayCategoryCode === TASK_ALERT_CATEGORY_CODES.RESOLVED;
              const matchesCategory = alertPreference.taskAlertDisplayCategoryId === displayCategoryId;
              if (isResolvedCategory) {
                return matchesCategory;
              }
              return alertPreference.userId === userUuid && matchesCategory;
            });
            const reasonMlov = getMlovObjectFromId(
              reasonObject?.reasonId ||'',
              taskAlertPreferenceReasonsMlovs
            );
            const reasonText = reasonMlov?.value;
            const reasonDisplayTitle = displayCategoryCode === TASK_ALERT_CATEGORY_CODES.RESOLVED ? 'Reason for Resolution: ' : 'Reason for Dismissal: '
            const noteToDisplay = reasonObject?.note;
            return (
              <Stack
                direction={'row'}
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack direction={'column'} space={2} style={{flex: 1}}>
                  <Text
                    style={{
                      color:
                        displayCategoryCode ===
                        TASK_ALERT_CATEGORY_CODES.RESOLVED
                          ? Colors.Custom.AlertsDescriptionColor
                          : Colors?.FoldPixel?.GRAY400,
                      fontWeight: '400',
                      fontSize: 14,
                      ...(displayCategoryCode ===
                        TASK_ALERT_CATEGORY_CODES.RESOLVED && {
                        textDecorationLine: 'line-through',
                      }),
                    }}
                  >
                    {record.title}
                  </Text>
                  {
                    record?.attachments?.length > 0 && (
                      <Pressable onPress={() => setAttachmentState((prev) => ({
                        ...prev,
                        showAttachmentDrawer: true,
                        attachments: record?.attachments,
                      }))}

                      style={{
                        maxWidth: 'fit-content',
                      }}
                      >
                        <Stack direction={'row'} space={8} style={{alignItems: 'center', maxWidth: 'fit-content'}}>
                          <Text
                            style={{
                              color: Colors?.FoldPixel?.PRIMARY300,
                              fontWeight: '400',
                              fontSize: 12,
                            }}
                          >
                            {'View attachments'}
                            <ClosedCollapseSvg customStrokeColor={Colors?.FoldPixel?.PRIMARY300} />
                          </Text>
                        </Stack>
                      </Pressable>
                    )
                  }
                  {!!record?.description && (
                    <Text
                      style={{
                        color: Colors?.Custom?.AlertsDescriptionColor,
                        fontWeight: '400',
                        fontSize: 12,
                      }}
                    >
                      {needsTruncationForDescription &&
                        !expandedDescriptions[record.id]
                        ? `${record.description.slice(0, 100)}...`
                        : record.description}
                      {
                        needsTruncationForDescription && ' '
                        // added empty space to show before 'show more' and 'show less'
                      }
                      {needsTruncationForDescription && (
                        <Text
                          style={{
                            color: Colors.FoldPixel.PRIMARY300,
                            textDecorationLine: 'underline',
                          }}
                          selectable={true}
                          onPress={() => toggleDescription(record.id)}
                        >
                          {expandedDescriptions?.[record.id]
                            ? 'Show less'
                            : 'Show more'}
                        </Text>
                      )}
                    </Text>
                  )}
                  { (!!reasonText || !!noteToDisplay) &&
                    <View style={{ borderRadius: 4, backgroundColor: Colors.FoldPixel?.GRAY50, padding: 4, maxWidth: 'fit-content' }}>
                        {!!reasonText &&
                          <Stack direction={'row'}
                          >
                            {<Text
                              style={{
                                color: Colors?.FoldPixel?.GRAY300,
                                fontWeight: '500',
                                fontSize: 12,
                              }}>
                              {
                                reasonDisplayTitle
                              }
                            </Text>
                            }
                            <Text
                              style={{
                                color: Colors?.FoldPixel?.GRAY300,
                                fontWeight: '400',
                                fontSize: 12,
                              }}
                            >
                              {reasonText}
                            </Text>

                          </Stack>
                        }
                      {!!noteToDisplay &&
                        <Stack direction={'row'}
                        >
                          {<Text
                            style={{
                              color: Colors?.FoldPixel?.GRAY300,
                              fontWeight: '500',
                              fontSize: 12,
                            }}>
                            Note:{' '}
                          </Text>
                          }
                          <Text
                            style={{
                              color: Colors?.FoldPixel?.GRAY300,
                              fontWeight: '400',
                              fontSize: 12,
                            }}
                          >
                            {noteToDisplay}
                          </Text>
                        </Stack>
                        }
                    </View>
                  }
                </Stack>
              </Stack>
            );
          }}
        />
        <Column
          title={
            <Text
              style={{
                color: Colors?.FoldPixel?.GRAY300,
                fontWeight: '500',
                fontSize: 14,
              }}
            >
              Type
            </Text>
          }
          dataIndex="type"
          key="type"
          width={'30%'}
          render={(text, record: ITaskAlert) => {
            return (
              <Stack direction={'row'} style={{paddingRight: 4}}>
                {loadingTaskAlertTypes ? (
                  <Skeleton.Text
                    alignContent={'center'}
                    width={'auto'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    lines={1}
                    marginTop={1}
                  ></Skeleton.Text>
                ) : (
                  <Pressable
                    cursor={'default'}
                    style={{
                      backgroundColor: onHoverType?.[record.id]
                        ? Colors.FoldPixel.PRIMARY100
                        : Colors.FoldPixel?.GRAY50,
                      maxWidth: '80%',
                      height: 'fit-content',
                      borderRadius: 4,
                      ...(onHoverType?.[record.id] && {
                        borderWidth: 1,
                        borderColor: Colors.FoldPixel.GRAY50,
                      }),
                    }}
                    {...(isSideCarContext ?
                      {
                        onHoverIn: () => setOnHoverType({[record.id]: true}),
                        onHoverOut: () => setOnHoverType({})
                      } :
                      {}
                    )}
                  >
                    {
                      <Text
                        style={{
                          color: Colors.FoldPixel?.GRAY300,
                          fontWeight: '400',
                          fontSize: 12,
                          paddingHorizontal: 4,
                          paddingVertical: 2,
                        }}
                      >
                        {
                          taskAlertTypes?.getTaskTypes?.taskTypes?.find(
                            (alertType) => alertType.id === record.taskTypeId
                          )?.value
                        }
                      </Text>
                    }
                  </Pressable>
                )}
                <View style={{flex: 1}} />
                <Stack
                  direction={'row'}
                  style={{alignItems: 'center', justifyContent: 'flex-end'}}
                >
                  <Popover
                    overlayClassName="alerts-action-popover"
                    key={record?.id}
                    overlayInnerStyle={{padding: -8, borderRadius: 16}}
                    overlayStyle={{padding: 0}}
                    overlay={{padding: 0}}
                    style={{padding: 0, borderRadius: 16}}
                    placement={'left'}
                    trigger="click"
                    open={!!isOpenActionsPopover?.[record?.id]}
                    onOpenChange={(visible: boolean) =>
                      handleVisibleChange(visible, record?.id)
                    }
                    content={() => popoverContent(record, displayCategoryCode)}
                  >
                    <Pressable
                      cursor={loadingTaskAlertTypes || loaders.statusChangeloading ? 'not-allowed' : 'pointer'}
                      disabled={loadingTaskAlertTypes || loaders.statusChangeloading}
                      onPress={() => {
                        handleVisibleChange(
                          !isOpenActionsPopover?.[record?.id],
                          record?.id
                        );
                      }}
                      _hover={{
                        backgroundColor: Colors.FoldPixel.GRAY50,
                      }}
                    >
                      {
                        record?.id === actionsState.selectedTaskAlertForAction?.id && loaders.statusChangeloading ? <Spinner />
                          :
                          <ThreeDotsSvg
                            customStrokeColor={
                              loadingTaskAlertTypes
                                ? Colors?.FoldPixel?.GRAY150
                                : undefined
                            }
                          />
                      }
                    </Pressable>
                  </Popover>
                </Stack>
              </Stack>
            );
          }}
        />
      </Table>
    );
  };
  const renderCareGapTable = (
    alerts: any,
    loading: boolean,
    totalCount: number,
    displayCategoryCode: string
  ) => {
    return (
      <Table
        className="alertsTable care-gap"
        dataSource={alerts}
        rowKey="id"
        pagination={false}
        loading={{
          spinning: loading,
          indicator: (
            <View style={{marginTop: 10}}>
              <Spin size={'default'} />
            </View>
          ),
        }}
        onChange={(pagination: any, filters: any, sorter: any) =>
          handleTableChange(pagination, filters, sorter, displayCategoryCode)
        }
        locale={{
          emptyText: intl.formatMessage({id: 'noAlerts'}),
        }}
      >
        <Column
          title={
            <Text
              style={{
                color: Colors?.FoldPixel?.GRAY300,
                fontWeight: '500',
                fontSize: 14,
              }}
            >
              Measure
            </Text>
          }
          dataIndex="title"
          key="title"
          width={'50%'}
          render={(text, record: ITaskAlert) => {
            const needsTruncationForDescription = needsTruncation(
              record?.description
            );
            const evalutionDate = record.startDateTime ? getDateStrFromFormat(record.startDateTime,DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT) : ''
            return (
              <Pressable
              testID={'careCapDetailButton'}
              onPress={() => handleCareGapDetail(record)}>
              <Stack
                direction={'row'}
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack direction={'column'} space={2} style={{flex: 1}}>
                  <Text
                    style={{
                      color:
                        displayCategoryCode ===
                        TASK_ALERT_CATEGORY_CODES.RESOLVED
                          ? Colors.Custom.AlertsDescriptionColor
                          : Colors?.FoldPixel?.GRAY400,
                      fontWeight: '400',
                      fontSize: 14,
                      ...(displayCategoryCode ===
                        TASK_ALERT_CATEGORY_CODES.RESOLVED && {
                        textDecorationLine: 'line-through',
                      }),
                    }}
                  >
                    {record.title}
                  </Text>
                  {!!record?.description && (
                    <Text
                      style={{
                        color: Colors?.Custom?.AlertsDescriptionColor,
                        fontWeight: '400',
                        fontSize: 12,
                      }}
                    >
                      {needsTruncationForDescription &&
                        !expandedDescriptions[record.id]
                        ? `${record.description.slice(0, 100)}...`
                        : record.description}
                      {
                        needsTruncationForDescription && ' '
                        // added empty space to show before 'show more' and 'show less'
                      }
                      {needsTruncationForDescription && (
                        <Text
                          style={{
                            color: Colors.FoldPixel.PRIMARY300,
                            textDecorationLine: 'underline',
                          }}
                          selectable={true}
                          onPress={() => toggleDescription(record.id)}
                        >
                          {expandedDescriptions?.[record.id]
                            ? 'Show less'
                            : 'Show more'}
                        </Text>
                      )}
                    </Text>
                  )}
                  {evalutionDate && (
                    <View style={{marginVertical: 1}}>
                      <Text
                        style={{
                          color: Colors.FoldPixel.GRAY300,
                          fontSize: 12,
                          fontWeight: '400'
                        }}
                      >
                        Evaluation Date: {evalutionDate}
                      </Text>
                    </View>
                  )}
                </Stack>
              </Stack>
            </Pressable>
            );
          }}
        />
        <Column
          title={
            <Text
              style={{
                color: Colors?.FoldPixel?.GRAY300,
                fontWeight: '500',
                fontSize: 14,
              }}
            >
              Status
            </Text>
          }
          dataIndex="type"
          key="type"
          width={'15%'}
          render={(text, record: ITaskAlert) => {
            const status = taskStatusMlov?.find(
              (status) => status.id === record.statusId
            )
            const renderStatus = status?.code === TASK_STATUS.MISSED ? 'Not met' : status?.value;
            return (
              <Stack direction={'row'} style={{paddingRight: 4}}>
                {loadingTaskAlertTypes ? (
                  <Skeleton.Text
                    alignContent={'center'}
                    width={'auto'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    lines={1}
                    marginTop={1}
                  ></Skeleton.Text>
                ) : (
                  <Pressable
                    cursor={'default'}
                    style={{
                      backgroundColor: onHoverType?.[record.id]
                        ? Colors.FoldPixel.PRIMARY100
                        : Colors.FoldPixel?.GRAY50,
                      maxWidth: '80%',
                      borderRadius: 4,
                      ...(onHoverType?.[record.id] && {
                        borderWidth: 1,
                        borderColor: Colors.FoldPixel.GRAY50,
                      }),
                    }}
                    {...(isSideCarContext ? 
                      {
                        onHoverIn: () => setOnHoverType({[record.id]: true}),
                        onHoverOut: () => setOnHoverType({})
                      } : 
                      {}
                    )}
                  >
                    {
                      <Text
                        style={{
                          color: Colors.FoldPixel?.GRAY300,
                          fontWeight: '400',
                          fontSize: 12,
                          paddingHorizontal: 4,
                          paddingVertical: 2,
                        }}
                      >
                        {renderStatus}
                      </Text>
                    }
                  </Pressable>
                )}
              </Stack>
            );
          }}
        />
        <Column
          title={<></>}
          dataIndex="action"
          key="action"
          width={'10%'}
          render={(text, record: ITaskAlert) => {
            return (
              <Stack direction={'row'}  style={{paddingRight: 4, alignItems: 'center'}}>
                <View style={{flex: 1}} />
                <Stack
                  direction={'row'}
                  style={{alignItems: 'center', justifyContent: 'flex-end'}}
                >
                  <Popover
                    overlayClassName="alerts-action-popover"
                    key={record?.id}
                    overlayInnerStyle={{padding: -8, borderRadius: 16}}
                    overlayStyle={{padding: 0}}
                    overlay={{padding: 0}}
                    style={{padding: 0, borderRadius: 16}}
                    placement={'left'}
                    trigger="click"
                    open={!!isOpenActionsPopover?.[record?.id]}
                    onOpenChange={(visible: boolean) =>
                      handleVisibleChange(visible, record?.id)
                    }
                    content={() => popoverContent(record, displayCategoryCode)}
                  >
                    <Pressable
                      cursor={
                        loadingTaskAlertTypes || loaders.statusChangeloading
                          ? 'not-allowed'
                          : 'pointer'
                      }
                      disabled={
                        loadingTaskAlertTypes || loaders.statusChangeloading
                      }
                      onPress={() => {
                        handleVisibleChange(
                          !isOpenActionsPopover?.[record?.id],
                          record?.id
                        );
                      }}
                      _hover={{
                        backgroundColor: Colors.FoldPixel.GRAY50,
                      }}
                    >
                      {record?.id ===
                        actionsState.selectedTaskAlertForAction?.id &&
                      loaders.statusChangeloading ? (
                        <Spinner />
                      ) : (
                        <ThreeDotsSvg
                          customStrokeColor={
                            loadingTaskAlertTypes
                              ? Colors?.FoldPixel?.GRAY150
                              : undefined
                          }
                        />
                      )}
                    </Pressable>
                  </Popover>
                </Stack>
              </Stack>
            );
          }}
        />
      </Table>
    );
  };

  const getActionIcon = (action: typeof ALERTS_ACTIONS_MENU[0]) => {
    switch (action.code) {
      case ALERT_ACTION_CODES.SUGGESTED_ACTION:
        return <SuggestedActionsSvg />;
      case ALERT_ACTION_CODES.CREATE_TASK:
        return <AddTaskSvg />;
      case ALERT_ACTION_CODES.SCHEDULE_APPOINTMENT:
        return <ScheduleAppointmentSvg />;
      case ALERT_ACTION_CODES.DISMISS:
        return <DismissIconSvg />;
      case ALERT_ACTION_CODES.RESOLVE:
        return <ResolvedAlertsSvg />;
      case ALERT_ACTION_CODES.SNOOZE:
        return <SnoozedAlertsSvg />;
      case ALERT_ACTION_CODES.SEND_PATIENT_EDUCATION:
        return <PatientEducationSvg />;
      case ALERT_ACTION_CODES.ADD_NOTE:
        return <NoteLinkIcon />;
      case ALERT_ACTION_CODES.SEND_ASSESSMENT:
        return <AssessmentSvg />;
      case ALERT_ACTION_CODES.MOVE_TO_PENDING:
        return <PendingAlertsSvg />;
    }
    return <></>;
  };

  const onActionClick = async (actionCode: string, record: ITaskAlert) => {
    let displayCategoryId = '';
    setActionsState((prev) => ({
      ...prev,
      selectedTaskAlertForAction: record,
    }));
    if(actionCode !== ALERT_ACTION_CODES.SNOOZE && actionCode !== ALERT_ACTION_CODES.ADD_NOTE) {
      setIsOpenActionsPopover({});
    }
    switch (actionCode) {
      case ALERT_ACTION_CODES.SNOOZE:
        setActionsState((prev) => ({
          ...prev,
          showSnoozeView: true,
        }));
        break;
      case ALERT_ACTION_CODES.DISMISS:
      case ALERT_ACTION_CODES.RESOLVE:
        displayCategoryId = getMlovIdFromCode(
          taskAlertDisplayCategoryMlovs,
          ALERT_CATEGORY_CODES_MAPPED[actionCode]
        );
        setActionsState((prev) => ({
          ...prev,
          showDismissAlertReasonView: actionCode === ALERT_ACTION_CODES.DISMISS,
          showResolveAlertReasonView: actionCode === ALERT_ACTION_CODES.RESOLVE,
        }));
        await getAlertActionReasons({
          variables: {
            params: {
              taskAlertDisplayPreferenceIds: [displayCategoryId],
              taskTypeIds: [record.taskTypeId],
            },
          },
        });
        break;
      case ALERT_ACTION_CODES.CREATE_TASK:
        setActionsState((prev) => ({
          ...prev,
          showAddTaskDrawer: true,
        }));
        break;
      case ALERT_ACTION_CODES.SCHEDULE_APPOINTMENT:
        setActionsState((prev) => ({
          ...prev,
          showAppointmentDrawer: true,
        }));
        break;
      case ALERT_ACTION_CODES.SEND_ASSESSMENT:
        setActionsState((prev) => ({
          ...prev,
          showContactSendForm: true,
        }));
        break;
      case ALERT_ACTION_CODES.SEND_PATIENT_EDUCATION:
        setActionsState((prev) => ({
          ...prev,
          showEducationalContentDrawer: true,
        }));
        break;
      case ALERT_ACTION_CODES.ADD_NOTE:
        setComponentState((prev) => ({
          ...prev,
          todayInProgressNote: undefined,
        }));
        defaultNoteCreationHandler(record.id);
        break;
      case ALERT_ACTION_CODES.MOVE_TO_PENDING:
        onSaveOfAlertActionReason(undefined, undefined, TASK_ALERT_CATEGORY_CODES.PENDING, undefined, record)
        break;
    }
  };

  const defaultNoteCreationHandler = async (recordId: string) => {
    try {
      setActionsState((prev) => ({
        ...prev,
        loadingActionCode: ALERT_ACTION_CODES.ADD_NOTE,
        loadingAlertId: recordId
      }));
      const dateRange = getCareDashboardDateRange();
      const documentResponse = await getDocumentsReferenceWithFilters(
        props?.formattedContactData?.contactData?.patient?.patientId,
        {createdOnStartDate: dateRange.startDate},
        '',
        undefined,
        foldVisitNoteWithEncountersEnabled
      );
      const formatResponseParam: INotesFormattedDataProps = {
        noteResponse: documentResponse.data?.entry || [],
        ehrConfig,
        loginUserId: userUuid,
        contextData: mlovData,
        accountUserList: accountUserList || [],
        elationFormData: elationFormData,
        additionalFlags:{
          foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled
        }
      };
      const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam);
      const todayPendingNote = (patientNotes || []).find(note => {
        return note?.status === DocStatus.PRELIMINARY;
      });
      if (todayPendingNote?.resourceId) {
        setActionsState((prev) => ({
          ...prev,
          showInProgressAddNotesPopup: true,
          loadingActionCode: undefined,
          loadingAlertId: undefined
        }));
        setComponentState((prev) => ({
          ...prev,
          todayInProgressNote: todayPendingNote
        }));
      } else {
        setActionsState((prev) => ({
          ...prev,
          showAddNotesDrawer: true,
          loadingActionCode: undefined,
          loadingAlertId: undefined
        }));
      }
      setIsOpenActionsPopover({});
    } catch(err) {
      setActionsState((prev) => ({
        ...prev,
        showAddNotesDrawer: true,
        loadingActionCode: undefined,
        loadingAlertId: undefined
      }));
      setIsOpenActionsPopover({});
    }
  };

  const popoverContent = (record: ITaskAlert, displayCategoryCode: string) => {
    const taskTypeActionIds = taskAlertTypes?.getTaskTypes?.taskTypes
      ?.find(alertType => alertType.id === record.taskTypeId)
      ?.taskTypeActionConfigs
      ?.map(config => config?.taskTypeActionId);

    const alertTaskTypeActionIds = record?.taskActionConfigs
      ?.map(config => config?.taskTypeActionId)
      ?.filter((id): id is string => id !== undefined) || [];

    const actionIdsToShowForAlerts = alertTaskTypeActionIds.length > 0
      ? alertTaskTypeActionIds
      : taskTypeActionIds || [];

    const filteredActionsMenu = getFilteredActionsMenuForAlerts(
      displayCategoryCode,
      taskTypeActionsMlovList,
      actionIdsToShowForAlerts
    );

    const renderActions = () => (
      <Stack direction="column" style={{ padding: 0, borderRadius: 16 }}>
        {filteredActionsMenu.map((action, index: number) => {
          const actionIcon = getActionIcon(action);
          const isDismissAction = action.code === ALERT_ACTION_CODES.DISMISS;

          return (
            <React.Fragment key={index}>
              <Pressable
                style={{ padding: 4, borderRadius: 4 }}
                onPress={() => onActionClick(action.code, record)}
                _hover={{
                  backgroundColor: isDismissAction
                    ? Colors.Custom.Red100
                    : Colors.FoldPixel.GRAY50
                }}
              >
                <Stack direction="row" style={{ padding: 4, justifyContent: 'space-between' }} space={4}>
                  <Stack direction="row" style={{ alignItems: 'center' }} space={4}>
                    {actionIcon}
                    <Text
                      style={{
                        color: isDismissAction
                          ? Colors.FoldPixel.STATUS_ERROR
                          : Colors.FoldPixel.GRAY400,
                        fontSize: 14,
                        fontWeight: '400'
                      }}
                    >
                      {action.value}
                    </Text>
                  </Stack>
                  {actionsState.loadingAlertId === record.id && actionsState.loadingActionCode === action.code && <Spinner/> }
                </Stack>
              </Pressable>
              {!!filteredActionsMenu[index + 1] && filteredActionsMenu[index + 1]?.categoryCode !== action.categoryCode && (
                <Divider style={{ marginTop: 8, marginBottom: 8 }} />
              )}
            </React.Fragment>
          );
        })}
      </Stack>
    );

    return actionsState?.showDatePicker ? (
      <View>
        <Pressable
          style={{
            padding: 4,
            borderRadius: 4,
          }}
          onPress={() => setActionsState(prev => ({
            ...prev,
            showDatePicker: false,
          }))}
          _hover={{
            backgroundColor: Colors.FoldPixel.GRAY50
          }}
        >
          <Stack
            direction={'row'}
            space={2}
            style={{ padding: 4, alignItems: 'center' }}
          >
            <BackArrowSvg />
            <Text
              style={{
                color: Colors.FoldPixel.GRAY400,
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 16.8,
                letterSpacing: 0.5,
              }}
            >
              Back to options
            </Text>
          </Stack>
        </Pressable>
        <Calendar
          value={getMomentObjectWithDateStringAndFormat(
            getDateStrFromFormat(selectedDateForSnooze, DATE_FORMATS.API_DATE_FORMAT),
            DATE_FORMATS.API_DATE_FORMAT
          )}
          style={{ maxWidth: 'min-content' }}
          fullscreen={false}
          disabledDate={(current) => isBeforeOrSameDate(current.toDate(), new Date())}
          onChange={(momentDate) => {
            setSelectedDateForSnooze(momentDate.toDate()?.toISOString())
          }}
        />
        <Pressable
          onPress={() => {
            const dateObject = getDateObject(selectedDateForSnooze);
            handleSelectSnoozeOption(getPreviousCalendarDate(CalendarView.day, dateObject)?.toISOString())
          }}
          disabled={!selectedDateForSnooze}
          style={{ backgroundColor: Colors?.FoldPixel?.PRIMARY300, borderRadius: 4, alignItems: 'center', paddingVertical: 7.5 }}>
          <Text style={{ color: Colors?.Custom?.BackgroundColor, fontWeight: '500', fontSize: 14 }}>
            {intl.formatMessage({ id: 'apply' })}
          </Text>
        </Pressable>
      </View>
    ) : actionsState?.showSnoozeView ? (
      <SnoozeView
        onSelectSnoozeOption={handleSelectSnoozeOption}
        onDatePickerOpen={() => setActionsState(prev => ({
          ...prev,
          showDatePicker: true
        }))}
        onBackClick={() => handleVisibleChange(true, actionsState?.selectedTaskAlertForAction?.id || '')}
      />
    ) : (
      renderActions()
    );
  };

  const getAlertsHeaderIcon = (displayCategoryCode: string) => {
    switch (displayCategoryCode) {
      case TASK_ALERT_CATEGORY_CODES.PENDING:
        return <PendingAlertsSvg />;
      case TASK_ALERT_CATEGORY_CODES.SNOOZED:
        return <SnoozedAlertsSvg />;
      case TASK_ALERT_CATEGORY_CODES.RESOLVED:
        return <ResolvedAlertsSvg />;
      case TASK_ALERT_CATEGORY_CODES.DISMISSED:
        return <DismissedAlertsSvg />;
      default:
        return <></>;
    }
  };

  const getHeaderForCollapse = (
    textToDisplay: string,
    alertCount: number,
    isActive: boolean,
    displayCategoryCode: string
  ) => {
    const totalCountForDisplayCategory = componentState[
      `${displayCategoryCode.toLowerCase()}TotalCount` as keyof IAlertsViewComponentState
    ] as number;
    const pageFordisplayCategory = componentState[
      `${displayCategoryCode.toLowerCase()}AlertsPage` as keyof IAlertsViewComponentState
    ] as number;
    return (
      <Stack
        direction={'row'}
        style={{
          alignItems: 'center',
          backgroundColor: Colors?.Custom?.BackgroundColor,
        }}
        space={8}
      >
        {getAlertsHeaderIcon(displayCategoryCode)}
        <Text
          style={{
            fontWeight: '500',
            fontSize: 14,
            color: Colors.FoldPixel.GRAY300,
            lineHeight: 14.4,
            letterSpacing: 0.5,
            fontStyle: 'normal',
            borderRadius: 4,
          }}
        >
          {textToDisplay}
        </Text>
        {isActive ? <OpenedCollapseSvg /> : <ClosedCollapseSvg />}
        {displayCategoryCode === TASK_ALERT_CATEGORY_CODES.PENDING && (
          getAlertCountView(
            componentState[`${displayCategoryCode.toLowerCase()}CountLoading` as keyof IAlertsViewComponentState],
            alertCount
          )
        )}
        <View style={{flex: 1}} />
        {isActive && totalCountForDisplayCategory && (
          <SidePagination
            page={pageFordisplayCategory}
            pageSize={componentState.pageSize}
            totalCount={totalCountForDisplayCategory}
            handleChange={(newPage: number, pageSize: number) =>
              handlePaginationChange(displayCategoryCode, newPage, pageSize)
            }
          />
        )}
      </Stack>
    );
  };

  const onStatusChangeCompleted = (displayCategoryStatusCode?: string) => {
    let toastToDisplay = actionsState?.showDismissAlertReasonView
      ? 'alertDismissed'
      : 'alertResolved';
    if (displayCategoryStatusCode === TASK_ALERT_CATEGORY_CODES.SNOOZED) {
      toastToDisplay = 'alertSnoozed'
    }
    if (displayCategoryStatusCode === TASK_ALERT_CATEGORY_CODES.PENDING) {
      toastToDisplay = 'alertMovedToPending'
    }
    setActionsState((prev) => ({
      ...prev,
      showDismissAlertReasonView: false,
      showResolveAlertReasonView: false,
      selectedTaskAlertForAction: undefined,
    }));
    notification.success({
      message: intl.formatMessage({id: `${toastToDisplay}`}),
      duration: 3.0,
      placement: 'topRight',
    });
    fetchAlerts(componentState.activePanels, undefined, true);
  };

  const onSaveOfAlertActionReason = (
    selectedReason?: IAlertActionReasons,
    note?: string,
    displayStatusCode?: string,
    snoozeValidTill?: string,
    selectedTaskAlertForAction?: ITaskAlert
  ) => {
    let displayCategoryStatusCode = '';
    if (actionsState.showDismissAlertReasonView) {
      displayCategoryStatusCode = TASK_ALERT_CATEGORY_CODES.DISMISSED;
    } else if (actionsState.showResolveAlertReasonView) {
      displayCategoryStatusCode = TASK_ALERT_CATEGORY_CODES.RESOLVED;
    }
    if(!!displayStatusCode) {
      displayCategoryStatusCode = displayStatusCode;
    }
    const currentDisplayPreferenceID =
      actionsState?.selectedTaskAlertForAction?.taskDisplayCategoryId;
    handleAlertStatusChange(
      !!selectedTaskAlertForAction ? selectedTaskAlertForAction : actionsState?.selectedTaskAlertForAction,
      displayCategoryStatusCode,
      onStatusChangeCompleted,
      selectedReason,
      note,
      snoozeValidTill,
    );
  };

  const handleSelectSnoozeOption = (dateTime: string) => {
    setSelectedDateForSnooze(getNextCalendarDate(CalendarView.day, new Date()).toISOString());
    const formattedDate = getFormattedDate(getEndOfDay(dateTime), DATE_FORMATS.API_DATE_FORMAT);
    setActionsState((prev) => ({
      ...prev,
      showDatePicker: false,
    }));
    setIsOpenActionsPopover({});
    onSaveOfAlertActionReason(undefined, undefined, TASK_ALERT_CATEGORY_CODES.SNOOZED, formattedDate)
  };

  const handleSendOfEducationContent = ({
    userInputFieldMap,
    conditionList,
  }: {
    userInputFieldMap: {out: {userInputFieldList: IUserInputField[]}};
    conditionList: {
      [key: string]: ICondition[];
    }[];
  }) => {
    const paramsForEducationContent = {
      contactId: props?.formattedContactData?.contactData?.uuid,
      articleId: null,
      communicationMode: '',
      taskPriorityId: '',
      taskPatientFacingTitle: '',
      emailTemplateId: null,
      emailTemplateCategoryCode: '',
      emailTemplateSubject: '',
      smsBody: '',
    };

    userInputFieldMap?.out?.userInputFieldList.forEach((field) => {
      switch (field.key) {
        case NODE_INPUT_KEYS.EDUCATION_CONTENT:
          paramsForEducationContent.articleId = field.value.id;
          break;
        case NODE_INPUT_KEYS.PRIORITY:
          paramsForEducationContent.taskPriorityId = getMlovIdFromCode(
            taskPriorityMlovs,
            field.value
          );
          break;
        case NODE_INPUT_KEYS.PATIENT_FACING_TITLE:
          paramsForEducationContent.taskPatientFacingTitle = field.value;
          break;
        case NODE_INPUT_KEYS.COMMUNICATION_VIA_NODE:
          paramsForEducationContent.communicationMode = field.value;
          break;
        case NODE_INPUT_KEYS.EMAIL_TEMPLATE:
          paramsForEducationContent.emailTemplateId =
            field?.value?.template?.id || '';
          paramsForEducationContent.emailTemplateCategoryCode =
            field?.value?.category?.code || '';
          paramsForEducationContent.emailTemplateSubject =
            field?.value?.subject || '';
          paramsForEducationContent.smsBody = field.value.sms?.body || '';
          break;
        default:
          break;
      }
    });
    sendPatientEducation({
      variables: {params: paramsForEducationContent},
    });
  };

  const getFilterCount = () => {
    const filterCount =
      componentState.filters.selectedPriorityCodes?.length +
      componentState.filters.selectedTaskAlertDisplayCodes?.length;
    return filterCount;
  };

  const renderFilterView = (
    <Stack
      direction="row"
      style={{
        alignItems: 'center',
        ...(props?.isActionableDrawer ? {
          marginBottom: isSideCarContext ? 16 : 8,
          ...(!isSideCarContext ? {marginLeft: props.filterStyle?.marginLeft || 12} : {}),
          paddingHorizontal: props.filterStyle?.paddingHorizontal ? props.filterStyle?.paddingHorizontal : 4,
          paddingVertical: props.filterStyle?.paddingHorizontal ? props.filterStyle?.paddingHorizontal : 8,
        } : {
          paddingHorizontal: 4,
          paddingVertical: 8,
        }),
        backgroundColor: Colors.Custom.BackgroundColor,
      }}
    >
    <FilterWrapperComponent
      marginBottom={0}
      textWeight={400}
        onClose={() => {
          handleFilterChange();
        }}
        label={'Alert Type'}
        value={componentState?.filters?.formData?.selectedTaskAlertTypeList?.map(
          (item) => item.value
        )}
      contentMinHeight={0}
      contentStyle={{
        paddingVertical: 7.5,
        paddingHorizontal: 8,
        width: 'fit-content',
      }}
      maxWidth={'90%'}
      noJustify={true}
      filterTextStyle={{
        fontSize: 14,
        marginRight: 2,
        fontWeight: '500',
        color: Colors.FoldPixel.GRAY300,
        lineHeight: 14.4,
        letterSpacing: 0.5,
        fontStyle: 'normal',
        borderRadius: 4,
      }}
      filterValueTextStyle={{
        fontSize: 14,
        marginRight: 2,
        fontWeight: '500',
        color: Colors.FoldPixel.GRAY400,
        lineHeight: 14.4,
        letterSpacing: 0.5,
        fontStyle: 'normal',
        borderRadius: 4,
      }}
      {...(props?.isActionableDrawer ? {dropdownMarginLeft: 0} : {})}
      >
        {loadingTaskAlertTypes && <Spin />}
        {!loadingTaskAlertTypes &&
          (componentState.filtersInitialData.taskAlertTypeCategoriesList
            .length ? (
        <View>
              {componentState.filtersInitialData.taskAlertTypeCategoriesList.map(
                (mlov, index) => {
                  return (
              <AntdCheckbox
                key={mlov.id}
                value={mlov.code}
                style={{
                        marginTop: 10, marginBottom: 10, alignItems: 'center', marginLeft: index === 0 ? 10 : undefined,
                }}
                      checked={componentState.filters.selectedTaskAlertDisplayCodes?.includes(
                        mlov.code
                      )}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
    setComponentState((prev) => {
                          const selectedTaskAlertDisplayCodes =
                            prev.filters?.selectedTaskAlertDisplayCodes || [];
                          if (isChecked) {
                            if (
                              !selectedTaskAlertDisplayCodes.includes(mlov.code)
                            ) {
                              selectedTaskAlertDisplayCodes.push(mlov.code);
                            }
                          } else {
                            if (
                              selectedTaskAlertDisplayCodes.includes(mlov.code)
                            ) {
                              selectedTaskAlertDisplayCodes.splice(
                                selectedTaskAlertDisplayCodes.indexOf(
                                  mlov.code
                                ),
                                1
                              );
                            }
                          }
                          const selectedTaskAlertTypeList: ITaskAlertsType[] =
                            [];
                          selectedTaskAlertDisplayCodes?.length &&
                            componentState.filtersInitialData?.taskAlertTypeCategoriesList?.forEach?.(
                              (status) => {
                                if (
                                  selectedTaskAlertDisplayCodes.indexOf?.(
                                    status.code
                                  ) !== -1
                                ) {
                                  selectedTaskAlertTypeList.push(status);
                                }
                              }
      );

      return {
        ...prev,
        filters: {
          ...prev.filters,
                              selectedTaskAlertDisplayCodes: [
                                ...selectedTaskAlertDisplayCodes,
                              ],
          formData: {
            ...prev.filters.formData,
                                selectedTaskAlertTypeList: [
                                  ...selectedTaskAlertTypeList,
                                ],
          },
        },
      };
    });
                      }}
                    >
                      <Text
                        style={{marginLeft: 8, color: Colors.Custom.Gray700}}
                      >
                        {mlov.value}
                      </Text>
                    </AntdCheckbox>
                  );
                }
              )}
            </View>
          ) : undefined)}
      </FilterWrapperComponent>
      <FilterWrapperComponent
        marginBottom={0}
        textWeight={400}
        contentMinHeight={0}
        onClose={() => {
          handleFilterChange();
        }}
        noJustify={true}
        label={'Severity'}
        value={
          getSeverityNamesFromPriorityCodes(componentState?.filters?.formData?.selectedPriorityList?.map(
            (item) => item.code
          ))}
        contentStyle={{
          paddingVertical: 7.5,
          paddingHorizontal: 8,
        }}
        filterTextStyle={{
          fontSize: 14,
          marginRight: 2,
          fontWeight: '500',
          color: Colors.FoldPixel.GRAY300,
          lineHeight: 14.4,
          letterSpacing: 0.5,
          fontStyle: 'normal',
          borderRadius: 4,
        }}
        filterValueTextStyle={{
          fontSize: 14,
          marginRight: 2,
          fontWeight: '500',
          color: Colors.FoldPixel.GRAY400,
          lineHeight: 14.4,
          letterSpacing: 0.5,
          fontStyle: 'normal',
          borderRadius: 4,
        }}
      >
        {componentState.filtersInitialData.priorityTypeList.length ? (
          <View>
            {componentState.filtersInitialData.priorityTypeList.map((mlov, index) => {
              return (
                <AntdCheckbox
                  key={mlov.id}
                  value={mlov.code}
                  style={{ marginTop: 10, marginBottom: 10, alignItems: 'center', marginLeft: index === 0 ? 10 : undefined }}
                  checked={componentState.filters.selectedPriorityCodes?.includes(
                    mlov.code
                  )}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
    setComponentState((prev) => {
                      const selectedPriorityCodes =
                        prev.filters?.selectedPriorityCodes || [];
                      if (isChecked) {
                        if (!selectedPriorityCodes.includes(mlov.code)) {
                          selectedPriorityCodes.push(mlov.code);
                        }
                      } else {
                        if (selectedPriorityCodes.includes(mlov.code)) {
                          selectedPriorityCodes.splice(
                            selectedPriorityCodes.indexOf(mlov.code),
                            1
                          );
                        }
                      }
                      const selectedPriorityList: IMlov[] = [];
                      selectedPriorityCodes?.length &&
                        componentState.filtersInitialData?.priorityTypeList?.forEach?.(
                          (status) => {
                            if (
                              selectedPriorityCodes.indexOf?.(status.code) !==
                              -1
                            ) {
                              selectedPriorityList.push(status);
                            }
                          }
      );

      return {
        ...prev,
        filters: {
          ...prev.filters,
                          selectedPriorityCodes: [...selectedPriorityCodes],
          formData: {
            ...prev.filters.formData,
                            selectedPriorityList: [...selectedPriorityList],
          },
        },
      };
    });
                  }}
                >
                  <Text style={{marginLeft: 8, color: Colors.Custom.Gray700}}>
                    {getSeverityNamesFromPriorityCodes(mlov.code)}
                  </Text>
                </AntdCheckbox>
              );
            })}
          </View>
        ) : undefined}
      </FilterWrapperComponent>
      <View style={{flex: 1}} />
      {getFilterCount() > 0 && (
        <Pressable
          onPress={() => {
            setComponentState((prev) => ({
              ...prev,
              filters: initialFilters,
            }));
            handleFilterChange(true);
          }}
          style={{
            marginRight: 12,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ClearActionIcon />
          <Text style={{marginLeft: 6, color: Colors.Custom.mainPrimaryPurple}}>
            Clear All
          </Text>
        </Pressable>
      )}
    </Stack>
  );

  const getNewTaskAssignee = () => {
    const userUUID = getUserUUID();
    return {
      value: getUserUUID(),
      label: getUserFullName(),
      key: userUUID,
      type: ParticipantType.staff,
      details: userData,
    };
  }
  const getTitleView = (title: string) => {
    return (
      <Text
        style={{
          color: Colors.FoldPixel.GRAY300,
          fontSize: 14,
          fontWeight: '500',
          marginLeft: 8,
          marginBottom: 8,
        }}
      >
        {title}
      </Text>
    );
  };
  const renderAlertsView = (
    <>
      {props?.showFiltersTabInAlerts && renderFilterView}
      {componentState.showSearch && (
        <Input.Search
          placeholder="Search alerts"
          value={componentState.search}
          onChange={handleSearchChange}
          enterButton
        />
      )}
      {getTitleView('Alerts')}
      <Collapse
        activeKey={componentState.activePanels}
        onChange={handlePanelChange}
        className="alerts-view"
      >
        {taskAlertDisplayCategoryMlovs?.map(({code, value}) => {
          const {totalCount, alerts, loading} = getComponentStateForCode(code);
          if (code === TASK_ALERT_CATEGORY_CODES.SNOOZED) {
            value = 'On Hold';
          }

          return (
            <>
            <Panel
              header={getHeaderForCollapse(
                value,
                totalCount,
                componentState.activePanels?.includes(code.toLowerCase()),
                code
              )}
                key={code.toLowerCase()}
                showArrow={false}
              >
                {renderTable(alerts, loading, totalCount, code)}
              </Panel>
              {!componentState.activePanels?.includes(code.toLowerCase()) && <NativeBaseDivider />}
            </>
          );
        })}
      </Collapse>
    </>
  );
  const renderCareGapView = (
    <View style={{marginTop: 12}}>
      {getTitleView('Care gaps')}
        {[TASK_ALERT_CUSTOM_CATEGORY_CODES.CARE_GAPS]?.map((code) => {
          const {totalCount, alerts, loading} = getComponentStateForCode(code);
          return (
            renderCareGapTable(alerts, loading, totalCount, code)
          );
        })}
    </View>
  );

  return (
    <>
      {actionsState.showDismissAlertReasonView ||
      actionsState.showResolveAlertReasonView ? (
        <AlertActionReasonView
          reasonsList={actionsState.alertActionReasons}
          reasonsLoading={loadingAlertActionReasons}
          onSave={onSaveOfAlertActionReason}
          onCancelView={() => {
            setActionsState((prev) => ({
              ...prev,
              showDismissAlertReasonView: false,
              showResolveAlertReasonView: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
          drawerTitle={
            actionsState.showDismissAlertReasonView
              ? 'dismissAlert'
              : 'markAsResolved'
          }
          isNoteRequired={actionsState.showDismissAlertReasonView}
          saveLoading={loaders.statusChangeloading}
          drawerSubTitleTextId={
            actionsState.showDismissAlertReasonView
              ? 'selectReasonToDismissALert'
              : 'selectReasonToResolveALert'
          }
        />
      ) : (
        renderAlertsView
      )}
      {renderCareGapView}
      {actionsState.showAddTaskDrawer && (
        <AddOrUpdateTask
          saveBtnText={'Save'}
          successMessage={'taskAddedMsg'}
          member={getNewTaskMember() as any}
          assignee={getNewTaskAssignee() as any}
          isVisible={actionsState.showAddTaskDrawer}
          onComplete={(data) => {
            if (actionsState?.selectedTaskAlertForAction) {
              logAuditAction(
                ALERT_ACTION_CODES.CREATE_TASK,
                { taskId: data?.addOrUpdateTask?.id },
                actionsState?.selectedTaskAlertForAction,
              )
            }
            setActionsState((prev) => ({
              ...prev,
              showAddTaskDrawer: false,
            }));
          }}
          onCancel={() => {
            setActionsState((prev) => ({
              ...prev,
              showAddTaskDrawer: false,
            }));
          }}
          restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
        />
      )}
      {actionsState.showAppointmentDrawer && (
        <BookingWorkflow
          user={{
            name: props?.formattedContactData?.contactData?.name || '',
            uuid: props?.formattedContactData?.contactData?.uuid || '',
          }}
          disablePatientProspectSearch={true}
          isOpen={true}
          appointmentType={AppointmentType.bookAppointment}
          defaultParticipants={[
            {
              label: props?.formattedContactData?.contactData?.name || '',
              key: props?.formattedContactData?.contactData?.uuid || '',
              value: props?.formattedContactData?.contactData?.uuid || '',
              type: ParticipantType.patient,
            },
          ]}
          onAppointmentBooked={(appointmentId: any) => {
            logAuditAction(
              ALERT_ACTION_CODES.SCHEDULE_APPOINTMENT,
              { appointmentId: appointmentId },
              actionsState?.selectedTaskAlertForAction,
            )
          }}
          onClose={(data?: any) => {
            setActionsState((prev) => ({
              ...prev,
              showAppointmentDrawer: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
          onCancel={() => {
            setActionsState((prev) => ({
              ...prev,
              showAppointmentDrawer: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
        />
      )}
      {actionsState.showContactSendForm && (
        <ContactSendForm
          isVisible={true}
          assignmentData={{
            patientId:
              props?.formattedContactData?.contactData?.patient?.patientId,
            contactId: props?.formattedContactData?.contactData?.uuid,
            assignedById: userUuid,
            patientEmail: props?.formattedContactData?.contactData?.email,
            patientFirstName: props?.formattedContactData?.contactData?.name,
            patientContactUUID: props?.formattedContactData?.contactData?.uuid,
            contactTypeCode:
              props?.formattedContactData?.contactType?.code || '',
          }}
          onActionComplete={(formIds?: string[]) => {
            if (!!formIds && formIds?.length > 0) {
              logAuditAction(
                ALERT_ACTION_CODES.SEND_ASSESSMENT,
                { formLogIds: [...new Set(formIds)] },
                actionsState?.selectedTaskAlertForAction,
              )
            }
            setActionsState((prev) => ({
              ...prev,
              showContactSendForm: false,
            }));
            notification.success({
              message: intl.formatMessage({id: 'formSentSuccessully'}),
              duration: 3.0,
              placement: 'topRight',
            });
          }}
          onClose={() => {
            setActionsState((prev) => ({
              ...prev,
              showContactSendForm: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
          categoryCodesToSearchForms={[
            FORM_CATEGORY_TYPES.ASSESSMENT_FORM_CATEGORY,
          ]}
          showButtonsInTopBar={true}
          onCancelAction={() => {
            setActionsState((prev) => ({
              ...prev,
              showContactSendForm: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
        />
      )}
      {actionsState.showEducationalContentDrawer && (
        <SideMenu
          hideSideMenuTopBar={true}
          userInputFieldMap={{
            out: {
              userInputFieldList: typeNodes?.userInputFieldList,
            },
          }}
          nodeType={typeNodes || ''}
          isOpen={true}
          title={(typeNodes && typeNodes.displayName) || ''}
          onClose={() => {
            setActionsState((prev) => ({
              ...prev,
              showEducationalContentDrawer: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
          onSave={handleSendOfEducationContent}
          isContinueButtonLoading={sendPatientEducationLoading}
          primaryButtonText={'Send'}
          encloseSideMenuInDrawer={true}
          {...(!isSideCarContext ? {width: '40%'} : {})}
        />
      )}
      {
        attachmentState.showAttachmentDrawer &&
        <AttachmentsList onCancel={() => setAttachmentState((prev) => ({
          ...prev,
          showAttachmentDrawer: false,
          attachments: []
        }))}
          contactData={props?.contactData}
          attachments={manageAttachmentsListData(attachmentState.attachments || [])}
        />
      }
      {true && props.contactData && (
        <SidecarAddNotesDrawer
          accountUserList={accountUserList || []}
          contactData={props.contactData}
          onClose={()=> {
            setActionsState((prev) => ({
              ...prev,
              showAddNotesDrawer: false,
              showUpdateNotesDrawer: false
            }));
          }}
          onSaveNote={(noteData)=> {
            if (actionsState?.selectedTaskAlertForAction && noteData?.id) {
              logAuditAction(
                ALERT_ACTION_CODES.ADD_NOTE,
                { noteId:noteData?.id, docStatus: noteData?.docStatus },
                actionsState?.selectedTaskAlertForAction,)
            }
            setActionsState((prev) => ({
              ...prev,
              showAddNotesDrawer: false,
              showUpdateNotesDrawer: false
            }));
          }}
          isNewNote={actionsState.showAddNotesDrawer}
          noteData={componentState.todayInProgressNote}
          formattedContactData={props.formattedContactData}
        />
      )}
       {actionsState.showInProgressAddNotesPopup && (
        <SidecarConfirmationPopup
          header={'Create new note?'}
          onClose={()=> {
            setActionsState((prev) => ({
              ...prev,
              showInProgressAddNotesPopup: false,
              showAddNotesDrawer: false
            }));
          }}
          message={intl.formatMessage({id: 'todayInprogressNoteMsg'})}
          buttonActions={[
            {
              btnText: 'createNewButtonLabel',
              buttonType: ButtonType.secondary,
              onClick: () => {
                setActionsState((prev) => ({
                  ...prev,
                  showInProgressAddNotesPopup: false,
                  showAddNotesDrawer: true
                }));
              },
            },
            {
              btnText: 'openProgressNoteLabel',
              buttonType: ButtonType.primary,
              onClick: () => {
                setActionsState((prev) => ({
                  ...prev,
                  showInProgressAddNotesPopup: false,
                  showUpdateNotesDrawer: true
                }));
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default AlertsView;
