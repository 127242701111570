import React, { useContext, useEffect, useState } from "react";
import { ILeadListViewProps } from "../../RightSideContainer/Contacts/Leads/interfaces";
import { ContactListTableView } from "./customHook/ContactListTableView";
import { usePatientManager } from "./customHook/usePatientManager"
import { usePatientCountManager } from "./customHook/usePatientCountManager"
import { IDashboardProgramFilter, IPatientDashboardManager } from "./interface"
import { getDashboardProgramFilter, getDashboardProgramFilterCategoryCode } from "./customHook/utils"
import { PROGRAM_STEPS_FILTER_CODE } from "./constant";
import { IS_FEATURE_FLAG_ENABLED } from "../../../utils/commonUtils";
import { CommonDataContext } from "../../../context/CommonDataContext";
import { getMlovListFromCategory } from "../../../utils/mlovUtils";
import { MLOV_CATEGORY } from "../../../constants/MlovConst";
import { Center, Spinner, View } from "native-base";
import { SORT_ORDER } from "../../../constants";


interface IContactListViewState {
  selectedCategoryCode?: string,
  filterCodes: IDashboardProgramFilter[],
  pageSize: number,
  pageNumber: number,
  canShowMembershipDetail: boolean,
  offset: number,
  orderBy?: {
    foldScore?: string,
    hccV24?: string,
    hccV28?: string
  }
}


export const ContactListView = (props: ILeadListViewProps): JSX.Element => {
  const PAGE_SIZE = 10;
  const commonDataContext = useContext(CommonDataContext);
  const careProgramStepList = getMlovListFromCategory(commonDataContext.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_STEP) || [];
  const { tab, formData, personTypeUuid,  personType,  onSubFilterChange, contactUpdated, searchText, tagItem, inboxItem, selectedUserPracticeLocation } = props;
  const [selectedCategoryCode, setSelectedCategoryCode] = useState<string>(PROGRAM_STEPS_FILTER_CODE.ALL);
  const codes: string[] = getDashboardProgramFilterCategoryCode(tab || '', careProgramStepList, selectedCategoryCode);
  
  const [contactTab, setContactTab] = useState(tab);

  const {dashboardTabCount, tabCountLoading, refreshCount, loadingCategoryCodes} = usePatientCountManager({
    isProgramBasedDashboard: true,
    params: {
        tabCode: contactTab || '',
        categoryCodes: codes || [],
        filter: {
          searchText: searchText,
          tagItem: tagItem,
          inboxItem: inboxItem,
          personType: personType,
          personTypeUuid: personTypeUuid,
          selectedUserPracticeLocation: selectedUserPracticeLocation,
          status: formData?.status,
          formData: formData,
          tab: contactTab || '',
          loggedInStatus: formData?.loggedInStatus,
          contactUpdated: contactUpdated,
          careManagerId: formData?.careManagerId,
          categoryCode: selectedCategoryCode,
        },
      },
  });

  const filterCodes: IDashboardProgramFilter[] = getDashboardProgramFilter(contactTab || '', careProgramStepList, dashboardTabCount || [], selectedCategoryCode);
  const [contactViewData, setContactView] = useState<IContactListViewState>({
    selectedCategoryCode: PROGRAM_STEPS_FILTER_CODE.ALL,
    filterCodes: filterCodes,
    pageSize: PAGE_SIZE,
    offset: 0,
    canShowMembershipDetail: IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP'),
    pageNumber: 1,
    orderBy: {
      foldScore: SORT_ORDER.DESC,
      hccV24: '',
      hccV28: ''
    } as any
  });

  const params = {
    categoryCode: selectedCategoryCode,
    tabCode: contactTab || '',
    filter: {
      searchText: searchText,
      tagItem: props?.tagItem,
      inboxItem: props?.inboxItem,
      personType: personType,
      personTypeUuid: personTypeUuid,
      selectedUserPracticeLocation: props?.selectedUserPracticeLocation,
      status: (formData || {})?.status,
      formData: formData,
      tab: contactTab || '',
      loggedInStatus: (formData || {})?.loggedInStatus
    },
    pagination: {
      pageSize: contactViewData.pageSize,
      pageNumber: contactViewData.offset,
    },
    config: {
      isMembershipEnabled: contactViewData.canShowMembershipDetail
    },
    orderBy: contactViewData.orderBy || {}
  } as IPatientDashboardManager;

  const { loading, contactList, count, fetchContactsByFilter } = usePatientManager(params);

  const onChangeCategoryCode = (categoryCode: string) => {
    setSelectedCategoryCode(categoryCode);
    setContactView(prev => {
      return {
        ...prev,
        selectedCategoryCode: categoryCode,
        pageNumber: 1,
        offset: 0
      }
    });
  }

  const onPageChange = (pageNumber: number, pageSize: number) => {
    const offset = (pageNumber * (pageSize || PAGE_SIZE) - (pageSize || PAGE_SIZE)) || 0;
    setContactView(prev => {
      return {
        ...prev,
        pageNumber: pageNumber,
        offset: offset,
        pageSize: pageSize
      }
    });
  }

  const handleFoldScoreSort = (order?: string) => {
    setContactView(prev => {
      return {
        ...prev,
        orderBy: {
          hccV24: '',
          hccV28: '',
          foldScore: order
        }
      }
    });
  }

  const handleHccV24Sort = (order?: string) => {
    setContactView(prev => {
      return {
        ...prev,
        orderBy: {
          foldScore: '',
          hccV28: '',
          hccV24: order
        }
      }
    });
  }
  
  const handleHccV28Sort = (order?: string) => {
    setContactView(prev => {
      return {
        ...prev,
        orderBy: {
          foldScore: '',
          hccV24: '',
          hccV28: order
        }
      }
    });
  }

  useEffect(() => {
    if (props?.contactUpdated) {
      refreshCount();
      fetchContactsByFilter();
    }
  }, []);

  useEffect(()=> {
    setContactView((prev) => {
      return {
        ...prev,
        filterCodes: prev.filterCodes.map((filterCode) => {
          filterCode.isLoading = loadingCategoryCodes.includes(
            filterCode.categoryCode
          );
          return filterCode;
        }),
      };
    });
  }, [loadingCategoryCodes]);

  useEffect(() => {
    refreshCount();
    fetchContactsByFilter();
  }, [props?.refreshData])
  
  useEffect(() => {
    if (dashboardTabCount?.[0] && onSubFilterChange && typeof onSubFilterChange == 'function') {
      const tabCount = dashboardTabCount?.[0]?.count || 0;
      onSubFilterChange(selectedCategoryCode || '', tabCount);
    }
  }, [dashboardTabCount?.[0]?.count])

  useEffect(() => {
    const updatedFilterCodes = getDashboardProgramFilter(
      tab || '',
      careProgramStepList,
      dashboardTabCount || [],
      selectedCategoryCode
    );
    setContactView((prev) => {
      return {
        ...prev,
        filterCodes: updatedFilterCodes,
        pageNumber: 0
      };
    });

    setContactTab(tab);    
  }, [tab]);  
  
  const tableLoading = loading;

  return (
    <>
      {contactTab === tab ? (
        <ContactListTableView
          sortOrderBy={contactViewData?.orderBy}
          customerDataList={tableLoading ? [] : contactList}
          filterCodes={contactViewData.filterCodes}
          onChangeCategoryCode={onChangeCategoryCode}
          selectedCategoryCode={selectedCategoryCode}
          onPageChange={onPageChange}
          pageNumber={contactViewData.pageNumber}
          pageSize={contactViewData.pageSize}
          totalRecords={count}
          tableLoader={tableLoading}
          tabCode={tab || ''}
          handleFoldScoreSort={handleFoldScoreSort}
          handleHccV24Sort={handleHccV24Sort}
          handleHccV28Sort={handleHccV28Sort}
          clearAllEvent={props.clearAllEvent}
          {...props}
        />
      ) : (
        <Center>
          <Spinner />
        </Center>
      )}
    </>
  );


}
