import {Icon, Text, Tooltip, VStack} from 'native-base';
import {useContext} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {IAllowedOperation} from '../../../../../../../Interfaces';
import {BUTTON_TYPE} from '../../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {Colors} from '../../../../../../../styles';
import {
  findIsAllowToRunAutomation,
} from '../../../../../../../utils/commonUtils';
import {getContactTypeId} from '../../../../../../../utils/mlovUtils';
import {CustomMenuItem} from '../../../../../../common/CustomMenuItem/CustomMenuItem';
import CrossIconSvg from '../../../../../../common/Svg/CrossIconSvg';
import AutomationSvg from '../../../../../../common/Svg/GlobalActionSvg/AutomationSvg';
import CampaignSvg from '../../../../../../common/Svg/GlobalActionSvg/CampaignSvg';
import GroupSvg from '../../../../../../common/Svg/GroupSvg';
import EmailActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/EmailActionSvg';
import SmsActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/SmsActionSvg';
import PlusIcon from '../../../../../../common/Svg/PlusSvg';
import ThreeDotsSvg from '../../../../../../common/Svg/ThreeDotsSvg';
import {ITableTopBarProps} from '../../../../../../common/TableTopBar/Interfaces';
import {USER_ACCESS_PERMISSION} from '../../../../../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../../../../SideMenuBar/SideBarConst';
import {IBulkActionOption} from '../../../interfaces';

import FoldPermitCan from '../../../../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import { Pressable } from 'react-native';
import BulkTaskDeselectSvg from '../../../../../../common/Svg/Task/BulkTaskDeselectSvg';
import BulkTaskSelectSvg from '../../../../../../common/Svg/Task/BulkTaskSelectSvg';
import AssignCareManagerActionSvg from '../../../../../../common/Svg/PersonActionSvgIcons/AssignCareManagerActionSvg';
import ThreeDotIconSvg from '../../../../../../../assets/Icons/ThreeDotIconSvg';

export const getLeadListTopBarButtons = (
  buttonClickFnList: (() => void)[],
  onActionPerformed: (actioncode: any, rawData: any) => void,
  title: string,
  contactType: string,
  selectedTags: any[],
  filterPropsWithCode?: {
    [index: string]: any;
  },
  canCreatePatient?: boolean
): ITableTopBarProps => {
  const mlovData = useContext(CommonDataContext);
  const patientTypeUuid = getContactTypeId('CUSTOMER');
  const prospectTypeUuid = getContactTypeId('LEAD');

  const filterByPracticeLocationProps =
    (filterPropsWithCode &&
      filterPropsWithCode['FILTER_BY_PRACTICE_LOCATION']) ||
    {};
  const canCreate = () =>
    contactType === 'CUSTOMER' ? !!canCreatePatient : true;
  const btnList = [
    {
      id: 4,
      btnText: 'bulkImport',
      borderColor: Colors.Custom.mainPrimaryPurple,
      size: 'sm',
      colorScheme: '',
      textColor: Colors.Custom.mainPrimaryPurple,
      backgroundColor: Colors.Custom.buttonWhiteBg,
      style: {
        paddingHorizontal: 16,
      },
      variant: '',
      contactType: contactType,
      isTransBtn: true,
      btnType: 'IMPORT',
      leftIcon: (
        <Icon
          as={AntIcon}
          color={'#6941C7'}
          name={'download'}
          size="3"
          width={10}
          height={10}
        />
      ),
      btnClick: () => {
        buttonClickFnList[0]();
      },
    },
  ];

    if(canCreate()){
      btnList.push({
        id: 5,
        btnText: 'addNew',
        size: 'smBold',
        textColor: Colors.Custom.mainPrimaryPurple,
        borderColor: Colors.Custom.mainPrimaryPurple,
        backgroundColor: Colors.Custom.buttonWhiteBg,
        variant: '',
        leftIcon: <PlusIcon />,
        btnClick: () => {
          const initialContactType = {
            id: 1,
            contactType: contactType,
            contactTypeUuid:
              contactType && contactType === 'CUSTOMER'
                ? patientTypeUuid
                : prospectTypeUuid,
            label:
              contactType && contactType == 'CUSTOMER'
                ? 'Add Members'
                : 'Add Lead',
          };
          onActionPerformed(COMMON_ACTION_CODES.ADD, initialContactType);
        },
      } as any);
    }
  return {
    title: title,
    buttonList: btnList,
  };
};

export const getBulkActionButton = (
  title: string,
  onBulkActionClick: () => void,
  bulkActionEnabled: boolean
) => {
  return (
    <Tooltip placement="bottom" hasArrow label={title}>
      <Pressable
        style={{marginLeft: 4}}
        onPress={(event) => {
          onBulkActionClick();
        }}
      >
        {bulkActionEnabled ? <BulkTaskDeselectSvg /> : <BulkTaskSelectSvg />}
      </Pressable>
    </Tooltip>
  );  
};


export const getPatientsExtraActions = (isCareProgramEnabled: boolean) => {
  return isCareProgramEnabled
    ? [
        {
          displayString: 'assignCareManager',
          actionCode: 6,
          icon: (
            <AssignCareManagerActionSvg
              customStrokeColor={Colors.Custom.Gray500}
            />
          ),
          actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
        },
      ]
    : [];  
};

export const getBulkActionTopBarButton = (
  buttonClickFnList: (() => void)[],
  onActionPerformed: (actioncode: any, rawData: any) => void,
  title: string,
  showAutomationOption?: boolean,
  extraActions?: IBulkActionOption[]
): ITableTopBarProps => {
  const intl = useIntl();

  const emailActionList = [
    {
      displayString: 'sendEmail',
      actionCode: 0,
      icon: <EmailActionSvg customStrokeColor={Colors.Custom.Gray500} />,
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.INBOX,
    },
    {
      displayString: 'sendSms',
      actionCode: 1,
      icon: <SmsActionSvg customStrokeColor={Colors.Custom.Gray500} />,
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.INBOX,
    },
  ];

  const createActionList: IBulkActionOption[] = [   
    {
      displayString: 'createCampaign',
      actionCode: 3,
      icon: <CampaignSvg />,
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.CAMPAIGN_MANAGER,
    },
  ];
  const getCustomMenuItem = (action: IBulkActionOption, index: number) => {
    return (
      <CustomMenuItem
        key={action + index.toString()}
        label={intl.formatMessage({id: action.displayString})}
        onClick={() => {
          buttonClickFnList[action.actionCode]();
        }}
        hideBottomBorder
        icon={action.icon}
      ></CustomMenuItem>
    );
  };
  const addSmsActionView = (isCreate: boolean, extraActions?: IBulkActionOption[]) => {
    return (
      <VStack>       
        {emailActionList.map((action, index) => {
          return (
            <>
              {action?.resourceCode && (
                <FoldPermitCan
                  key={action + index.toString()}
                  resource={action?.resourceCode}
                  action={action.actionEvent}
                  component={getCustomMenuItem(action, index)}
                />
              )}
              {!action.resourceCode && getCustomMenuItem(action, index)}
            </>
          );
        })}       
        {createActionList.map((action, index) => {
          return (
            <>
              {action?.resourceCode && (
                <FoldPermitCan
                  key={action + index.toString()}
                  resource={action?.resourceCode}
                  action={action.actionEvent || ''}
                  component={getCustomMenuItem(action, index)}
                />
              )}
              {!action.resourceCode && getCustomMenuItem(action, index)}
            </>
          );
        })}
        {extraActions?.map((action, index) => {
          return (
            <>              
              {getCustomMenuItem(action, index)}
            </>
          );
        })}
      </VStack>
    );
  };

  return {
    title: title,
    buttonList: [
      ...(showAutomationOption && findIsAllowToRunAutomation()
        ? [
            {
              btnText: 'runAutomation',
              size: 'smBold',
              textColor: Colors.Custom.mainPrimaryPurple,
              borderColor: Colors.Custom.mainPrimaryPurple,
              backgroundColor: Colors.Custom.buttonWhiteBg,
              variant: '',
              isRightDividerNotVisible: false,
              leftIcon: (
                <AutomationSvg
                  customStrokeColor={Colors.Custom.Gray500}
                />
              ),
              style: {
                paddingHorizontal: 16,
              },
              btnClick: () => {
                buttonClickFnList[4]();
              },
            },
          ]
        : []),
        {
          btnText: 'createGroup',
          size: 'smBold',
          textColor: Colors.Custom.mainPrimaryPurple,
          borderColor: Colors.Custom.mainPrimaryPurple,
          backgroundColor: Colors.Custom.buttonWhiteBg,
          variant: '',
          isRightDividerNotVisible: false,
          leftIcon: (
            <GroupSvg width={16} height={16} />
          ),
          style: {
            paddingHorizontal: 16,
          },
          btnClick: () => {
            buttonClickFnList?.[5]?.();
          },
      },
      {
        id: 100,
        dropDownType: 'Popover',
        btnText: '',
        size: 'smBold',
        textColor: Colors.Custom.mainPrimaryPurple,
        style: {
          paddingHorizontal: 0,
          minWidth: 'auto',
        },
        borderColor: Colors.Custom.mainPrimaryPurple,
        backgroundColor: Colors.Custom.buttonWhiteBg,
        variant: BUTTON_TYPE.NO_BORDER,
        rightIcon: (
          <ThreeDotIconSvg style={{padding: 0, margin: 0}}></ThreeDotIconSvg>
        ),
        withRightBorder: true,
        isRightDividerNotVisible: false,
        content: addSmsActionView(false, extraActions),
        btnClick: () => {
          // btnClick
        },
      },
    ],
  };
};
