import {useCallback, useContext} from 'react';
import {getMomentObj} from '../../utils/DateUtils';
import {redirectToSelf} from '../../utils/commonUtils';
import {CommonDataContext} from '../../context/CommonDataContext';
import {IMemoryInfo} from './interfaces';
import {
  LAST_REDIRECT_EXPIRY_DATE_KEY,
  LAST_REDIRECT_EXPIRY_DATE_KEY_NON_MEMORY,
  MEMORY_CLEANUP_TIMEOUT,
  MEMORY_THRESHOLD_LIMIT,
  MEMORY_THRESHOLD_TIMEOUT,
} from '../../constants/StringConst';

const useMemoryMonitor = () => {
  const performanceWithMemory = performance as Performance & {
    memory?: IMemoryInfo;
  };
  const commonData: any = useContext(CommonDataContext);
  const checkMemoryUsage = useCallback(async (location?: any) => {
    try {
      if (window.isExtension || window.location.hostname === 'localhost' || !!location?.state
      ) {
        return;
      }

      const memoryConfigResponse = JSON.parse(
        commonData.accountConfigData?.CLEANUP_MEMORY_CONFIG?.defaultValue || '{}'
      );
      if (performanceWithMemory?.memory) {
        const memoryConfigData = memoryConfigResponse?.memory;
        const {usedJSHeapSize, jsHeapSizeLimit} = performanceWithMemory?.memory;
        const usedMemoryInGB = usedJSHeapSize / 1024 ** 3; // Convert bytes to GB
        const totalMemoryInGB = jsHeapSizeLimit / 1024 ** 3;

        const selectedConfig =
          memoryConfigData?.find(
            (config: any) => totalMemoryInGB <= config?.size
          ) || memoryConfigData?.[0];

        const thresholdMemoryLimit =
          selectedConfig?.thresholdLimit || MEMORY_THRESHOLD_LIMIT;

        const thresholdTimeout =
          selectedConfig?.timeout || MEMORY_THRESHOLD_TIMEOUT;

        if (usedMemoryInGB > thresholdMemoryLimit) {
          const lastRedirectExpiryDate = localStorage.getItem(
            LAST_REDIRECT_EXPIRY_DATE_KEY
          );

          if (!!lastRedirectExpiryDate) {
            const lastRedirectExpiryaDateMomentObject = getMomentObj(
              lastRedirectExpiryDate as string
            );
            const isLastRedirectExpiryValid = getMomentObj(new Date()).isBefore(
              lastRedirectExpiryaDateMomentObject
            );

            if (isLastRedirectExpiryValid) {
              return;
            }
          }

          localStorage.setItem(
            LAST_REDIRECT_EXPIRY_DATE_KEY,
            getMomentObj(new Date())
              .add(thresholdTimeout, 'minutes')
              .toISOString()
          );
          redirectToSelf();
        }
      } else {
        const cleanupTimeout =
          memoryConfigResponse?.cleanUpTimeout || MEMORY_CLEANUP_TIMEOUT;

        const lastRedirectExpiryDate = localStorage.getItem(
          LAST_REDIRECT_EXPIRY_DATE_KEY_NON_MEMORY
        );

        if (lastRedirectExpiryDate) {
          if (!!lastRedirectExpiryDate) {
            const lastRedirectExpiryaDateMomentObject = getMomentObj(
              lastRedirectExpiryDate as string
            );
            const isLastRedirectExpiryValid = getMomentObj(new Date()).isBefore(
              lastRedirectExpiryaDateMomentObject
            );

            if (isLastRedirectExpiryValid) {
              return;
            }
          }
          localStorage.setItem(
            LAST_REDIRECT_EXPIRY_DATE_KEY_NON_MEMORY,
            getMomentObj(new Date())
              .add(cleanupTimeout, 'minutes')
              .toISOString()
          );
          redirectToSelf();
        } else {
          localStorage.setItem(
            LAST_REDIRECT_EXPIRY_DATE_KEY_NON_MEMORY,
            getMomentObj(new Date())
              .add(cleanupTimeout, 'minutes')
              .toISOString()
          );
          redirectToSelf();
        }
      }
    } catch (e) {
      return;
    }
  }, []);

  return {
    checkMemoryUsage,
  };
};

export default useMemoryMonitor;
