import {Pressable, View} from 'react-native';
import DeleteIconSvg from '../../../../../../assets/Icons/DeleteIconSvg';
import {Colors} from '../../../../../../styles';
import CustomUserTag from '../../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/CustomUserTag';
import {
  BadgeSize,
  BadgeVarient,
  CustomBadge,
} from '../../../../../common/CustomBadge/CustomBadge';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import MemberInfoListItem from '../../../../../common/MemberInfoListItem/MemberInfoListItem';
import {ISummaryCount, IContactConflict} from './interface';
import {IContact} from '../../../../../common/CareDashboard/CareDashboardInterfaces';
import {
  BULK_CARE_TEAM_CONFLICT_CODE,
  SUMMARY_VIEW_VISIBLE_CODES,
} from './BulkAssignCareManagerConstants';

export enum BulkCareTeamOperationState {
  none,
  inProgress,
  error,
  success,
}

export enum BulkViewCode {
  configure = 'Configure',
  preview = 'Preview',
  bulkInProgress = 'BulkInProgress',
}

const getBadgeVarientByConflictCode = (conflictCode: string) => {
  if (conflictCode === BULK_CARE_TEAM_CONFLICT_CODE.ADD) {
    return BadgeVarient.success;
  } else if (conflictCode === BULK_CARE_TEAM_CONFLICT_CODE.REPLACE) {
    return BadgeVarient.warning;
  }

  return BadgeVarient.secondary;
};

export const getBulkCareManagerTableColumns = (
  onDeleteAction: (contact: any) => void
) => {
  const columns: any[] = [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
          textLocalId={'Member Name'}
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      fixed: 'left',
      sorter: false,
      width: '50%',
      filterMultiple: false,
      ellipsis: true,
      render: (text: any, selectedRecord: any): JSX.Element => {
        return (
          <MemberInfoListItem
            contactData={text}
            showEmail={false}
            showDateOfBirth={true}
          />
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
          textLocalId={'Update Action'}
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      fixed: 'left',
      sorter: false,
      width: '40%',
      filterMultiple: false,
      ellipsis: true,
      render: (text: any, selectedRecord: any): JSX.Element => {
        const varient = getBadgeVarientByConflictCode(
          selectedRecord?.conflictCode
        );

        return (
          <CustomBadge
            badgeText={getDisplayTextByConflictCode(
              selectedRecord?.conflictCode
            )}
            size={BadgeSize.l}
            varient={varient}
          />
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.primary['700'] || '', marginLeft: 8}}
          textLocalId={'Action'}
        />
      ),
      dataIndex: 'contactData',
      key: 'contactData',
      fixed: 'left',
      sorter: false,
      width: '10% ',
      filterMultiple: false,
      ellipsis: true,
      render: (text: any, selectedRecord: any): JSX.Element => {
        return (
          <View style={{alignItems: 'center'}}>
            <Pressable
              onPress={() => {
                onDeleteAction(selectedRecord);
              }}
            >
              <DeleteIconSvg customStrokeColor={Colors.FoldPixel.GRAY300} />
            </Pressable>
          </View>
        );
      },
    },
  ];
  return columns;
};

export const getConflictCodesCounts = (
  contacts: IContactConflict[],
  selectedContacts: IContact[]
) => {
  const conflictCodeCounts: ISummaryCount[] = [];

  const reducedConflictObjects = contacts?.reduce(
    (acc: ISummaryCount[], item) => {
      const conflictCodeObj = acc?.find(
        (accItem: any) => accItem.conflictCode === item.conflictCode
      );

      if (conflictCodeObj) {
        conflictCodeObj.count = conflictCodeObj.count + 1;
      } else {
        if (!acc) {
          acc = [];
        }
        acc.push({
          conflictCode: item.conflictCode,
          count: 1,
          showSelection: true,
        });
      }

      return acc;
    },
    []
  );

  conflictCodeCounts?.push({
    conflictCode: BULK_CARE_TEAM_CONFLICT_CODE.SELECTED_COUNT,
    count: selectedContacts?.length,
    showSelection: false,
  });

  SUMMARY_VIEW_VISIBLE_CODES?.forEach((summaryViewCode) => {
    const conflictObj = reducedConflictObjects?.find(
      (conflictCodeObj) => conflictCodeObj.conflictCode === summaryViewCode
    );

    if (conflictObj) {
      conflictObj.isSelected = true;
      conflictCodeCounts.push(conflictObj);
    } else {
      conflictCodeCounts.push({
        conflictCode: summaryViewCode,
        count: 0,
        showSelection: false,
      });
    }
  });
  return conflictCodeCounts;
};

export const getDisplayTextByConflictCode = (conflictCode: string) => {
  switch (conflictCode) {
    case BULK_CARE_TEAM_CONFLICT_CODE.SELECTED_COUNT:
      return 'Patients Selected';
    case BULK_CARE_TEAM_CONFLICT_CODE.ADD:
      return 'Add';
    case BULK_CARE_TEAM_CONFLICT_CODE.REPLACE:
      return 'Replace';
    case BULK_CARE_TEAM_CONFLICT_CODE.NO_CHANGE:
      return 'Unchanged';
  }
};

export const getDisplayColorByConflictCode = (conflictCode: string) => {
  switch (conflictCode) {
    case BULK_CARE_TEAM_CONFLICT_CODE.ADD:
      return Colors.Custom.SuccessColor;
    case BULK_CARE_TEAM_CONFLICT_CODE.REPLACE:
      return Colors.Custom.PendingTaskFontColor;
    default:
      return Colors.FoldPixel.GRAY300;
  }
};

export const getUdpatedSeletedContactsByConflictCode = (
  selectedContacts: any[],
  allSelectedContacts: any[],
  conflictCode: string,
  isSelected: boolean
) => {
  if (isSelected) {
    allSelectedContacts.forEach((contact) => {
      if (contact?.conflictCode === conflictCode) {
        selectedContacts.push(contact);
      }
    });
    return selectedContacts;
  } else {
    return selectedContacts?.filter(
      (contact) => contact?.conflictCode !== conflictCode
    );
  }
};
