import {Platform} from 'react-native';

export const CMS_BASE_URL = `https://${evnName()}foldhealth.io/cms`;

export const SERVICES_BASE_URL = {
  COMMERCE_BASE_URL: `https://${evnName()}foldhealth.io`,
  // COMMERCE_BASE_URL: 'https://dev.foldhealth.io',
};

export const SERVICES_BASE_PATH = {
  COMMERCE_BASE_PATH: '/crm-spree',
  COMMERCE_BASE_API_PATH: '/commerce/api/v2',
};

export const COMMERCE_API_PATH = {
  PRODUCT_LIST:
    'commerce/api/v2/platform/products?include=default_variant,variants,option_types,product_properties,taxons,images,primary_variant&',
  PRODUCT_DETAILS: 'commerce/api/v2/platform/products',
  MEMBERSHIP_DETAILS: 'commerce/api/v2/products',
  ADD_OR_UPDATE: 'commerce/api/v2/platform/products',
  TAXONS_LIST: 'commerce/api/v2/platform/taxons?&',
  OPTION_TYPE_LIST: 'commerce/api/v2/platform/option_types?&',
  VARIANTS_LIST:
    'commerce/api/v2/platform/variants?include=stock_items,product,tax_category,images,digitals,option_values&',
  STOCK_LOCATIONS: 'commerce/api/v2/platform/stock_locations',
  STOCK_ITEMS: 'commerce/api/v2/platform/stock_items',
};

// export const AIDBOX_BASE_URL = 'https://testserver02.aidbox.app/fhir';
// export const FHIR_BASE_URL = 'https://sandbox.foldhealth.io/fhir';
export const FHIR_BASE_URL = `https://${evnName()}foldhealth.io/integration/fhir`;
export const INTEGRATION_BASE_URL = `https://${evnName()}foldhealth.io/integration`;
export const OTHER_INTEGRATION_BASE_URL = `https://${evnName()}foldhealth.io/oth-integration`;
// export const INTEGRATION_BASE_URL = `http://10.6.0.13:9898/integration`;
// 10.6.0.13
export const AIDBOX_AUTHORIZATION_TOKEN =
  'Basic dGVzdHNlcnZlcjAyOnRlc3RzZXJ2ZXIwMnBhc3M=';

declare const window: any;

export const COMMUNICATION_APP_BASE_URL = () => {
  if (window.location.host.includes('localhost')) {
    return `https://default.communication.${evnName()}foldhealth.io`;
  }
  return `https://default.communication.${evnName()}foldhealth.io`;
};

export function isDemoEnv() {
  const envVariable = getEnvVariable();
  const isDemoMode = envVariable === 'dev' || envVariable === 'sandbox';
  return isDemoMode;
}

export function getEnvVariable() {
   //APP_BUILD_ENV
  if (window.isExtension) {
    return 'sandbox';
  }
  if (Platform.OS === 'web') {
    if (window.location.host.includes('localhost') || window.location.host.includes('192.168.')) {
      return 'qa';
    } else if (window.location.host.includes('.sandbox.foldhealth.io')) {
      return 'sandbox';
    } else if (window.location.host.includes('.qa.foldhealth.io')) {
      return 'qa';
    } else if (window.location.host.includes('.dev.foldhealth.io')) {
      return 'dev';
    } else if (window.location.host.includes('.qa.foldhealth.io')) {
      return 'qa';
    } else if (window.location.host.includes('.foldhealth.io')) {
      return '';
    }
  }
  return 'sandbox';
}

export function evnName() {
  return getEnvVariable().length ? `${getEnvVariable()}.` : '';
}

export const CRM_URLs = {
  CHATWOOT: `https://crmchat.${evnName()}foldhealth.io`,
  MIDDLEWARE: `https://${evnName()}foldhealth.io/crm-nest`,
};

export const getCurrentSubdomainNative = () => {
  return `multiehr.${evnName()}foldhealth.io`;
};

export const getZoomOauthUrl = (redirectUrl: string) => {
  return `https://zoom.us/oauth/authorize?client_id=iGpXDMkDQsqvoHGfXwhE5g&response_type=code&redirect_uri=${redirectUrl}`;
};

export const allowToShowGqlQueryNames = () => {
  if (Platform.OS === 'web') {
    if (
      window.location.host.includes('localhost') ||
      window.location.host.includes('.dev.foldhealth.io') ||
      window.location.host.includes('.qa.foldhealth.io')
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const isSideCarWindow = () => {
  return window.isExtension || window.location.href.includes('chrome-extension://');
}
